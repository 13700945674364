import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  TextField
} from '@mui/material';

import DateInput from '../../../UI/input/DateInput';
import { FormControl } from '@mui/material';
import { SnackContext, AppContext, ThemeContext } from 'src/store/ContextStore';

import {
  getDependencies,
  REFERRAL_REASONS,
  REFERRAL_TRIAGE
} from './formService';
import TextInput from 'src/components/UI/input/TextInput';
import UploadFileList from 'src/components/UI/input/UploadFileList';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';

export default function ReferralForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  isDisabled
}) {
  const { theme } = React.useContext(ThemeContext);

  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        referralTo: '',
        externalReferralId: '',
        referralDate: new Date(),
        referredBy: '',
        referralReason: '',
        referralTriage: '',
        referralStatus: 'Open', // Open, Closed
        referralType: 'None',
        fileAttachments: [], // Make this form
        referralNotes: '',
        showInFamily: false,
        dateClosed: null,
        reasonForClosure: ''
      };
  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    if (e.target.type === 'date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();

      if (selectedDate >= today) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
      } else setError({});
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);

  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
    if (familyMemberId == 'none') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: true
      }));
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async () => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);

      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then(() => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();

        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateForm = (values) => {
    let errors = {};

    if (values.referralDate === '') {
      errors.referralDate = 'Referral Date is required';
    }
    if (values.referredBy === '') {
      errors.referredBy = 'Referred By is required';
    }

    if (values.referralDate) {
      const selectedDate = new Date(values.dateTreferralDateaken);
      const today = new Date();

      if (selectedDate > today) {
        errors.referralDate = 'Date Taken cannot be in future';
      }
    }

    setError(errors);
    return errors;
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const errors = validateForm(formValues);

    if (Object.keys(errors).length > 0) return Promise.reject();

    return axios
      .put(APICONSTANTS.referral, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?

    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Referral ?"
        content="Warning: This action will permanently delete this Referral. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Referral To"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="referralTo"
            value={formValues.referralTo}
            InputProps={{ readOnly: isDisabled }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="Referral Type"
            type="autocomplete"
            name="referralType"
            value={formValues.referralType || ''}
            onChange={(e) => {
              const label = e ? e.label : '';
              setFormValues({ ...formValues, referralType: label });
            }}
            options={typeOptions || []}
            helperText="Please select or enter a referral type"
            readOnly={isDisabled}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 0.25 }}>
            <FormLabel required>Referral Date</FormLabel>
          </Grid>
          <DateInput
            value={formValues.referralDate}
            liveText
            disableFuture
            onChange={(val) =>
              handleChange({
                target: { name: 'referralDate', value: val, type: 'date' }
              })
            }
            variant="outline"
            error={error?.referralDate}
            helperText={'Date cannot be in the future'}
            readOnly={isDisabled}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Referred By"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="referredBy"
            value={formValues.referredBy}
            required={true}
            error={!!error.referredBy}
            helperText={error.referredBy}
            InputProps={{ readOnly: isDisabled }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="Referral Reason"
            variant="outlined"
            fullWidth
            type="autocomplete"
            options={REFERRAL_REASONS}
            onChange={(e) => {
              const label = e ? e.label : '';
              setFormValues({
                ...formValues,
                referralReason: label
              });
            }}
            name="referralReason"
            value={formValues.referralReason}
            readOnly={isDisabled}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="Referral Triage"
            variant="outlined"
            fullWidth
            type="select"
            options={REFERRAL_TRIAGE}
            onChange={handleChange}
            name="referralTriage"
            value={formValues.referralTriage}
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 0.5 }}>
            <FormLabel>Referral Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="referralStatus"
                value={formValues.referralStatus}
                onChange={handleChange}
                readOnly={isDisabled}
              >
                {['Open', 'Closed'].map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {formValues.referralStatus === 'Closed' && (
          <>
            <Grid item xs={6}>
              <Grid sx={{ paddingBlock: 0.5 }}>
                <FormLabel>Date Closed</FormLabel>
              </Grid>
              <DateInput
                name="dateClosed"
                value={formValues.dateClosed}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    dateClosed: e
                  }))
                }
                variant="outline"
                minDate={
                  isEditing ? new Date(1600, 0, 0) : formValues.referralDate
                }
                readOnly={isDisabled}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label="Reason For Closure"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="reasonForClosure"
                value={formValues.reasonForClosure}
                multiline
                InputProps={{ readOnly: isDisabled }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <TextInput
            label="External Referral ID"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="externalReferralId"
            value={formValues.externalReferralId}
            InputProps={{ readOnly: isDisabled }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Referral Notes"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="referralNotes"
            value={formValues.referralNotes}
            multiline
            InputProps={{ readOnly: isDisabled }}
          />
        </Grid>

        <UploadFileList
          value={formValues.fileAttachments}
          familyId={familyMemberId}
          onChange={(value) =>
            setFormValues({
              ...formValues,
              fileAttachments: value
            })
          }
          readOnly={isDisabled}
        />

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <Typography>Show in family</Typography>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          hideSaveButton={isDisabled}
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing && !isDisabled ? handleOpenModal : null}
          addLabel="Save New Referral"
        />
      </Grid>
    </form>
  );
}
