import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { validateDate } from 'src/util/util';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, FormHelperText } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';

const useStyles = makeStyles({
  datepicker: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000'
    },
    '& .MuiInputBase-root': {
      '& .MuiButtonBase-root': {
        padding: 0
      }
    }
  }
});

const defaultInvalidDateMessage = 'Invalid date.';

export default function DateInput(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    disableFuture,
    fullWidth,
    disabled,
    variant,
    showPlaceholder,
    minDateMessage,
    maxDateMessage,
    minDate,
    allowDateSelection = true,
    error,
    helperText,
    liveText = false,
    helperTextColour,
    slotProps = {}
  } = props;

  const { theme } = useContext(ThemeContext);
  let format = props.format ?? theme.dateFormat;

  if (format == 'MM/DD/YYYY') format = 'MM/dd/yyyy';
  if (format == 'DD.MM.YYYY') format = 'dd/MM/yyyy';
  if (format == 'DD/MM/YYYY') format = 'dd/MM/yyyy';

  const views = ['year'];
  if (allowDateSelection) views.push('date');

  const [invalidDateMessage, setInvalidDateMessage] = React.useState('');
  const [date, setDate] = React.useState(value);

  // React.useEffect(() => {
  //   console.log(value);
  //   setDate(null);
  // }, [error, helperText, value]);

  // used to disable the invalid date message when typing
  const onFocus = React.useCallback(() => {
    setInvalidDateMessage('');
  }, []);

  // check if valid date, then set the value otherwise show the error
  const onBlur = (e) => {
    if (date === null || validateDate(date)) onChange(date);
    else setInvalidDateMessage(defaultInvalidDateMessage);
  };

  React.useEffect(() => {
    if (liveText) {
      if (error && helperText) setInvalidDateMessage(helperText);
      else setInvalidDateMessage('');
    }
  }, [value]);

  const onDateChange = (date) => {
    if (error && helperText) setInvalidDateMessage(helperText);
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        readOnly={props.readOnly}
        value={value ? new Date(value) : null}
        onChange={onDateChange}
        disableFuture={disableFuture}
        format={format}
        // onAccept={onAccept}
        minDate={minDate ? new Date(minDate) : new Date('01/01/1600')}
        slotProps={{
          textField: {
            error: !!error,
            helperText: error ? helperText : ''
          }
        }}
      />
    </LocalizationProvider>
  );
}
