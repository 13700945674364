import { ButtonView, Plugin } from 'ckeditor5';
const exportIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="24" height="24" aria-hidden="true">
     <path d="M17.826 16.843a.75.75 0 0 0-1.152-.96L15.5 17.29V12H14v5.29l-1.174-1.408a.75.75 0 0 0-1.152.96l2.346 2.816a.95.95 0 0 0 1.46 0l2.346-2.815Z"></path>
     <path d="m14 0 5 4.5v9.741a2.737 2.737 0 0 0-1.5-.617V6.5h-5v-5h-8v3H3V0h11Zm0 2v3h3.3L14 2Z"></path>
     <path d="M3 17.5h6.746c.1.22.23.429.391.623l.731.877H3v-1.5Z"></path>
     <path d="M8.5 6a1.5 1.5 0 0 1 1.493 1.356L10 7.5v7a1.5 1.5 0 0 1-1.356 1.493L8.5 16h-7a1.5 1.5 0 0 1-1.493-1.356L0 14.5v-7a1.5 1.5 0 0 1 1.356-1.493L1.5 6h7ZM7.483 8.795l-.042.088-.986 2.534-.947-2.528-.043-.092a.601.601 0 0 0-1.042.008l-.042.093-.883 2.465-.937-2.475-.042-.089a.6.6 0 0 0-1.107.42l.027.093 1.514 4 .044.092a.6.6 0 0 0 1.041-.01l.041-.092.88-2.458.925 2.467.046.096a.6.6 0 0 0 1.032 0l.043-.09 1.554-4 .028-.093a.6.6 0 0 0-1.104-.43v.001Z"></path>
    </svg>
  `;

export default class ExportToDocs extends Plugin {
  init() {
    const editor = this.editor;

    const exportToDocs = editor.config.get('exportToDocs');

    editor.ui.componentFactory.add('exportToDocs', () => {
      const button = new ButtonView();

      button.set({
        label: 'Export to DOCX',
        withText: false,
        tooltip: 'Export to Word',
        icon: exportIcon
      });

      button.on('execute', () => {
        if (exportToDocs) {
          const content = editor.getData();
          exportToDocs(content);
        } else {
          console.error('exportToDOCS function is not defined in config.');
        }
      });

      return button;
    });
  }
}
