import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

import TextInput from 'src/components/UI/input/TextInput';
import DateInput from 'src/components/UI/input/DateInput';
import AssociatedNote from 'src/components/UI/input/AssociatedNote';
import UploadFileList from 'src/components/UI/input/UploadFileList';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import FormLabel from 'src/components/UI/input/CustomFormLabel';

// Import axios & constants just like in AppointmentForm.jsx
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';

import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';

// Example Delete Confirmation Modal for the interface

export default function AppointmentForm_Notification({ _id, handleClose }) {
  const [open, setOpen] = useState(false); // Controls the delete modal
  const [loading, setLoading] = useState(false); // for tracking delete in progress

  // Basic local state for demonstration
  const [formValues, setFormValues] = useState({
    clinicName: '',
    appointmentId: '',

    appointmentDate: new Date(),
    appointmentTime: dayjs(new Date()),
    appointmentStatus: 'Closed',
    appointmentType: '',
    location: 'Telephone',
    appointmentNotes: '',
    fileAttachments: [],
    showInFamily: false,
    joinUrl: '',
    rescheduleUrl: '',
    cancelUrl: ''
    // If your backend also tracks these, add them
    // familyId: '',
    // familyMemberId: '',
    // etc.
  });

  // Example for your type & location options
  const [typeOptions] = useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);

  const [locationOptions] = useState([
    'Telephone',
    'Video',
    'Face to Face',
    'Private Provider'
  ]);

  // Example referrals
  const [referralOptions] = useState([{ label: 'none', _id: '' }]);

  // --------------------------------------------------------------------
  // 1. FETCH APPOINTMENT BY ID (if _id is provided)
  //    This matches the original fetch approach but we can adapt it
  // --------------------------------------------------------------------
  useEffect(() => {
    if (_id) {
      // Instead of fetch, use axios:
      axios
        .get(`/clinical-notes/appointments/${_id}`) // matches the route in your clinical-note.js
        .then(({ data }) => {
          setFormValues((prev) => ({
            ...prev,
            clinicName: data.clinicName || '',
            familyId: data.familyId || '',
            familyMemberId: data.familyMemberId || '',
            appointmentId: data.appointmentId || '',
            appointmentDate: data.appointmentDate
              ? dayjs(data.appointmentDate).toDate()
              : new Date(),
            appointmentTime: data.appointmentTime
              ? dayjs(data.appointmentTime)
              : dayjs(new Date()),
            appointmentStatus: data.appointmentStatus || 'Closed',
            appointmentType: data.appointmentType || '',
            location: data.location || 'Telephone',
            appointmentNotes: data.appointmentNotes || '',
            fileAttachments: data.fileAttachments || [],
            showInFamily: data.showInFamily || false,
            joinUrl: data.joinUrl || '',
            rescheduleUrl: data.rescheduleUrl || '',
            cancelUrl: data.cancelUrl || '',
            inviteeName: data.inviteeName || ''
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [_id]);

  // Toggle switch for "Show in family"
  const onToggleSwitch = (event) => {
    const { name, checked } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: checked }));
  };

  // File handling (front-end only)
  const handleFileListChange = (value) => {
    setFormValues((prev) => ({ ...prev, fileAttachments: value }));
  };

  // Delete modal
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  // --------------------------------------------------------------------
  // 2. DELETE LOGIC FROM AppointmentForm.jsx
  //    (Calls /clinical-notes/note? _id=... to delete the entire record)
  // --------------------------------------------------------------------
  const deleteNote = async () => {
    try {
      setLoading(true);
      // If you also have a dependency check, you can replicate it here
      // e.g.  const dep = await getDependencies(...);
      // if (dep.length) { ... } else proceed.

      await axios.delete('/clinical-notes/note', {
        params: { _id } // pass the appointment's _id
      });

      alert('Appointment deleted successfully'); // or use your snack logic
      setOpen(false);
      setLoading(false);

      if (handleClose) {
        handleClose(); // close the parent modal or do your post-delete routine
      }
    } catch (err) {
      alert(`Delete failed! ${err}`);
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // 3. SAVE (CREATE/UPDATE) LOGIC FROM AppointmentForm.jsx
  //    (Calls put(APICONSTANTS.appointment, parsePayload))
  // --------------------------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      // If you have 'staffMember' logic, replicate it here
      // For demonstration, we'll just pass formValues + any IDs
      const parsePayload = {
        ...formValues,
        _id // ensure we pass the existing _id if editing
      };

      await axios.put(APICONSTANTS.appointment, parsePayload);
      alert('Successfully saved appointment');
      if (handleClose) {
        handleClose();
      }
    } catch (err) {
      alert(`Save failed! ${err}`);
    }
  };

  // Cancel/Close
  const onCancel = () => {
    if (handleClose) {
      handleClose();
    }
  };

  // (Below remains mostly the same — we just replaced the fetch logic with axios
  //  and now use handleSubmit / deleteNote from AppointmentForm.jsx)
  return (
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Appointment ?"
        content="Warning: This action will permanently delete this Appointment. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        {/* Clinic Name */}
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Clinic Name</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  clinicName: e.target.value
                }))
              }
              name="clinicName"
              value={formValues.clinicName}
            />
          </Box>
        </Grid>

        {/* Appointment ID */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  appointmentId: e.target.value
                }))
              }
              name="appointmentId"
              value={formValues.appointmentId}
            />
          </Box>
        </Grid>

        {/* Appointment Status */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="appointmentStatus"
                value={formValues.appointmentStatus}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    appointmentStatus: e.target.value
                  }))
                }
              >
                {['Open', 'Closed'].map((option, i) => (
                  <MenuItem value={option} key={i}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* Appointment Date */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Date</FormLabel>
          </Grid>
          <DateInput
            value={formValues.appointmentDate}
            onChange={(date) =>
              setFormValues((prev) => ({ ...prev, appointmentDate: date }))
            }
            variant="outline"
          />
        </Grid>

        {/* Appointment Time */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Time</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formValues.appointmentTime}
                onChange={(time) =>
                  setFormValues((prev) => ({
                    ...prev,
                    appointmentTime: time
                  }))
                }
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        {/* Appointment Type */}
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Appointment Type"
              type="autocomplete"
              name="appointmentType"
              value={formValues.appointmentType}
              onChange={(option) => {
                const label = option ? option.label : '';
                setFormValues((prev) => ({ ...prev, appointmentType: label }));
              }}
              options={typeOptions || []}
              helperText="Please select or enter an appointment type"
            />
          </Box>
        </Grid>

        {/* Location */}
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Location"
              type="autocomplete"
              name="location"
              value={formValues.location}
              onChange={(option) => {
                const label = option ? option.label : '';
                setFormValues((prev) => ({ ...prev, location: label }));
              }}
              options={locationOptions || []}
              helperText="Please select or enter a location"
            />
          </Box>
        </Grid>

        {/* Associated Referral */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedReferral"
            handleChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                associatedReferral: e.target.value
              }))
            }
            value={formValues.associatedReferral || { label: 'none', _id: '' }}
            noteOptions={referralOptions}
          />
        </Grid>

        {/* Appointment Notes */}
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  appointmentNotes: e.target.value
                }))
              }
              name="appointmentNotes"
              value={formValues.appointmentNotes}
            />
          </Box>
        </Grid>

        {/* File Upload List */}
        <UploadFileList
          value={formValues.fileAttachments}
          onChange={handleFileListChange}
        />

        {/* Show in family toggle */}
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary" // official MUI prop is "color"
                checked={formValues.showInFamily}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Join / Reschedule / Cancel Appointment Buttons */}
        <Grid item xs={12}>
          <Box
            sx={{ display: 'flex', gap: 1, mt: 1, justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              onClick={() => window.open(formValues.joinUrl, '_blank')}
              disabled={!formValues.joinUrl}
            >
              Join
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.open(formValues.rescheduleUrl, '_blank')}
              disabled={!formValues.rescheduleUrl}
            >
              Reschedule
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => window.open(formValues.cancelUrl, '_blank')}
              disabled={!formValues.cancelUrl}
            >
              Cancel Appointment
            </Button>
          </Box>
        </Grid>

        {/* Submit / Delete / Cancel Buttons */}
        <ModalButtons
          isEditing={!!_id} // if _id exists => editing
          hideContinueAdding={true}
          onClick={handleSubmit} // calls handleSubmit
          handleClose={onCancel} // calls onCancel
          intermediateBtnLabel="Delete"
          intermediateAction={handleOpenModal} // opens the delete modal
          addLabel="Add new appointment"
        />
      </Grid>
    </form>
  );
}
