import React, { useState, useEffect, useContext, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios';
import {
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  Alert,
  IconButton
} from '@mui/material';
import {
  APICONSTANTS,
  CONSTANTS,
  HPO_CONSTANTS
} from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import { getHPOSearchURL } from 'src/util/util';
import { AppContext } from 'src/store/ContextStore';
import { makeStyles } from '@mui/styles';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import EditDiagnoseModal from './EditDiagnoseModel';
import { EVENTS } from 'src/components/admin/appReducer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteModal from 'src/components/proband-info/DeleteModal';
import dayjs from 'dayjs';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import HistoryIcon from '@mui/icons-material/History';
import { formatPersonSummary, replacer } from './util';
import ModalHeader from './ModalHeader';

const useStyles = makeStyles({
  card: {
    boxShadow: '1px 1px 5px grey',
    marginBottom: 5,
    cursor: 'pointer',
    borderRadius: 5,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8
  },
  diagnoseName: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  diagnoseAge: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8
  },
  rightIcon: {
    color: 'green'
  },
  clinicallyConfirmedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  legendBox: {
    display: 'flex',
    gap: 5
  }
});

const DiagnosesMenu = ({
  data,
  deepCopy,
  setDeepCopy,
  setDataset,
  setPedigreeState,
  modificationHistoryHandle
}) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(HPO_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const [fetchedDiagnoses, setFetchedDiagnoses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDiagnose, setSelectedDiagnose] = useState({});
  const [ageConfirmationModal, setAgeConfirmationModal] = useState({
    status: false,
    data: {}
  });
  const [isError, setIsError] = useState(false);

  const t = useAutoTranslation();

  const { setSnack } = useContext(SnackContext);
  const { content, dispatchUpdate } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  let user_id = useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.familyMemberId;
  }, [data]);

  // Id for proband
  let proBandId = useMemo(() => {
    return data?.data?.proBandId;
  }, [data]);

  const fetchDataByDisease = async (currentPage) => {
    if (searchTerm.length >= 3) {
      let url = getHPOSearchURL(
        searchTerm,
        currentPage,
        HPO_CONSTANTS.category['disease']
      );

      axios
        .get(url)
        .then(({ data }) => {
          if (isError) setIsError(false);

          const { results = [], totalCount } = data || {};

          const newOptions = results.map((d) => ({
            id: d.id,
            name: d.name
          }));

          if (!currentPage) {
            setOptions([...newOptions]);
          } else {
            setOptions((preOption) => [...preOption, ...newOptions]);
          }

          setTotalCount(totalCount);
          setPage(currentPage);
        })
        .catch((err) => {
          setIsError(true);
          console.log('ERRRR-', err);
        });
    } else {
      setOptions([]);
    }
  };

  const getDiagnoses = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    setFetchedDiagnoses([]); // Reset diagnoses
    axios
      .get(
        APICONSTANTS.getDiagnosesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      )
      .then(({ data }) => {
        const { diagnoses = [] } = data || {};
        setFetchedDiagnoses(diagnoses);
      })
      .catch((err) => {
        console.log('ERRRR-', err);
      });
  };

  useEffect(() => {
    fetchDataByDisease(HPO_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    getDiagnoses();
    // eslint-disable-next-line
  }, [familyMemberId, proBandId]);

  const handleSelect = (event, value) => {
    if (value) {
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setSelectedItems([...uniqueData]);
      setSearchTerm('');
    }
  };

  const updateData = (dataset) => {
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset, replacer));
    });
    setDataset(dataset);
  };

  const onSave = () => {
    const id = proBandId ?? familyMemberId;

    if (!id || !user_id)
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });

    if (selectedItems?.length === 0) {
      return setSnack({
        status: true,
        msg: 'No diseases selected. Please add at least one disease to proceed.',
        severity: 'error'
      });
    }

    setLoading(true);
    const payload = {
      familyId: user.familyId,
      familyMemberId: id,
      user_id: user_id,
      isProband: proBandId ? true : false,
      diagnoses: selectedItems
    };

    axios
      .post(APICONSTANTS.diagnoses, payload)
      .then((res) => {
        if (res.data?.success) {
          if (res.data?.allDiagnoses) {
            setFetchedDiagnoses(res.data.allDiagnoses);
            dispatchUpdate({
              type: EVENTS.SET_PEDIGREE_DIAGNOSES,
              value: res.data.diagnoses
            });

            const new_dataset = deepCopy.map((elem) => {
              if (elem.pid === data.data.pid) {
                return {
                  ...elem,
                  diagnoses: res.data.allDiagnoses
                };
              }
              return elem;
            });
            updateData(new_dataset);
          }
          setSearchTerm('');
          setSelectedItems([]);
          setSnack({
            status: true,
            msg: 'Disease saved successfully',
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response.data.error ?? 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));
  };

  const handleScroll = (event) => {
    const target = event.target;
    const margin = 1; // Margin to handle floating-point inconsistencies

    const scrollPosition =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    const isEndOfScroll = Math.abs(scrollPosition) <= margin;
    const hasMoreData = options.length < totalCount;

    if (isEndOfScroll && hasMoreData) {
      let newCurrentPage = page + 1;
      fetchDataByDisease(newCurrentPage);
    }
  };

  const handleModalClose = () => setOpenModal(false);

  const onDiagnoseClick = (diagnose) => {
    setSelectedDiagnose(diagnose);
    setOpenModal(true);
  };

  const onEditDiagnose = (diagnose, ageConfirmation = false) => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    if (diagnose.updatedDate) {
      diagnose.dateReported = diagnose.updatedDate;
    }

    return axios
      .put(
        APICONSTANTS.getDiagnosesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          diagnose,
          isProband: proBandId ? true : false,
          ageConfirmation
        }
      )
      .then((res) => {
        const diagnosesData = res?.data?.data || [];
        const {
          probandDiagnoses = {},
          familyDiagnoses = [],
          updatedEntity
        } = diagnosesData;

        dispatchUpdate({
          type: EVENTS.UPDATE_EACH_PEDIGREE_DIAGNOSES,
          value: {
            probandDiagnoses,
            familyDiagnoses
          }
        });
        setFetchedDiagnoses(updatedEntity.diagnoses || []);

        const new_dataset = deepCopy.map((elem) => {
          if (elem.pid === data.data.pid) {
            return {
              ...elem,
              diagnoses: updatedEntity.diagnoses
            };
          }
          return elem;
        });
        updateData(new_dataset);

        setSnack({
          status: true,
          msg: 'Saved successfully',
          severity: 'success'
        });
        handleModalClose();
      })
      .catch((err) => {
        if (err.response.data?.ageConfirmation?.status) {
          const { dateReported } = err.response.data.ageConfirmation.diagnose;
          setAgeConfirmationModal({
            data: {
              ...diagnose,
              dateReported: dateReported,
              updatedDate: diagnose.dateReported
            },
            status: true
          });
        } else {
          setSnack({
            status: true,
            msg: err.response.data.message ?? 'An error has occurred!',
            severity: 'error'
          });
        }
      });
  };

  const confirmAgeHandler = async () => {
    const ageConfirmation = true;
    return onEditDiagnose(ageConfirmationModal.data, ageConfirmation).then(() =>
      setAgeConfirmationModal({ status: false })
    );
  };

  const personSummary = formatPersonSummary(data?.data);

  return (
    <>
      <Grid container spacing={2}>
        {isError && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                'The HPO service is unreachable and may be offline. Please also check your Internet connection. You will not be able to search HPO until the connection is restored.'
              )}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={selectedItems}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{
              onScroll: handleScroll,
              sx: {
                maxHeight: '280px',
                overflowY: 'auto'
              }
            }}
            filterOptions={(options, state) => options}
            // filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Diseases"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          spacing={2}
          className={classes.clinicallyConfirmedContainer}
        >
          <div>
            <Button
              endIcon={loading ? <CircularProgress size={15} /> : <></>}
              variant="contained"
              color="secondary"
              onClick={onSave}
              disabled={loading}
            >
              Save
            </Button>

            <IconButton
              sx={{ marginLeft: '10px' }}
              onClick={() =>
                modificationHistoryHandle(CONSTANTS.auditLogType['diagnoses'])
              }
            >
              <HistoryIcon />
            </IconButton>
          </div>

          <Grid item className={classes.legendBox}>
            <CheckCircleOutlineIcon className={classes.rightIcon} />
            <Typography>Clinically Confirmed</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={2}>
          {fetchedDiagnoses.map((diagnose) => (
            <Grid
              item
              xs={12}
              key={diagnose.id}
              onClick={() => onDiagnoseClick(diagnose)}
              className={classes.card}
            >
              <Grid xs={9}>
                <Tooltip title={diagnose.name} arrow>
                  <Typography className={classes.diagnoseName}>
                    {diagnose.alias || diagnose.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid xs={3} className={classes.diagnoseAge}>
                {diagnose.clinicallyConfirmed && (
                  <CheckCircleOutlineIcon className={classes.rightIcon} />
                )}
                <Typography color={!diagnose.age ? 'orange' : ''}>
                  {diagnose.age ? `(${diagnose.age})` : 'Age*'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {openModal && (
          <CustomDialog
            title={
              <ModalHeader title="Edit Disease" personSummary={personSummary} />
            }
            open={openModal}
            handleClose={handleModalClose}
            minHeight="auto"
            titleStyle={{ marginRight: '40px' }}
          >
            <EditDiagnoseModal
              formValues={selectedDiagnose}
              onSubmit={onEditDiagnose}
            />
          </CustomDialog>
        )}
        {ageConfirmationModal.status && (
          <CustomDialog
            title={'Confirm diagnose age'}
            open={ageConfirmationModal.status}
            handleClose={() => setAgeConfirmationModal({ status: false })}
            minHeight="auto"
            showActions={true}
            saveBtnLabel={'Save'}
            onSave={confirmAgeHandler}
          >
            <DeleteModal
              content={
                <>
                  <p>Are you sure you want to continue?</p>
                  <p>
                    You have already added diagnoses for{' '}
                    <strong>{`${ageConfirmationModal.data.name} `} </strong>
                    with age{' '}
                    <strong>{`${ageConfirmationModal.data.age} `}</strong>
                    reported on{' '}
                    <strong>
                      {dayjs(ageConfirmationModal.data.dateReported).format(
                        'DD/MM/YYYY'
                      )}
                    </strong>
                    .
                  </p>
                </>
              }
            />
          </CustomDialog>
        )}
      </Grid>
    </>
  );
};

export default DiagnosesMenu;
