import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
// import { diagnosesMap } from 'src/components/questionnaire/Constants';
// import { diagnsesShapeMap } from '../pedigreeUtil';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { PEDIGREE } from 'src/components/Constants';
// import DiagnosisModal from '../DiagnosisModal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 15,
    flexWrap: 'wrap'
  },
  boxHolder: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '1rem'
  },
  item: {
    display: 'inline-block',
    flexShrink: 0,
    width: 15,
    height: 15,
    borderRadius: 2,
    marginRight: 8
  },
  confirmedBox: {
    backgroundColor: 'white',
    backgroundImage: 'radial-gradient(black 1px, transparent 1px)',
    backgroundSize: '4px 4px',
    border: '1px solid black'
  },
  confirmedText: {
    fontStyle: 'italic'
  }
});

export default function LegendMenu({
  diagnoses,
  id,
  labelsSet,
  setLabelsSet,
  showLegendTxt
}) {
  const classes = useStyles();

  const handleChange = (e) =>
    setLabelsSet({ ...labelsSet, [e.target.name]: e.target.checked });

  const labels = {
    diseases: 'Diseases'
  };

  const t = useAutoTranslation();

  let isClinicallyConfirmed = diagnoses.some(
    (diagnose) => diagnose.clinicallyConfirmed
  );

  return (
    <>
      <Grid item>
        {labelsSet && (
          <FormGroup column>
            {Object.keys(PEDIGREE.legendLabelFilters).map((label) => {
              return (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={labelsSet[label]}
                      onChange={handleChange}
                      size="small"
                      name={label}
                    />
                  }
                  label={t(labels[label])}
                />
              );
            })}
          </FormGroup>
        )}

        {(!labelsSet || labelsSet?.diseases) && (
          <Grid container className={classes.container} id={id}>
            {diagnoses.map((el, i) => {
              return (
                <div key={i} className={classes.boxHolder}>
                  <span
                    className={classes.item}
                    style={{
                      backgroundColor: el.color ?? 'black'
                    }}
                  ></span>
                  <span>{t(el.name)}</span>
                </div>
              );
            })}

            {showLegendTxt && isClinicallyConfirmed && (
              <div className={classes.boxHolder}>
                <span
                  className={`${classes.item} ${classes.confirmedBox}`}
                ></span>
                <span className={classes.confirmedText}>
                  {t('Disease clinically confirmed')}
                </span>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
