import React, { useRef, useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Grid,
  MenuItem,
  Select,
  Button,
  CircularProgress
} from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  AdjacentListsSupport,
  Alignment,
  Autoformat,
  AutoLink,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  CloudServices,
  Clipboard,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  ButtonView,
  Plugin,
  icons
} from 'ckeditor5';
import { APICONSTANTS } from 'src/components/Constants';
import TextInput from 'src/components/UI/input/TextInput';
import axios from 'src/axios';
import 'ckeditor5/ckeditor5.css';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { SnackContext } from 'src/store/ContextStore';
import {
  exportToPDF,
  exportToDocs
} from 'src/components/settings/templates/util.js';
import { mergeFieldsOptions } from 'src/components/settings/templates/constants.js';

const baseConfig = {
  licenseKey: 'GPL',
  menuBar: {
    isVisible: true
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h2',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h3',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h4',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h5',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h6',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      }
    ]
  },
  fontFamily: {
    supportAllValues: true
  },
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  image: {
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage'
    ]
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://'
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
      'toggleTableCaption'
    ]
  }
};

const basicPlugins = [
  AdjacentListsSupport,
  Alignment,
  Autoformat,
  AutoLink,
  BlockQuote,
  Bold,
  CloudServices,
  Clipboard,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline
];

const toolbar = {
  shouldNotGroupWhenFull: true,
  items: [
    'undo',
    'redo',
    'exportToPdf',
    'exportToDocs',
    'timestamp',
    '|',
    'insertMergeField',
    'previewMergeFields',
    '|',
    'importWord',
    'exportWord',
    'exportPdf',
    '|',
    'insertTemplate',
    '|',
    'heading',
    '|',
    {
      label: 'Font styles',
      icon: 'text',
      items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor']
    },
    'removeFormat',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'link',
    'insertImage',
    'insertTable',
    '|',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent'
  ]
};

const formFields = [
  { id: 'name', label: 'Name', type: 'text' },
  { id: 'description', label: 'Description', type: 'text' }
];

// Initial Validation
const initialValidationSchema = yup.object({
  name: yup.string('Enter the name').required('This field must be specified'),
  description: yup.string('Enter the description')
});

class ExportToPdf extends Plugin {
  init() {
    const editor = this.editor;

    // Retrieve the export function from the editor config
    const exportToPDF = editor.config.get('exportToPDF');

    editor.ui.componentFactory.add('exportToPdf', () => {
      console.log('Export to PDF button added');
      const button = new ButtonView();

      button.set({
        label: 'Export to PDF',
        withText: true
      });

      // Execute the provided export function when clicked
      button.on('execute', () => {
        if (exportToPDF) {
          const content = editor.getData();
          exportToPDF(content);
        } else {
          console.error('exportToPDF function is not defined in config.');
        }
      });

      return button;
    });
  }
}

class ExportToDocs extends Plugin {
  init() {
    const editor = this.editor;

    // Retrieve the export function from the editor config
    const exportToDocs = editor.config.get('exportToDocs');

    editor.ui.componentFactory.add('exportToDocs', () => {
      const button = new ButtonView();

      button.set({
        label: 'Export to DOCX',
        withText: true, // Show button text
        icon: icons.file
      });

      // Execute the provided export function when clicked
      button.on('execute', () => {
        if (exportToDocs) {
          const content = editor.getData();
          exportToDocs(content);
        } else {
          console.error('exportToDOCS function is not defined in config.');
        }
      });

      return button;
    });
  }
}

const GenerateLetterModal = ({
  handleClose,
  memberId,
  isEditing,
  selectedLetter,
  onUpdateLetter,
  onCreateLetter,
  templates
}) => {
  const editorRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [editorReady, setEditorReady] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const { setSnack } = useContext(SnackContext);

  const t = useAutoTranslation();

  // handleSubmit
  const handleFormSubmit = async (values) => {
    if (editorRef.current) {
      const content = editorRef.current.getData();
      const payload = {
        name: values.name,
        description: values.description,
        content: content,
        familyMemberId: memberId
      };

      setLoading(true);

      if (isEditing) {
        axios
          .put(
            APICONSTANTS.updateLetter.replace(':id', selectedLetter._id),
            payload
          )
          .then(({ data }) => {
            console.log('data updateLetter-->>>', data);
            onUpdateLetter(data.updatedLetter);
            handleClose();
            setSnack({
              status: true,
              msg: 'Letter successfully updated!',
              severity: 'success'
            });
          })
          .catch((err) => {
            console.log('errr-->>', err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        axios
          .post(APICONSTANTS.createLetter, payload)
          .then(({ data }) => {
            console.log('letter', data.letter);
            onCreateLetter(data.letter);
            handleClose();
            setSnack({
              status: true,
              msg: 'Letter successfully created!',
              severity: 'success'
            });
          })
          .catch((err) => {
            console.log('errr-->>', err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  // Formik
  const formik = useFormik({
    initialValues: {
      name: isEditing && selectedLetter ? selectedLetter.name : '',
      description: isEditing && selectedLetter ? selectedLetter.description : ''
    },
    validationSchema: initialValidationSchema,
    onSubmit: handleFormSubmit
  });

  // Set CKEditor content when editing
  useEffect(() => {
    if (isEditing && selectedLetter && editorReady) {
      editorRef.current.setData(selectedLetter.content);
    }
  }, [isEditing, selectedLetter, editorReady]);

  const insertMergeField = (fieldLabel) => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const fieldText = `{{${fieldLabel}}}`; // Format as a placeholder

      editor.model.change((writer) => {
        const insertPosition =
          editor.model.document.selection.getFirstPosition();
        writer.insertText(fieldText, insertPosition);
      });

      setSelectedValue('');
    }
  };

  const insertTemplate = (templateData) => {
    if (editorRef.current) {
      const editor = editorRef.current;

      editor.model.change((writer) => {
        const viewFragment = editor.data.processor.toView(templateData);
        const modelFragment = editor.data.toModel(viewFragment);

        const selection = editor.model.document.selection;
        editor.model.insertContent(modelFragment, selection);
      });

      setSelectedTemplate('');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {formFields.map((formField, index) => (
                <Grid item xs={12} key={`Initial_Form_Field_${index}`}>
                  <TextInput
                    variant="outlined"
                    id={formField.id}
                    name={formField.id}
                    label={formField.label}
                    value={formik.values[formField.id]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched[formField.id] &&
                      Boolean(formik.errors[formField.id])
                    }
                    helperText={
                      formik.touched[formField.id] &&
                      formik.errors[formField.id]
                    }
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Select
            value={selectedValue}
            onChange={(e) => insertMergeField(e.target.value)}
            displayEmpty
            style={{ minWidth: 150, marginRight: 10 }}
          >
            <MenuItem value="" disabled>
              {'Select Merge Field'} ▼
            </MenuItem>
            {mergeFieldsOptions.map((field) => (
              <MenuItem key={field.id} value={field.label}>
                {field.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Templates Dropdown */}
        <Grid item xs={12}>
          <Select
            value={selectedTemplate}
            onChange={(e) => insertTemplate(e.target.value)}
            displayEmpty
            style={{ minWidth: 200, marginBottom: 10, marginRight: 10 }}
          >
            <MenuItem value="" disabled>
              Select a Template ▼
            </MenuItem>
            {templates.map((template) => (
              <MenuItem key={template._id} value={template.content}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <CKEditor
            editor={ClassicEditor}
            config={{
              height: '500px',
              exportToPDF,
              exportToDocs,
              plugins: [...basicPlugins, ExportToPdf, ExportToDocs],
              toolbar: toolbar,
              ...baseConfig
            }}
            onReady={(editor) => {
              editorRef.current = editor;
              setEditorReady(true);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
            paddingTop: 40
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={formik.handleSubmit}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={15} /> : undefined}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenerateLetterModal;
