import {
  AdjacentListsSupport,
  Alignment,
  Autoformat,
  AutoLink,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  CloudServices,
  Clipboard,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline
} from 'ckeditor5';

// Merge field options
export const mergeFieldsOptions = [
  { id: 'familyId', label: 'Family Name' },
  { id: 'upn', label: 'UPN' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'dob', label: 'DOB' },
  { id: 'gender', label: 'Gender' },
  { id: 'address', label: 'Address' },
  { id: 'email', label: 'Email' },
  { id: 'hospitalNumber', label: 'Hospital Number' },
  { id: 'healthcareId', label: 'Health ID Number' },
  { id: 'staffMember', label: 'Staff Member' },
  { id: 'title', label: 'Title' }
];

export const baseConfig = {
  licenseKey: 'GPL',
  menuBar: {
    isVisible: true
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h2',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h3',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h4',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h5',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h6',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      }
    ]
  },
  fontFamily: {
    supportAllValues: true
  },
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  image: {
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage'
    ]
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://'
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
      'toggleTableCaption'
    ]
  }
};

export const basicPlugins = [
  AdjacentListsSupport,
  Alignment,
  Autoformat,
  AutoLink,
  BlockQuote,
  Bold,
  CloudServices,
  Clipboard,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline
];

export const toolbar = {
  shouldNotGroupWhenFull: true,
  items: [
    'undo',
    'redo',
    'exportToPdf',
    'exportToDocs',
    'mergeFieldDropdown',
    'mergeFieldPreviewDropdown',
    'templateDropdown',
    '|',
    'insertMergeField',
    'previewMergeFields',
    '|',
    'importWord',
    'exportWord',
    'exportPdf',
    '|',
    'insertTemplate',
    '|',
    'heading',
    '|',
    {
      label: 'Font styles',
      icon: 'text',
      items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor']
    },
    'removeFormat',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'link',
    'insertImage',
    'insertTable',
    '|',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent'
  ]
};
