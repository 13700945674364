import React, { useContext, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import makeStyles from '@mui/styles/makeStyles';
import Pedigreechart from './PedigreeChart/Pedigreechart';
import {
  AppContext,
  SnackContext,
  UserContext,
  ThemeContext
} from 'src/store/ContextStore';
import {
  Button,
  Grid,
  Typography,
  Box,
  Popper,
  IconButton,
  Slider,
  Chip,
  TextField
} from '@mui/material';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import {
  defaultPedigreeConfig,
  RELATIONS,
  relationship,
  synchronizeData,
  validatePedigree
} from './pedigreeUtil';
import axios from 'src/axios';
import { GENDER } from '../questionnaire/Constants';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import UnhideModal from './UnhideModal';
import { useAutoTranslation } from 'src/hooks/useTranslate';

import PedigreeConfirmModel from './PedigreeConfirmModel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { APICONSTANTS, CONSTANTS, PEDIGREE } from '../Constants';
import { init_Cache, next_pos, prev_pos } from './PedigreeChart/cache';
import PedigreeSideBar from './pedigree-sidebar/PedigreeSideBar';
import { replacer, SIDEBAR_VALUE } from './pedigree-sidebar/util';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import { EVENTS, getUniqueDiagnoses } from 'src/components/admin/appReducer';
import DiagnosisModal from './DiagnosisModal';
import PhenotypesModal from './PhenotypesModal';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: 'flex',
    gap: 20,
    position: 'absolute',
    top: 55,
    left: 12,
    [theme.breakpoints.up('sm')]: {
      top: 11,
      left: 175
    }
  },
  hideTxtContainer: (props) => ({
    position: 'absolute',
    zIndex: 10,
    top: props.patient ? 55 : 100,
    left: 12,
    [theme.breakpoints.up('sm')]: {
      top: 55,
      left: 12
    }
  }),
  rightContainer: {
    position: 'absolute',
    right: 30,
    top: 10,
    zIndex: 9,
    width: 280,
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  datesContainer: {
    position: 'absolute',
    right: 30,
    bottom: 30,
    width: 260,
    zIndex: -1
  }
}));

import {
  CheckCircleOutline,
  Info,
  InfoOutlined,
  Settings,
  TextDecreaseOutlined,
  TextIncreaseOutlined
} from '@mui/icons-material';
import LegendMenu from './pedigree-sidebar/LegendMenu';
import Menu from './Menu/Menu';
import FormLabel from '../UI/input/CustomFormLabel';
import TextInput from '../UI/input/TextInput';
import {
  getOrganizeTwinData,
  removeTwinIdentifiers
} from './PedigreeChart/utils';
import PhenotypesLegend from './pedigree-sidebar/PhenotypesLegend';
// import PhenotypesModal from './PhenotypesModal';
import NodeReassignMenu from './NodeReassignMenu';
import LinkMenu from './LinkMenu/LinkMenu';

function PedigreeEditor({
  labelsSet,
  patient,
  setLabelsSet,
  options,
  setOptions,
  onConfigSave
}) {
  const classes = useStyles();
  const { user: loggedInUser } = React.useContext(UserContext);

  const [rootConfig, setRootConfig] = React.useState(null);
  // let isStudent = useMemo(() => {
  //   return loggedInUser.userType === CONSTANTS.userTypes.STUDENT;
  // }, [loggedInUser]);

  const { content, dispatchUpdate } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user, family } = users;
  const { setSnack } = useContext(SnackContext);
  const { theme } = useContext(ThemeContext);

  const [sidebar, setSidebar] = useState({
    open: false,
    expand: false,
    expandAccordian: []
  });
  const [openModal, setOpenModal] = React.useState(false);

  const t = useAutoTranslation();

  const [deepCopy, setDeepCopy] = useState(null);
  const [dataset, setDataset] = useState(null);
  // const [cancel, setCancel] = useState(true);

  const [open, setOpen] = React.useState(false);

  // state for context menu
  const [menu, setMenu] = React.useState(false);
  // state for link menu
  const [openlinkMenu, setLinkMenu] = React.useState(false);
  const prevRel = React.useRef();

  // state for replace node model
  const [assignNodeModel, setAssignNodeModel] = React.useState(false);

  const [configMenu, setConfigMenu] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setMenu(false);
    setLinkMenu(false);

    if (prevRel.current) {
      d3.select(prevRel.current).attr('stroke', theme.appearance.text);
    }
  };

  const [error, setError] = React.useState(false);

  // pedigree state
  const [pedigreeState, setPedigreeState] = useState(
    CONSTANTS.pedigreeState.unchanged
  );

  const famliyCopy = JSON.parse(JSON.stringify(family));
  const config =
    user.pedigreeConfig && JSON.parse(JSON.stringify(user.pedigreeConfig));

  const history = useHistory();
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: ''
  });

  const handleGoToIntendedPage = React.useCallback(
    (location) => history.push(location),
    [history]
  );

  React.useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }

    const unblock = history.block((location) => {
      if (
        location.pathname !== '/pedigree/' &&
        pedigreeState === CONSTANTS.pedigreeState.modified
      ) {
        handleOpen();
      } else {
        setTriggerExit({ onOk: true, path: location.pathname });
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    pedigreeState
  ]);

  const initiatePedigree = () => {
    user.pid = user._id;
    // user.gender = user.gender && user.gender !== GENDER.unknown ? user.gender : GENDER.male;
    user.gender = user.gender ?? GENDER.unknown;

    let initialData;

    if (family.length === 0) {
      user.top_level = 'true';
      delete user.mother;
      delete user.father;
      delete user.spouse;
      initialData = [user];
    } else {
      if (user?.pedigreeConfig?.length === 0 || !user.pedigreeConfig) {
        const data = validatePedigree(user, famliyCopy);
        initialData = [...data, user];
      } else {
        initialData = synchronizeData(user, config, famliyCopy);
      }
    }
    return initialData;
  };

  React.useEffect(() => {
    const initialData = initiatePedigree();

    setDeepCopy(JSON.parse(JSON.stringify(initialData, replacer)));
    setDataset([...initialData]);
  }, [user]);

  React.useMemo(() => {
    sessionStorage.clear();
    const initialData = initiatePedigree();

    init_Cache(JSON.stringify(initialData, replacer), user.familyId);
  }, [user]);

  const [data, setData] = useState(null);

  // State for pedigree is in selection mode
  const [selectionMode, setSelectionMode] = useState(false);
  const selectionModeRef = useRef(false);

  const prevNode = React.useRef();

  React.useEffect(() => {
    if (data) {
      const parentGroup = d3.selectAll('g').filter(function (d) {
        return d?.data?.pid === data.data.pid;
      });

      let target = parentGroup.select('path.te');

      prevNode.current = target._groups[0][0];
      let node = target.datum();
      node.nodeRef = prevNode.current;
      setData(node);
      target.style('stroke-width', '0.25em').style('stroke', theme.accent);
    }
  }, [dataset]);

  const handleClick = (event, d, nodeRef, panels) => {
    if (prevNode.current) {
      d3.select(prevNode.current).style('stroke-width', '.11em');
      d3.select(prevNode.current).style('stroke', theme.appearance.text);
    }
    // If user is not patient
    if (!patient && !selectionModeRef.current) {
      // Make selected node highlighted
      d3.select(nodeRef).style('stroke-width', '.25em');
      d3.select(nodeRef).style('stroke', theme.accent);
      prevNode.current = nodeRef;
      setData({ ...d, nodeRef: nodeRef });
      const prevPanel =
        localStorage.getItem('sidebar-panel') || SIDEBAR_VALUE.addRelative;

      setSidebar((pre) => ({
        ...pre,
        open: true,
        expandAccordian: panels ? panels : [prevPanel] // Make Legend & Add Relative default expanded
      }));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRelationClick = (e, link, d) => {
    if (!patient) {
      setLinkMenu({ link, d });
      setAnchorEl(e.currentTarget);
    }
    if (prevRel.current) {
      d3.select(prevRel.current).attr('stroke', theme.appearance.text);
    }
    if (link) {
      d3.select(link).attr('stroke', theme.accent + '99');
      prevRel.current = link;
    }
  };

  const handleDoubleClick = (e, node) => {
    setAssignNodeModel(node.data);
  };

  const [saveState, setSaveState] = React.useState(PEDIGREE.saveStatus.SAVED);

  const undo = () => {
    const pos = prev_pos();
    const data = sessionStorage.getItem(`${user.familyId + '-' + pos}`);
    if (data) {
      setDeepCopy(() => {
        return JSON.parse(data);
      });
      setDataset(() => {
        return JSON.parse(data);
      });
    }
  };

  const redo = () => {
    const pos = next_pos();
    const data = sessionStorage.getItem(`${user.familyId + '-' + pos}`);
    if (data) {
      setDeepCopy(() => {
        return JSON.parse(data);
      });
      setDataset(() => {
        return JSON.parse(data);
      });
    }
  };

  const handleDetailsChange = (members) => {
    axios
      .post(
        APICONSTANTS.savePedigreeMemberDetails.replace(':user_id', user._id),
        { members }
      )
      .then(() => {
        setSaveState(PEDIGREE.saveStatus.SAVED);
      })
      .catch((/*err*/) =>
        setSnack({
          status: true,
          msg: 'An Error occured',
          severity: 'error'
        }));
  };

  const onSumbit = async () => {
    const updatedDeepcopy = removeTwinIdentifiers(deepCopy);
    const data = updatedDeepcopy.filter(
      (p) => p.pid !== user._id && !p.hidden && !p.upn && !p.deleted
    );
    const config = updatedDeepcopy.filter((p) => p.pid !== user._id);
    const personalDetails = updatedDeepcopy.filter(
      (p) => p.pid === user._id
    )[0];

    if (data.length > 0) {
      axios
        .post(APICONSTANTS.savePedigree.replace(':id', user._id), {
          personalDetails,
          data,
          config,
          familyId: user.familyId
        })
        .then(({ status, data }) => {
          if (status === 201) {
            const newDataset = deepCopy.map((pre) => {
              if (pre.proBandId) {
                // Here update dateModified for proband
                return {
                  ...pre,
                  dateModified: data.dateModified
                };
              } else {
                if (pre.not_set === 'true' || pre.upn) {
                  return { ...pre };
                } else {
                  let updatedFamilyData = data.family.find(
                    (familyData) => pre.pid === familyData.pid
                  );

                  return {
                    ...pre,
                    member_id: updatedFamilyData?.member_id,
                    upn: updatedFamilyData?.upn,
                    familyMemberId: updatedFamilyData?.familyMemberId
                  };
                }
              }
            });

            setDeepCopy(() => {
              return JSON.parse(JSON.stringify(newDataset));
            });
            setDataset(newDataset);

            setPedigreeState(CONSTANTS.pedigreeState.unchanged);

            // Here update diagnoses
            const diagnoses = getUniqueDiagnoses(newDataset);
            dispatchUpdate({
              type: EVENTS.SET_PEDIGREE_SELECTED_USER,
              value: {
                ...users,
                diagnoses: diagnoses
              }
            });
            setSaveState(PEDIGREE.saveStatus.SAVED);

            // setSnack({
            //   status: true,
            //   msg: 'Successfully saved',
            //   severity: 'success'
            // });
          }
        })
        .catch((err) => {
          if (err.status === 413) {
            setSnack({
              status: true,
              msg: 'Pedigree Maximum members has exceeded',
              severity: 'warning'
            });
          } else {
            setSnack({
              status: true,
              msg: 'An error occured',
              severity: 'error'
            });
          }
        });
    }
    setPedigreeState(CONSTANTS.pedigreeState.unchanged);
    setSaveState(PEDIGREE.saveStatus.SAVED);
  };

  function debounce(func, wait = 1) {
    let timeout;
    return (...args) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  let debounceSave = debounce(onSumbit, 0);

  React.useEffect(() => {
    if (dataset && pedigreeState === CONSTANTS.pedigreeState.modified) {
      setSaveState(PEDIGREE.saveStatus.IN_PROGRESS);
      debounceSave();
    }
  }, [dataset]);

  const onContinue = async (e, save = false) => {
    if (save) {
      onSumbit();
    }
    setTriggerExit((obj) => ({
      ...obj,
      onOk: true
    }));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const unHideHandle = (updatedDatasetID) => {
    handleCloseModal();
    let updatedNodes = [];

    const newDataset = deepCopy.map((pre) => {
      const shouldUnhide = updatedDatasetID.includes(pre.pid);

      if (shouldUnhide) {
        updatedNodes.push({
          ...pre,
          isHide: false
        });
      }

      return {
        ...pre,
        isHide: shouldUnhide ? false : pre.isHide
      };
    });

    setPedigreeState(CONSTANTS.pedigreeState.modified);

    handleDetailsChange(updatedNodes);

    setDeepCopy(() => JSON.parse(JSON.stringify(newDataset)));
    setDataset(newDataset);
  };

  const onNodeClick = (e, d) => {
    if (!patient) {
      setMenu({ d });
      setAnchorEl(e.currentTarget);
    }
  };

  const pedigree = useMemo(() => {
    if (dataset) {
      return (
        <Pedigreechart
          options={options}
          dataset={dataset}
          handleClick={handleClick}
          handleRelationClick={handleRelationClick}
          handleDoubleClick={handleDoubleClick}
          undo={undo}
          redo={redo}
          onNodeClick={onNodeClick}
          onCloseMenu={handleClose}
          setRootConfig={setRootConfig}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, options]);

  const handleCloseDialog = () => {
    setError(false);
    setAssignNodeModel(false);
  };

  const isHide = React.useMemo(() => {
    return dataset?.some((data) => data.isHide);
  }, [dataset]);

  const hideDataset = React.useMemo(() => {
    return dataset?.filter((data) => data.isHide);
  }, [dataset]);

  let headerTitle = useMemo(() => {
    return `PEDIGREE: ${user?.familyId}`;
  }, [user]);

  const handleSelectionExit = (state) => {
    setSelectionMode(state);
  };

  const contextMenu = React.useMemo(() => {
    return (
      <Menu
        rootConfig={rootConfig}
        node={menu.d}
        setPedigreeState={setPedigreeState}
        data={data}
        deepCopy={deepCopy}
        setDataset={setDataset}
        setDeepCopy={setDeepCopy}
        handleClose={handleClose}
        dataset={dataset}
        setError={setError}
        saveState={saveState}
        selectionMode={selectionMode}
        selectionModeRef={selectionModeRef}
        handleSelectionExit={handleSelectionExit}
        handleDetailsChange={handleDetailsChange}
      />
    );
  }, [menu, dataset]);

  const linkMenu = React.useMemo(() => {
    return (
      <LinkMenu
        rootConfig={rootConfig}
        node={openlinkMenu}
        deepCopy={deepCopy}
        setDataset={setDataset}
        setDeepCopy={setDeepCopy}
        handleClose={handleClose}
      />
    );
  }, [openlinkMenu, dataset]);

  const relMap = {
    [RELATIONS.siblings]: 'sibling',
    [RELATIONS.children]: 'parent',
    [RELATIONS.partner]: 'spouse'
  };

  const pedigreeSideBar = React.useMemo(() => {
    return (
      <PedigreeSideBar
        saveState={saveState}
        data={data}
        deepCopy={deepCopy}
        setDeepCopy={setDeepCopy}
        setDataset={setDataset}
        dataset={dataset}
        setPedigreeState={setPedigreeState}
        setSidebar={setSidebar}
        sidebar={sidebar}
        setData={setData}
        labelsSet={labelsSet}
        setLabelsSet={setLabelsSet}
        setError={setError}
        handleDetailsChange={handleDetailsChange}
      />
    );
  }, [data, sidebar, labelsSet, deepCopy]);

  return (
    <Box position={'relative'}>
      {/* Pedigree Dates used for when exporting as img or pdf from pedigree sidebar.
          This will not appear on pedigree canvas */}
      <Box
        className={classes.datesContainer}
        id="pedigree-dates"
        sx={{
          border: 0,
          padding: '8px',
          backgroundColor: '#f3f3f3',
          borderRadius: '10px'
        }}
      >
        <PedigreeDates dataset={dataset} theme={theme} />
      </Box>

      {/* Pedigree SideBar (Hide for patient) */}
      {!patient && pedigreeSideBar}

      {/* Pedigree Context Menu (Hide for patient) */}
      {!patient && !selectionMode && (
        <Popper open={menu} anchorEl={anchorEl}>
          {contextMenu}
        </Popper>
      )}

      {/* Pedigree Link Relation Menu (Hide for patient) */}
      {!patient && !selectionMode && (
        <Popper
          open={openlinkMenu}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: openlinkMenu?.d?.target ? [50, 10] : [10, -200]
              }
            }
          ]}
          placement="right-start"
        >
          {linkMenu}
        </Popper>
      )}

      {deepCopy && (
        <NodeReassignMenu
          open={assignNodeModel}
          handleClose={handleCloseDialog}
          deepCopy={deepCopy}
          setDataset={setDataset}
          setDeepCopy={setDeepCopy}
          user={user}
          setSnack={setSnack}
        />
      )}

      <CustomDialog
        open={error}
        title={'Info'}
        handleClose={handleCloseDialog}
        minHeight={100}
        distanceTop={100}
        allowBackgroundClose={true}
        showCancelBtn={true}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Info fontSize="large" />
          {error}
        </Box>
      </CustomDialog>

      <CustomDialog
        open={configMenu}
        title={'Pedigree Settings'}
        handleClose={() => {
          setConfigMenu(false);
        }}
        showActions={true}
        onSave={() => {
          setConfigMenu(false);
          return onConfigSave();
        }}
        minHeight={400}
        distanceTop={150}
        allowBackgroundClose={true}
      >
        <ConfigMenu options={options} setOptions={setOptions} />
      </CustomDialog>

      <Grid
        className={classes.rightContainer}
        style={{
          height: '100%',
          pointerEvents: 'none'
        }}
      >
        <Box
          position={'absolute'}
          left={-50}
          top={-3}
          sx={{ pointerEvents: 'auto' }}
        >
          <IconButton onClick={() => setConfigMenu(true)}>
            <Settings />
          </IconButton>
        </Box>

        {/* Heading */}
        <HeaderTitle
          variant="h5"
          text={headerTitle}
          autoWidth
          fontWeight={500}
          style={{ marginRight: '20px' }}
        />

        {/* Legend Menu */}
        {labelsSet.diseases && (
          <div
            style={{
              marginBottom: users.diagnoses.length ? '15px' : '0px',
              pointerEvents: 'auto',
              backgroundColor: 'white'
            }}
          >
            {users.diagnoses.length > 0 ? (
              <Typography variant="h6" style={{ fontSize: '1rem' }}>
                <span>{t('Diseases')}</span>
              </Typography>
            ) : null}
            <DiagnosisModal
              setPedigreeState={setPedigreeState}
              deepCopy={deepCopy}
              setDeepCopy={setDeepCopy}
              setDataset={setDataset}
              diagnoses={users.diagnoses}
            />
            <LegendMenu
              diagnoses={users.diagnoses}
              id="diagnoses-legend-canvas"
              showLegendTxt
            />
          </div>
        )}

        {/* Phenotypes menu */}
        <div
          style={{
            marginBottom: users.phenotypes.length ? '15px' : '0px',
            pointerEvents: 'auto',
            backgroundColor: 'white'
          }}
        >
          {users.phenotypes.length > 0 ? (
            <Typography variant="h6" style={{ fontSize: '1rem' }}>
              <span>{t('Phenotypes')}</span>
            </Typography>
          ) : null}

          <PhenotypesModal
            setPedigreeState={setPedigreeState}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setDataset={setDataset}
            phenotypes={users.phenotypes}
            labelsSet={labelsSet}
          />

          <PhenotypesLegend
            phenotypes={users.phenotypes}
            id="phenotypes-legend-canvas"
          />
        </div>

        {/* Pedigree Dates */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 100,
            border: 0,
            padding: '8px',
            backgroundColor: theme.appearance.color,
            borderRadius: '10px'
          }}
        >
          <PedigreeDates dataset={dataset} theme={theme} />
        </Box>
      </Grid>

      <Grid sx={{ height: '100%' }}>
        {isHide && (
          <Grid item xs={12} className={classes.hideTxtContainer}>
            <Alert severity="info">
              This pedigree has hidden family members.
            </Alert>
          </Grid>
        )}

        <CustomDialog
          title={'Unhide Family Members'}
          open={openModal}
          handleClose={handleCloseModal}
          minHeight="auto"
        >
          <UnhideModal hideDataset={hideDataset} unHideHandle={unHideHandle} />
        </CustomDialog>

        <PedigreeConfirmModel
          open={open}
          handleClose={handleClose}
          onContinue={onContinue}
        />
        {selectionMode && (
          <Box
            position={'absolute'}
            zIndex={1000}
            bgcolor={'#eeeeee'}
            top={5}
            width={'100%'}
            height={44}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Alert severity="warning" icon=" " sx={{ fontWeight: 'bold' }}>
              Entered Selection Mode : Select a{' '}
              {relMap[selectionMode?.relation]} for UPN {selectionMode.node.upn}
              <br />
              <Typography
                textAlign={'center'}
                fontSize={'small'}
                fontWeight={'bold'}
                display={'flex'}
                alignItems={'center'}
                gap={1}
                mt={0.5}
              >
                <InfoOutlined fontSize="small" />
                Please select the same highlighted node to exit
              </Typography>
            </Alert>
          </Box>
        )}
        <Box sx={{ height: '100%' }}>{pedigree}</Box>
        {/* Hide for patient */}
        {!patient && !selectionMode && (
          <Grid className={classes.btnContainer}>
            {isHide && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setOpenModal(true)}
                disabled={!isHide}
              >
                {t(`UnHide`)}
              </Button>
            )}

            <Chip
              label={t(saveState)}
              color={
                saveState === PEDIGREE.saveStatus.SAVED ? 'success' : 'info'
              }
              size="medium"
              variant="outlined"
              icon={
                saveState === PEDIGREE.saveStatus.SAVED && (
                  <CheckCircleOutline fontSize="small" />
                )
              }
              sx={{ px: 2, mt: 0.2, fontWeight: 'bold' }}
            />

            {/* FONT SIZE */}
            <Grid>
              <FontSlider options={options} setOptions={setOptions} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PedigreeEditor;

const PedigreeDates = ({ dataset, theme }) => {
  if (!theme.dateFormat) theme = { dateFormat: 'MM/DD/YYYY' };
  const t = useAutoTranslation();

  let probandUser = useMemo(() => {
    return dataset?.find((data) => !!data.proBandId) || {};
  }, [dataset]);

  return (
    <Grid container>
      {[
        { label: t('Date Created:'), date: probandUser.dateGenerated },
        {
          label: t('Last Modified Date:'),
          date: probandUser.dateModified || probandUser.dateGenerated
        },
        { label: t('Print Date:'), date: new Date() }
      ].map(({ label, date }, index) => (
        <Grid item container direction="row" xs={12} key={index}>
          <Typography
            variant="h6"
            style={{ fontSize: '1rem', display: 'flex', gap: '8px' }}
          >
            <strong style={{ minWidth: '150px', display: 'inline-block' }}>
              {label}
            </strong>
            <span>{moment(date).format(theme.dateFormat)}</span>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const FontSlider = ({ options, setOptions }) => {
  const onChange = (e, val) => {
    if (val) {
      let fontSize = parseInt(options.fontSize) + val;
      if (fontSize < 10) fontSize = 10;
      if (fontSize > 30) fontSize = 30;
      setOptions((prev) => ({ ...prev, fontSize: fontSize }));
    } else {
      setOptions((prev) => ({ ...prev, fontSize: e.target.value }));
    }
  };

  return (
    <Box
      position={'absolute'}
      width={200}
      bottom={-45}
      left={-205}
      display={'flex'}
      alignItems={'center'}
      gap={2}
    >
      <IconButton onClick={(e) => onChange(e, -2)}>
        <TextDecreaseOutlined fontSize="small" />
      </IconButton>
      <Slider
        size="medium"
        step={2}
        marks
        value={options.fontSize}
        min={10}
        max={30}
        name="fontSize"
        onChange={(e) => onChange(e)}
      />
      <IconButton onClick={(e) => onChange(e, 2)}>
        <TextIncreaseOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

const ConfigMenu = ({ options, setOptions }) => {
  const handleChange = (e) => {
    setOptions((prev) => {
      const opt = {
        ...prev,
        [e.target.name]: e.target.value
      };

      return opt;
    });
  };

  const reset = () => {
    setOptions(defaultPedigreeConfig);
  };

  return (
    <form className="user-form">
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={2}>
            <FormLabel component="label" className="form-label">
              Symbol
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', w: 1, gap: 3, alignItems: 'center' }}>
              <TextInput
                variant="outlined"
                onChange={handleChange}
                fullWidth
                name="symbol_size"
                value={options.symbol_size}
                placeholder="45"
              />

              <Slider
                step={2}
                marks
                value={options.symbol_size}
                min={35}
                max={55}
                name="symbol_size"
                onChange={handleChange}
              />

              <strong>{options.symbol_size}px</strong>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={2}>
            <FormLabel component="label" className="form-label">
              Font Size
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', w: 1, gap: 3, alignItems: 'center' }}>
              <TextInput
                variant="outlined"
                name="fontSize"
                value={options.fontSize}
                placeholder="16"
                onChange={handleChange}
              />
              <Slider
                step={2}
                marks
                value={options.fontSize}
                min={10}
                max={25}
                name="fontSize"
                onChange={handleChange}
              />
              <strong>{options.fontSize}px</strong>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={2}>
            <FormLabel component="label" className="form-label">
              Dimensions
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={5}>
            {console.log(options)}
            <Box sx={{ display: 'flex', w: 1, gap: 3, alignItems: 'center' }}>
              <TextInput
                variant="outlined"
                name="horizontal_spacing"
                value={options.horizontal_spacing}
                placeholder="100"
                onChange={handleChange}
              />
              X
              <TextInput
                variant="outlined"
                name="vertical_spacing"
                value={options.vertical_spacing}
                placeholder="100"
                onChange={handleChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ position: 'absolute', right: 10, bottom: 80 }}>
        <Button variant="contained" size="small" onClick={reset}>
          Reset to Defaults
        </Button>
      </Box>
    </form>
  );
};
