import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { ThemeContext } from 'src/store/ContextStore';

const useStyles = makeStyles((theme) => ({
  line1: {
    fontSize: '1.25rem',
    fontWeight: 'bold'
  },
  line2: {
    fontSize: '1rem'
  },
  line3: {
    fontSize: '1rem'
  }
}));

const joinParts = (...parts) => parts.filter(Boolean).join(', ');

const ModalHeader = ({ title, personSummary }) => {
  const classes = useStyles();
  const { theme } = useContext(ThemeContext);

  const { idPart, namePart, dobPart, hospitalPart, healthcarePart } =
    personSummary() || {};

  const formattedDOBPart = dobPart
    ? moment(dobPart).format(theme.dateFormat)
    : '';

  return (
    <div className={classes.root}>
      <Typography className={classes.line1}>{title}</Typography>

      {idPart || namePart || dobPart ? (
        <Typography className={classes.line2}>
          {joinParts(idPart, namePart, formattedDOBPart)}
        </Typography>
      ) : null}

      {hospitalPart || healthcarePart ? (
        <Typography className={classes.line3}>
          {joinParts(hospitalPart, healthcarePart)}
        </Typography>
      ) : null}
    </div>
  );
};

export default ModalHeader;
