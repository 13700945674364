import React from 'react';
import { Box, Grow, List, ListItemButton, Popper } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import AddRelativeMenu from '../pedigree-sidebar/AddRelativeMenu';
import { AppContext, ThemeContext } from 'src/store/ContextStore';
import FertilityMenu from './FertilityMenu';
import GenderMenu from './GenderMenu';
import AdoptedMenu from './AdoptedMenu';
import Comment from './Comment';
import EditText from './EditText';
import SetRelation from './SetRelation';
import DeletionMenu from './DeletionMenu';

export default function Menu({
  node,
  onCloseMenu,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  dataset,
  setPedigreeState,
  setError,
  selectionMode,
  handleSelectionExit,
  selectionModeRef,
  handleDetailsChange,
  rootConfig,
  saveState
}) {
  const [subMenu, setSubMenu] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    setSubMenu(false);
  }, [node]);

  const toggleSubMenu = (e, { val }) => {
    setAnchorEl(e.currentTarget);
    setSubMenu({ val });
  };

  const {
    content: {
      pedigreeSelectedUsers: { selectedUser }
    }
  } = React.useContext(AppContext);

  const { theme } = React.useContext(ThemeContext);

  const menu = React.useMemo(() => {
    switch (subMenu.val) {
      case 1:
        return (
          <AddRelativeMenu
            menu={true}
            setPedigreeState={setPedigreeState}
            user={selectedUser}
            data={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            dataset={dataset}
            setError={setError}
            onCloseMenu={onCloseMenu}
            saveState={saveState}
          />
        );
      case 2:
        return (
          <SetRelation
            node={node}
            user={selectedUser}
            handleClose={handleClose}
            selectionMode={selectionMode}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            setPedigreeState={setPedigreeState}
            dataset={dataset}
            setError={setError}
            handleSelectionExit={handleSelectionExit}
            selectionModeRef={selectionModeRef}
          />
        );
      case 3:
        return (
          <GenderMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            dataset={dataset}
            setError={setError}
            handleDetailsChange={handleDetailsChange}
          />
        );
      case 4:
        return (
          <AdoptedMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            handleDetailsChange={handleDetailsChange}
          />
        );
      case 5:
        return (
          <FertilityMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            handleDetailsChange={handleDetailsChange}
          />
        );
      case 6:
        return (
          <Comment
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            handleDetailsChange={handleDetailsChange}
          />
        );
      case 7:
        return (
          <EditText
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            handleDetailsChange={handleDetailsChange}
          />
        );
      case 9:
        return (
          <DeletionMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            rootConfig={rootConfig}
          />
        );

      default:
        return false;
    }
  }, [subMenu, node]);

  const onHideNode = () => {
    const { data } = node;

    const idx = deepCopy.findIndex((person) => data.pid === person.pid);

    deepCopy[idx] = {
      ...deepCopy[idx],
      isHide: true
    };

    handleDetailsChange([deepCopy[idx]]);

    setDeepCopy((deepCopy) => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);

    handleClose();
  };

  return (
    <>
      {node && (
        <Grow
          in={node}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            width={150}
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid',
              margin: 1
            }}
          >
            <List>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 1 })}>
                Add Relation <ArrowRight />
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 2 })}>
                Set Relation
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 3 })}>
                Gender
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 4 })}>
                Adopted
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 5 })}>
                Fertility
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 6 })}>
                Person Note
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 7 })}>
                Edit Text
              </ListItemButton>
              <ListItemButton onClick={onHideNode}>Hide</ListItemButton>
              {!node.data.proBandId && node.data.upn && (
                <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 9 })}>
                  Delete
                </ListItemButton>
              )}
            </List>
          </Box>
        </Grow>
      )}
      <Popper
        open={!!subMenu && !!node}
        anchorEl={anchorEl}
        placement="auto-start"
      >
        <Grow
          in={node}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid'
            }}
          >
            {menu}
          </Box>
        </Grow>
      </Popper>
    </>
  );
}
