import {
  createDropdown,
  addListToDropdown,
  Plugin,
  ViewModel,
  Collection
} from 'ckeditor5';
import TemplateStore from 'src/store/TemplateStore';
const templateIcon = `
 <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <!-- First Rectangle -->
  <path clip-rule="evenodd" d="M8 0H3a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM2.5 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V2Z" fill="black"/>
  
  <!-- Second Rectangle -->
  <path clip-rule="evenodd" d="M13 0h5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm0 1.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5V2a.5.5 0 0 0-.5-.5h-5Z" fill="black"/>
  
  <!-- Third Rectangle -->
  <path clip-rule="evenodd" d="M13 10h5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2Zm0 1.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5h-5Z" fill="black"/>
</svg>
`;
export default class TemplateDropdown extends Plugin {
  static get pluginName() {
    return 'TemplateDropdown';
  }

  async init() {
    const editor = this.editor;
    const t = editor.t;
    const dropdownTooltip = t('Insert Template');

    const store = TemplateStore.getInstance();
    let templates = await store.getTemplates();

    editor.ui.componentFactory.add('templateDropdown', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: 'Insert Template',
        tooltip: dropdownTooltip,
        icon: templateIcon,
        withText: false
      });

      dropdownView.extendTemplate({
        attributes: {
          class: ['ck-template-dropdown']
        }
      });

      // Create a collection for dropdown items
      const items = new Collection();

      // Add merge fields dynamically
      templates.forEach((field) => {
        const fieldModel = new ViewModel({
          id: field._id,
          label: field.name,
          content: field.content,
          withText: true
        });

        items.add({ type: 'button', model: fieldModel });
      });

      // Attach items to the dropdown
      addListToDropdown(dropdownView, items);

      dropdownView.on('execute', (evt) => {
        let templateData = evt.source.content;

        editor.model.change((writer) => {
          const viewFragment = editor.data.processor.toView(templateData);
          const modelFragment = editor.data.toModel(viewFragment);

          const selection = editor.model.document.selection;
          editor.model.insertContent(modelFragment, selection);
        });
      });

      return dropdownView;
    });
  }
}
