//This file is AppBar.jsx

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link, useHistory } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import EventIcon from '@mui/icons-material/Event';

import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';
import logo from 'src/assets/images/trakgeneLogo.png';

import HelpIcon from '@mui/icons-material/Help';
import { useTourContext } from 'src/components/tour/TourContext';

import TranslationMenu from 'src/components/settings/TranslationMenu';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import SystemAnnouncements from 'src/components/admin/SystemAnnouncements';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import { Modal } from '@mui/material';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import ModalHeader from 'src/components/pedigree/pedigree-sidebar/ModalHeader';
import { ClinicalNotesForm } from 'src/components/pedigree/pedigree-sidebar/forms/FormsExport';

import AppointmentForm2 from 'src/components/pedigree/pedigree-sidebar/forms/AppointmentForm_Notification';

const drawerWidth = 200;

export default React.memo(function CustomAppBar(props) {
  let [isModalOpen, setOpenModal] = React.useState(false);

  let [familyMemberId, setFamilyMemberId] = React.useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  let [currentModel, setCurrentModel] = React.useState({});

  const {
    theme: { appearance, navbar, navbarText, logoURL, logoName, appbarHeight }
  } = React.useContext(ThemeContext);

  const offset = appbarHeight ? appbarHeight : 65;

  const { user, setUser } = React.useContext(UserContext);
  const { userType } = user || {};
  const { startTour } = useTourContext();

  const t = useAutoTranslation();

  const history = useHistory();

  const colors = {
    Referral: '#4d9fb3',
    Appointment: '#53b382',
    Test: '#354051',
    Sample: '#de2054',
    Address: '#9b9b9b',
    'Clinical Note': '#f7b661',
    Contact: '#f6d956',
    Consent: '#14859a'
  };

  const [notifications, setNotifications] = React.useState([]);
  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState(null);

  const isNotificationsMenuOpen = Boolean(anchorElNotifications);

  const handleNotificationsOpen = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const handleOpenRecord = async (notification) => {
    const { familyId, familyMemberId, id } = notification;
    if (!familyId || !familyMemberId || !id) return;

    const url =
      CONSTANTS.pedigreeChartPath.replace(':_id', familyId) +
      `?familyMemberId=${familyMemberId}&appointmentId=${id}`;

    window.location.href = url;
    setAnchorElNotifications(null);
  };

  const handleDismissNotification = (notificationId) => {
    setNotifications((prev) => prev.filter((n) => n.id !== notificationId));
  };

  // 2. Fetch notifications from the backend
  React.useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          '/clinical-notes/appointments/notifications?onlyFuture=true'
        );
        // Filter out any notifications whose familyId is missing or empty
        const validNotifications = response.data.filter(
          (item) => item.familyId && String(item.familyId).trim() !== ''
        );
        setNotifications(validNotifications);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  // ----------------------
  // 其他现有逻辑
  // ----------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clearSession = () => {
    setAnchorEl(null);
    axios.get(APICONSTANTS.logout).then(() => {
      history.push(CONSTANTS.signinPath);
      setUser(null);
      localStorage.setItem('2FAloggedin', 'false');
    });
  };

  const handleStartTour = () => {
    startTour(userType);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user?.email && (
        <Typography
          variant="caption"
          display="block"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {t(`Logged In as`)}:
        </Typography>
      )}
      {user?.email && (
        <Typography
          variant="caption"
          display="block"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {user.email}
        </Typography>
      )}
      {props.menuItems &&
        React.cloneElement(props.menuItems, { handleMenuClose })}
      <MenuItem onClick={clearSession}>{t(`Log Out`)}</MenuItem>
    </Menu>
  );

  const useStyles = makeStyles((theme) => ({
    offset: {
      minHeight: offset
    },
    root: {
      flexGrow: 1
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: `${navbar ?? appearance.color}!important`,
      transition: 'background-color 1s',
      color: navbarText ?? appearance.text,
      borderBottom: '1px solid #c1c1c170',
      boxShadow: 'none',
      height: offset
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: `${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })}, background-color 1s`
    },
    link: {
      textDecoration: 'none !important',
      fontWeight: 'bolder',
      color: navbarText ?? appearance.text,
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      }
    },
    logo: {
      height: 40,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    menu: {
      '& .MuiPaper-root': {
        backgroundColor: appearance.color,
        color: appearance.text,
        boxShadow: `3px 3px 15px ${appearance.shade}`
      }
    },
    systemmessage: {
      '& .MuiPaper-root': {
        top: '9px',
        left: '25%',
        right: '25%',
        position: 'absolute',
        margin: 'auto'
      }
    },
    PEP: {
      [theme.breakpoints.down('md')]: {
        position: 'absolute'
      }
    },
    smlogo: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        height: 20
      }
    }
  }));

  const classes = useStyles();

  const notificationMenuId = 'primary-notifications-menu';

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.systemmessage}>
          <SystemAnnouncements />
        </div>
        <Toolbar>
          <img
            alt="Logo"
            src={logoURL && logoName ? logoURL : logo}
            className={classes.logo}
          />
          <Typography variant="h5" className={classes.PEP}>
            <Link to={CONSTANTS.adminPath} className={classes.link}>
              <img
                alt="TrakGene"
                src={logoURL && logoName ? logoURL : logo}
                className={classes.smlogo}
              />
              {props.title ? t(props.title) : t('')}
            </Link>
          </Typography>

          <div className={classes.root} />

          <TranslationMenu color={navbarText} />

          <IconButton
            edge="end"
            onClick={() => history.push(CONSTANTS.calendarPath)}
            color="inherit"
            fontSize="large"
            size="large"
          >
            <EventIcon />
          </IconButton>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleNotificationsOpen}
            style={{ marginRight: '2px' }}
            fontSize="large"
            size="large"
          >
            <Badge badgeContent={notifications.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <IconButton
            edge="end"
            onClick={handleStartTour}
            color="inherit"
            fontSize="large"
            size="large"
          >
            <HelpIcon />
          </IconButton>

          {user && (
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ marginRight: '10px' }}
              fontSize="small"
              size="large"
            >
              <AccountCircle />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorElNotifications}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={notificationMenuId}
        open={isNotificationsMenuOpen}
        onClose={handleNotificationsClose}
        className={classes.menu}
      >
        {notifications.length === 0 && (
          <MenuItem disabled>
            <Typography variant="body2">No upcoming reminders.</Typography>
          </MenuItem>
        )}

        {notifications.map((notification) => (
          <MenuItem
            key={notification.id}
            onClick={() => handleOpenRecord(notification)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                label={notification.type}
                size="small"
                sx={{
                  backgroundColor: colors[notification.type] || 'grey',
                  color: 'white',
                  marginRight: 2
                }}
              />
              <Typography variant="body2">
                {/* {notification.familyId} */}
                {`with ${notification.inviteeName} at `}
                {notification.appointmentDate
                  ? ` ${new Date(notification.appointmentDate).toLocaleString(
                      undefined,
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      }
                    )} — `
                  : ''}
                {notification.clinicName}
              </Typography>
            </div>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDismissNotification(notification.id);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </MenuItem>
        ))}
      </Menu>

      {renderMenu}
      <div className={classes.offset} />
      <Modal
        open={isModalOpen}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomDialog
          maxWidth="sm"
          title={
            <ModalHeader
              title={`Edit Appointment`}
              // personSummary={personSummary}
            />
          }
          open={isModalOpen}
          handleClose={handleClose}
          minHeight="auto"
          onClose={true}
        >
          <AppointmentForm2 _id={currentModel.id} />
        </CustomDialog>
      </Modal>
    </>
  );
});
