import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { Grid, Chip } from '@mui/material';
import TileCard from './NoteTile';

import axios from 'axios';

import {
  AddressForm,
  AppointmentForm,
  ClinicalNotesForm,
  ConsentForm,
  ContactForm,
  ReferralForm,
  SampleForm,
  TestForm
} from './forms/FormsExport';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AppContext } from 'src/store/ContextStore';
import HistoryIcon from '@mui/icons-material/History';
import { CONSTANTS } from 'src/components/Constants';
import { formatPersonSummary } from './util';
import ModalHeader from './ModalHeader';
import TemplateStore from 'src/store/TemplateStore';

function ClinicalNotes({ data, modificationHistoryHandle, deepCopy }) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { familyId } = user;

  let [isModalOpen, setOpenModal] = React.useState(false);
  let [noteType, setNoteType] = React.useState('Referral');
  let [isEditing, setIsEditing] = React.useState(false);
  let [currentModel, setCurrentModel] = React.useState({});
  const [staffMembers, setStaffMembers] = useState([]);

  const [filter, setFilter] = React.useState({
    Referral: true,
    Appointment: true,
    Contact: true,
    Consent: true,
    Sample: true,
    Test: true,
    Address: true,
    'Clinical Note': true
  });

  let [tiles, setTiles] = React.useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const familyMemberIdFromURL = queryParams.get('familyMemberId');
  const appointmentIdFromURL = queryParams.get('appointmentId');
  const isOpenedModal = useRef(false);

  let [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const loadTemplates = async () => {
      const store = TemplateStore.getInstance();
      await store.fetchTemplates();
    };
    loadTemplates();
  }, []);

  const staffMemberOptions = React.useMemo(() => {
    return staffMembers.map((d) => ({
      label: d.firstName + ' ' + d.lastName,
      value: d.userId
    }));
  }, [staffMembers]);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [newNote, setNewNote] = React.useState(false);
  const addClicked = async () => {
    setOpenModal(true);
    setNewNote(true);
    setIsDisabled(false);
  };

  const handleChanges = (key) => {
    let replace = { ...filter };
    replace[key] = !replace[key];
    setFilter(replace);
  };

  const [noteTypes] = React.useState([
    'Referral',
    'Appointment',
    'Contact',
    'Sample',
    'Test',
    'Address',
    'Clinical Note',
    'Consent'
  ]);

  const handleClose = () => {
    setOpenModal(false);
    setIsEditing(false);
    setNewNote(false);
  };
  const familyMemberId =
    data?.data?.familyMemberId ??
    data?.data?.proBandId ??
    data?.data?.pid ??
    'none';

  const forms = (type, model = {}) => {
    switch (type) {
      case 'Referral':
        return (
          <ReferralForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            isDisabled={isDisabled}
          />
        );
      case 'Appointment':
        return (
          <AppointmentForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
            isDisabled={isDisabled}
          />
        );
      case 'Contact':
        return (
          <ContactForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
            data={data}
            isDisabled={isDisabled}
          />
        );
      case 'Consent':
        return (
          <ConsentForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
          />
        );
      case 'Sample':
        return (
          <SampleForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
          />
        );
      case 'Test':
        return (
          <TestForm
            handleClose={handleClose}
            data={data}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
          />
        );
      case 'Address':
        return (
          <AddressForm
            handleClose={handleClose}
            familyId={familyId}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Clinical Note':
        return (
          <ClinicalNotesForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
            staffMembers={staffMembers}
            staffMemberOptions={staffMemberOptions}
            isDisabled={isDisabled}
          />
        );
      default:
        return <></>;
    }
  };

  const fetchStaffMembers = () => {
    axios.get(`/api/clinical-notes/staff-members`).then((res) => {
      const parsedStaffMembers = res.data.map((member) => ({
        firstName: member.firstName,
        lastName: member.lastName,
        userId: member._id
      }));
      setStaffMembers(parsedStaffMembers || []);
    });
  };

  React.useEffect(() => {
    fetchStaffMembers();
  }, []);

  React.useEffect(() => {
    if (!isModalOpen) setTiles([]);

    setIsLoading(true);

    axios
      .get(`/api/clinical-notes/all`, {
        params: { familyId }
      })
      .then((res) => {
        let edited = res.data;
        // Sort the notes based on the pinned property
        edited.sort(function (a) {
          if (a.pinned) return -1;
          return 1;
        });

        // Transform the data based on noteType
        const transformedData = edited.map((note) => {
          // Handle Consent & Sample type note
          if (note.noteType === 'Consent') {
            note.obtainedBy =
              Array.isArray(note.obtainedBy) && note.obtainedBy.length > 0
                ? note.obtainedBy[0].userId
                : null;
          }
          if (note.noteType === 'Test') {
            note.requestedBy =
              Array.isArray(note.requestedBy) && note.requestedBy.length > 0
                ? note.requestedBy[0].userId
                : null;
          }
          if (note.noteType === 'Appointment') {
            note.staffMember =
              Array.isArray(note.staffMember) && note.staffMember.length > 0
                ? note.staffMember.map((member) => member.userId)
                : null;
          }
          if (note.noteType === 'Contact') {
            note.staffMember =
              Array.isArray(note.staffMember) && note.staffMember.length > 0
                ? note.staffMember.map((member) => member.userId)
                : null;

            note.requestChecking =
              Array.isArray(note.requestChecking) &&
              note.requestChecking.length > 0
                ? note.requestChecking[0].userId
                : null;
          }
          return note;
        });
        setTiles(transformedData);

        setIsLoading(false);
        // If familyMemberIdFromURL & appointmentIdFromURL present then open apponitment modal
        if (
          familyMemberIdFromURL &&
          appointmentIdFromURL &&
          !isOpenedModal.current
        ) {
          let apponitment = transformedData.find(
            (data) =>
              data.familyMemberId === familyMemberIdFromURL &&
              data._id === appointmentIdFromURL
          );

          if (apponitment) {
            setIsEditing(true);
            setCurrentModel(apponitment);
            setNoteType('Appointment');
            setOpenModal(true);
            isOpenedModal.current = true;
          }
        }
      })
      .catch((err) => {
        console.error('Error fetching clinical notes:', err);
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [isModalOpen]);

  //Fix empty form selection values, shouldn't be necessary with radio
  React.useEffect(() => {
    if (noteType === '') setNoteType('Referral');
  }, [noteType]);

  const personSummary = React.useCallback(() => {
    let details = data.data;
    if (!newNote)
      details = deepCopy?.find(
        (d) => d.familyMemberId === currentModel.familyMemberId
      );
    return formatPersonSummary(details);
  }, [currentModel, newNote]);

  const renderTiles = () => {
    const MemberCards = [];
    const FamilyCards = [];
    tiles.map((item) => {
      if (filter[item.noteType] && familyMemberId == item.familyMemberId)
        MemberCards.push(
          <TileCard
            model={item}
            key={item._id}
            type={item.noteType}
            setIsEditing={setIsEditing}
            setModalOpen={setOpenModal}
            setNoteType={setNoteType}
            setCurrentModel={setCurrentModel}
            deepCopy={deepCopy}
            staffMemberOptions={staffMemberOptions}
          />
        );

      if (item.showInFamily)
        FamilyCards.push(
          <TileCard
            model={item}
            key={item._id}
            showInFamily={item.showInFamily}
            highlightedPerson={data?.data}
            type={item.noteType}
            setIsEditing={setIsEditing}
            setModalOpen={setOpenModal}
            setNoteType={setNoteType}
            setCurrentModel={setCurrentModel}
            deepCopy={deepCopy}
            staffMemberOptions={staffMemberOptions}
            setIsDisabled={setIsDisabled}
          />
        );
    });
    return { MemberCards, FamilyCards };
  };

  return (
    <div className="user-form">
      {familyMemberId !== 'none' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={noteType}
              onChange={(e) => setNoteType(e.target.value)}
            >
              <Grid container>
                {noteTypes.map((item, iter) => (
                  <Grid item key={iter} xs={6}>
                    <FormControlLabel
                      key={iter}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
          <Box display={'flex'} gap={2} justifyContent={'start'}>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<AddCircleIcon fontSize="small" />}
              onClick={addClicked}
            >
              {`Add ${noteType}`}
            </Button>

            <IconButton
              sx={{ marginLeft: '10px' }}
              onClick={() => modificationHistoryHandle('Modification History')}
            >
              <HistoryIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '1rem',
          gap: 1
        }}
      >
        <FormLabel component="label" className="form-label">
          Filter Clinical Records
        </FormLabel>

        {isLoading ? (
          <Chip label="Loading" />
        ) : renderTiles().MemberCards.length === 0 ? (
          <Chip label="No Notes Found!" />
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FilterClinicalRecord filter={filter} setFilter={setFilter} />
            </Box>
            {renderTiles().MemberCards.map((tile, iter) => (
              <Grid key={iter} item xs={12}>
                {tile}
              </Grid>
            ))}
          </>
        )}
      </Box>
      {!isLoading && (
        <Box sx={{ marginTop: 4 }}>
          <hr />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '1rem',
          gap: 1
        }}
      >
        <FormLabel component="label" className="form-label">
          Family Clinical Records
        </FormLabel>
        {renderTiles().FamilyCards.map((tile, iter) => (
          <Grid key={iter} item xs={12}>
            {tile}
          </Grid>
        ))}
        {renderTiles().FamilyCardslength == 0 && (
          <Chip label={`No Notes Found!`} />
        )}
      </Box>

      <CustomDialog
        maxWidth="sm"
        title={
          <ModalHeader
            title={isEditing ? `Edit ${noteType}` : `Create New ${noteType}`}
            personSummary={personSummary}
          />
        }
        allowBackgroundClose={true}
        open={isModalOpen}
        handleClose={handleClose}
        minHeight="auto"
        onClose={true}
      >
        {forms(noteType, currentModel)}
      </CustomDialog>
    </div>
  );
}

const FilterClinicalRecord = ({ filter, setFilter }) => {
  const selectedValues = Object.keys(filter).filter((key) => filter[key]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    const newFilter = { ...filter };

    Object.keys(newFilter).forEach((key) => {
      newFilter[key] = false;
    });

    value.forEach((key) => {
      if (key) newFilter[key] = true;
    });

    setFilter(newFilter);
  };

  const handleSelectAll = (event) => {
    event.stopPropagation();

    const newFilter = { ...filter };
    Object.keys(newFilter).forEach((key) => {
      newFilter[key] = true;
    });
    setFilter(newFilter);
  };

  const handleClearAll = (event) => {
    event.stopPropagation();

    const newFilter = { ...filter };
    Object.keys(newFilter).forEach((key) => {
      newFilter[key] = false;
    });
    setFilter(newFilter);
  };

  return (
    <div>
      <FormControl sx={{ width: 300 }}>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => selected.join(', ')}
        >
          <div
            style={{
              padding: '8px 16px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button onClick={handleSelectAll} size="small">
              Select All
            </Button>
            <Button onClick={handleClearAll} size="small">
              Clear All
            </Button>
          </div>

          {Object.keys(filter).map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={filter[name]} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ClinicalNotes;
