import React, { useState, useContext, useMemo, useRef } from 'react';
import clsx from 'clsx';
import * as d3 from 'd3';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LegendMenu from './LegendMenu';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Box, Grid } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CONSTANTS } from 'src/components/Constants';
import { AppContext, UserContext, ThemeContext } from 'src/store/ContextStore';
import {
  getUserSidebarItems,
  SIDEBAR_ITEMS,
  SIDEBAR_VALUE,
  STUDENT_SIDEBAR_ITEMS
} from './util';
import RiskScoresMenu from './RiskFactorsMenu';
import AddRelativeMenu from './AddRelativeMenu';
import PersonMenu from './PersonMenu';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExportMenu from './ExportMenu';
import LabelsMenu from './LabelsMenu';
import FamilyHistoryMenu from './FamilyHistoryMenu';
import ClinicalNotes from './ClinicalNotes';
import Tags from './Tags';
// import SetupPatientAccessMenu from './SetupPatientAccessMenu';
import PhenotypesMenu from './PhenotypesMenu';
import GenotypesMenu from './GenotypesMenu';

import DiagnosesMenu from './DiagnosesMenu';
import Alert from '@mui/material/Alert';
import Suggestions from './Suggestions';
import EpicEmrButton from 'src/components/integrations/epic/EpicEmrButton';
import ModificationHistoryModal from './ModificationHistoryModal';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import FormSubmission from './FormSubmission';
import InfoTooltip from 'src/components/UI/addons/InfoTooltip';
import GenerateLetters from './generate-letters/GenerateLetters';

const useStyles = makeStyles({
  list: {
    width: 380,
    transition: 'width 1s ease'
  },
  expand: {
    width: '95vw'
  },
  fullList: {
    width: 'auto'
  },
  btnContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10
  },
  expandBtn: {
    position: 'fixed',
    top: '50%',
    marginLeft: -30,
    zIndex: 10
  },
  listContainer: {
    overflowX: 'hidden'
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerTitle: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 500
  },
  closeBtn: {
    marginLeft: 'auto'
  },
  drawer: {
    top: '65px'
  },
  infoContainer: {
    marginBottom: '72px'
  }
});

const modalTitleMap = {
  [CONSTANTS.auditLogType.clinicalRecord]: 'Clinical History',
  [CONSTANTS.auditLogType.riskFactors]: 'Risk History',
  [CONSTANTS.auditLogType.phenotypes]: 'Phenotypes History',
  [CONSTANTS.auditLogType.genotypes]: 'Genotypes History',
  [CONSTANTS.auditLogType.diagnoses]: 'Diagnoses History'
};

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

export default function PedigreeSideBar({
  data,
  handleDetailsChange,
  deepCopy,
  setDataset,
  setDeepCopy,
  dataset,
  setPedigreeState,
  sidebar,
  setSidebar,
  setData,
  labelsSet,
  setLabelsSet,
  setError,
  saveState
}) {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles(theme);
  const { content } = useContext(AppContext);
  const {
    pedigreeSelectedUsers: users,
    settings: { epicEmrApi }
  } = content;
  const { selectedUser } = users;
  const { user: loggedInUser } = useContext(UserContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [modificationType, setModificationType] = useState(false);
  const accordionRefs = useRef({});

  const handleOpenModel = () => setOpenModal(true);
  const handleCloseModel = () => setOpenModal(false);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.proBandId ?? data?.data?.familyMemberId;
  }, [data]);

  let isStudent = useMemo(() => {
    return loggedInUser.userType === CONSTANTS.userTypes.STUDENT;
  }, [loggedInUser]);

  const modificationHistoryHandle = (type) => {
    setModificationType(type);
    handleOpenModel();
  };

  const unHighlightNodeByPid = (pid) => {
    const parentGroup = d3.selectAll('g').filter(function (d) {
      return d?.data?.pid === pid;
    });

    if (!parentGroup.empty()) {
      const targetPath = parentGroup.select('path.te');

      if (!targetPath.empty()) {
        targetPath
          .style('stroke-width', '0.11em')
          .style('stroke', theme.appearance.text);
      } else {
        console.error('No <path class="te"> found for pid:', pid);
      }
    } else {
      console.error('No <g> element found for pid:', pid);
    }
  };

  const handleClose = () => {
    setSidebar({
      open: false,
      expand: false,
      expandAccordian: []
    });

    if (data) {
      unHighlightNodeByPid(data?.data?.pid);
    }
    setData(null);
  };

  const toggleSideBar = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!open) {
      handleClose();
    } else {
      const defaultExpandedAccordion = [SIDEBAR_VALUE.legend];

      // Fetch state of expanded accordion from local storage
      const savedAccordionState = localStorage.getItem('pedigreeSidebarState');
      const expandedAccordion = savedAccordionState
        ? JSON.parse(savedAccordionState)
        : null;

      // Determine the final accordion state
      const expandAccordian =
        expandedAccordion === null
          ? defaultExpandedAccordion
          : expandedAccordion;

      setSidebar((pre) => ({ ...pre, open: open, expandAccordian }));
    }
  };

  const expandToggleSideBar = () => {
    setSidebar((pre) => ({
      ...pre,
      expand: !pre.expand
    }));
  };

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    if (newExpanded) localStorage.setItem('sidebar-panel', panel);
    else localStorage.setItem('sidebar-panel', null);
    setSidebar((pre) => {
      const expandAccordian = newExpanded
        ? [...pre.expandAccordian, panel]
        : [...pre.expandAccordian].filter((item) => item !== panel);

      // Save state of accordion to local storage of browser
      localStorage.setItem(
        'pedigreeSidebarState',
        JSON.stringify(expandAccordian)
      );

      return { ...pre, expandAccordian };
    });

    requestAnimationFrame(() => {
      setTimeout(() => {
        if (newExpanded && accordionRefs.current[panel]) {
          accordionRefs.current[panel].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 200);
    });
  };

  const getAccordianDetails = (value) => {
    switch (value) {
      case SIDEBAR_VALUE.legend:
        return (
          <LegendMenu
            diagnoses={users.diagnoses}
            id="diagnoses-legend-sidebar"
            labelsSet={labelsSet}
            setLabelsSet={setLabelsSet}
          />
        );
      case SIDEBAR_VALUE.person:
        return (
          <PersonMenu
            data={data}
            handleDetailsChange={handleDetailsChange}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            setError={setError}
            handleClose={handleClose}
          />
        );
      case SIDEBAR_VALUE.tags:
        return <Tags user={selectedUser} />;
      case SIDEBAR_VALUE.familyHistory:
        return <FamilyHistoryMenu user={selectedUser} />;
      case SIDEBAR_VALUE.addRelative:
        return (
          <AddRelativeMenu
            setPedigreeState={setPedigreeState}
            user={selectedUser}
            data={data}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            saveState={saveState}
            // TODO: require a cleanup
            handleClose={() => {}}
            dataset={dataset}
            setError={setError}
            keepOpen={true}
          />
        );
      case SIDEBAR_VALUE.labels:
        return <LabelsMenu labelsSet={labelsSet} setLabelsSet={setLabelsSet} />;
      case SIDEBAR_VALUE.riskFactors:
        return (
          <RiskScoresMenu
            data={data}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.export:
        return <ExportMenu user={selectedUser} />;
      // case SIDEBAR_VALUE.setupPatientAccess:
      //   return <SetupPatientAccessMenu handleClose={handleClose} />;
      case SIDEBAR_VALUE.phenotypes:
        return (
          <PhenotypesMenu
            handleClose={handleClose}
            data={data}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setDataset={setDataset}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.genotypes:
        return (
          <GenotypesMenu
            handleClose={handleClose}
            data={data}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.diagnoses:
        return (
          <DiagnosesMenu
            handleClose={handleClose}
            data={data}
            setDataset={setDataset}
            modificationHistoryHandle={modificationHistoryHandle}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setPedigreeState={setPedigreeState}
          />
        );
      case SIDEBAR_VALUE.suggestions:
        return <Suggestions data={data} setDataset={setDataset} />;

      case SIDEBAR_VALUE.emr:
        return <EpicEmrButton data={data?.data} emrSettings={epicEmrApi} />;
      case SIDEBAR_VALUE.clinicalNotes:
        return (
          <ClinicalNotes
            data={data}
            deepCopy={deepCopy}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.formSubmission:
        return <FormSubmission data={data} />;
      // TODO:: For future
      // case SIDEBAR_VALUE.generateLetters:
      //   return <GenerateLetters data={data} />;

      default:
        return <div>New Feature Coming Soon...</div>;
    }
  };

  // const getSideBarItems = () => {
  //   const selectedUser = data?.data;
  //   if (selectedUser) {
  //     let showPatientAccessLabel =
  //       selectedUser.proBandId && !selectedUser.email;

  //     if (isStudent) return getStudentUserSidebarItems(showPatientAccessLabel);
  //     else
  //       return getUserSidebarItems({
  //         showPatientAccessLabel,
  //         showEmrTab: epicEmrApi?.enabled
  //       });
  //   } else {
  //     if (isStudent) return STUDENT_SIDEBAR_ITEMS;
  //     else return SIDEBAR_ITEMS;
  //   }
  // };

  const getSideBarItems = () => {
    const selectedUser = data?.data;
    if (selectedUser) {
      let showPatientAccessLabel =
        selectedUser.proBandId && !selectedUser.email;

      if (isStudent) return STUDENT_SIDEBAR_ITEMS;
      else
        return getUserSidebarItems({
          showEmrTab: epicEmrApi?.enabled
        });
    } else {
      if (isStudent) return STUDENT_SIDEBAR_ITEMS;
      else return SIDEBAR_ITEMS;
    }
  };

  const getExpandedValue = (val) => {
    return sidebar.expandAccordian?.includes(val) ? true : false;
  };

  let headerTitle = useMemo(() => {
    let proBandId = data?.data?.proBandId;
    let familyMemberId = data?.data?.familyMemberId;
    if (proBandId || familyMemberId)
      return `PEDIGREE: ${proBandId || familyMemberId}`;
    return `PEDIGREE: ${selectedUser?.familyId}`;
  }, [selectedUser, data]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        [classes.expand]: sidebar.expand
      })}
      role="presentation"
    >
      {/* Header Section */}
      <div className={classes.headerSection}>
        {/* Expandable Button */}
        <IconButton onClick={expandToggleSideBar} size="large">
          {sidebar.expand ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
        </IconButton>

        {/* Header Title */}
        <Typography
          variant="h5"
          component="h2"
          style={{ color: theme.header, fontWeight: 500 }}
        >
          {headerTitle}
        </Typography>

        {/* Close Button */}
        <IconButton
          onClick={toggleSideBar(false)}
          size="large"
          className={classes.closeBtn}
        >
          <HighlightOffIcon />
        </IconButton>
      </div>

      {/* Sidebar Items */}
      <List className={classes.listContainer}>
        {getSideBarItems().map((item) => (
          <Accordion
            square
            expanded={getExpandedValue(item.value)}
            onChange={handleChangeAccordian(item.value)}
            key={item.value}
          >
            <AccordionSummary
              aria-controls={item.value}
              id={item.value}
              expandIcon={<ArrowDropDownIcon />}
              ref={(el) => (accordionRefs.current[item.value] = el)}
            >
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <IconButton color="primary">{item.icon}</IconButton>
                <Typography pt={0.1}>{item.label}</Typography>
                {item?.info && (
                  <InfoTooltip label={item.info} fontSize={'5px'} help />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {getAccordianDetails(item.value)}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>

      <Grid item xs={12} className={classes.infoContainer}>
        <Alert severity="info">
          TrakGene uses the <b>Human Phenotype Ontology</b> (Version - 0.5.8).
          Find out more at http://www.human-phenotype-ontology.org
        </Alert>
      </Grid>
    </div>
  );

  return (
    <div>
      <div className={classes.btnContainer}>
        <IconButton onClick={toggleSideBar(true)} size="large">
          <MenuIcon />
        </IconButton>
      </div>

      {openModal && (
        <CustomDialog
          title={
            modalTitleMap[modificationType]
              ? modalTitleMap[modificationType]
              : modificationType
          }
          open={openModal}
          handleClose={handleCloseModel}
          minHeight="auto"
          maxWidth="lg"
          fullWidth
        >
          <ModificationHistoryModal
            open={openModal}
            modificationType={modificationType}
            handleClose={handleCloseModel}
            familyMemberId={familyMemberId}
          />
        </CustomDialog>
      )}

      {/* SideBar */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={sidebar.open}
        onClose={toggleSideBar(false)}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawer
        }}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
