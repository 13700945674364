import { CONSTANTS } from '../Constants';

/* eslint-disable default-case */
export const EVENTS = {
  SET_ALL: 'SET_ALL',
  SET_TAB_ALL: 'SET_TAB_ALL',
  SET_PEDIGREE_USERS: 'SET_PEDIGREE_USERS',
  SET_PEDIGREE_SELECTED_USER: 'SET_PEDIGREE_SELECTED_USER',
  SET_PEDIGREE_DIAGNOSES: 'SET_PEDIGREE_DIAGNOSES',
  SET_PEDIGREE_PHENOTYPES: 'SET_PEDIGREE_PHENOTYPES',
  UPDATE_PEDIGREE_SELECTED_USER: 'UPDATE_PEDIGREE_SELECTED_USER',
  UPDATE_EACH_PEDIGREE_DIAGNOSES: 'UPDATE_EACH_PEDIGREE_DIAGNOSES',
  ADD_GENERATED_LINKS: 'ADD_GENERATED_LINKS',
  REVERT_TO_DRAFT_LINK: 'REVERT_TO_DRAFT_LINK',
  DEACTIVATE_LINK: 'DEACTIVATE_LINK',
  CONFIG_UPDATE_INSTRUCTION: 'CONFIG_UPDATE_INSTRUCTION',
  CONFIG_TOGGLE_CUSTOMIZATION: 'CONFIG_TOGGLE_CUSTOMIZATION',
  CONFIG_TOGGLE_CUSTOM_DIAGNOSES: 'CONFIG_TOGGLE_CUSTOM_DIAGNOSES',
  CONFIG_TOGGLE_PREGNANCY: 'CONFIG_TOGGLE_PREGNANCY',
  CONFIG_TOGGLE_RISK_SURVEY: 'CONFIG_TOGGLE_RISK_SURVEY',
  CONFIG_UPDATE_PAGE_TITLE: 'CONFIG_UPDATE_PAGE_TITLE',
  CONFIG_UPDATE_CUSTOM_CONSENT: 'CONFIG_UPDATE_CUSTOM_CONSENT',
  CONFIG_UPDATE_QUESTIONS: 'CONFIG_UPDATE_QUESTIONS',
  UPDATE_CUSTOM_DIAGNOSES: 'UPDATE_CUSTOM_DIAGNOSES',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_EPIC_EMR_SETTINGS: 'SET_EPIC_EMR_SETTINGS',
  SET_SYSTEM_FEATURE_SETTINGS: 'SET_SYSTEM_FEATURE_SETTINGS',
  UPDATE_CLIENTS: 'UPDATE_CLIENTS',
  CONFIG_UPDATE_EMAIL_TEMPLATES: 'CONFIG_UPDATE_EMAIL_TEMPLATES',
  MERGE_AND_SET_TAB: 'MERGE_AND_SET_TAB',
  SET_FORM_BUILDER_TAB: 'SET_FORM_BUILDER_TAB',
  ADD_FORM_BUILDER_GENERATED_LINKS: 'ADD_FORM_BUILDER_GENERATED_LINKS',
  REVERT_DRAFT_FORM_BUILDER_LINK: 'REVERT_DRAFT_FORM_BUILDER_LINK',
  DELETE_FORM_BUILDER_LINK: 'DELETE_FORM_BUILDER_LINK',
  DEACTIVATE_FORM_BUILDER_LINK: 'DEACTIVATE_FORM_BUILDER_LINK',
  SET_FORM_BUILDER_SCHEMA_STATUS: 'SET_FORM_BUILDER_SCHEMA_STATUS',
  SET_FORM_BUILDER_SCHEMA_ONLIVE_STATUS:
    'SET_FORM_BUILDER_SCHEMA_ONLIVE_STATUS',
  SET_SELECTED_GROUP: 'SET_SELECTED_GROUP',
  SET_MEDICARE_INVOICES: 'SET_MEDICARE_INVOICES',
  ADD_MEDICARE_INVOICES: 'ADD_MEDICARE_INVOICES',
  SET_CARRIER_SCREENING_REPORTS: 'SET_CARRIER_SCREENING_REPORTS',
  SET_SYSTEM_FEATURES: 'SET_SYSTEM_FEATURES'
};

export const getUniqueDiagnoses = (familyMembers) => {
  const diagnoses = [];
  if (familyMembers && familyMembers.length > 0) {
    const _diagnoses = familyMembers.flatMap((el) => el?.diagnoses || []);
    const updatedDiagnoses = _diagnoses.filter(
      (elem) => elem.displayOnPedigree === true
    );
    const uniqueDiagnoses = new Map();

    // Iterate through diagnoses and store unique ones
    updatedDiagnoses.forEach((diagnosis) => {
      if (diagnosis && diagnosis.id && !uniqueDiagnoses.has(diagnosis.id)) {
        uniqueDiagnoses.set(diagnosis.id, diagnosis);
      }
    });
    return Array.from(uniqueDiagnoses.values());
  }
  return diagnoses;
};

export const getUniquePhenotypes = (familyMembers) => {
  const phenotypes = [];
  if (familyMembers && familyMembers.length > 0) {
    const _phenotypes = familyMembers.flatMap((el) => el?.phenotypes || []);
    const uniquePhenotypes = new Map();

    _phenotypes.forEach((phenotype) => {
      if (phenotype && phenotype.id && !uniquePhenotypes.has(phenotype.id)) {
        uniquePhenotypes.set(phenotype.id, phenotype);
      }
    });
    return Array.from(uniquePhenotypes.values());
  }
  return phenotypes;
};
export const appReducer = (state, action) => {
  let result = {};
  switch (action.type) {
    case EVENTS.SET_ALL:
      result = action.value;
      break;

    case EVENTS.SET_TAB_ALL:
      result = { [action.tab]: action.value };
      break;

    case EVENTS.SET_PEDIGREE_USERS: {
      result = { pedigreeUsers: action.value };
      // check if selected user is set and if removed from the incoming array
      if (
        state.pedigreeSelectedUsers?.selectedUser &&
        Object.keys(state.pedigreeSelectedUsers.selectedUser).length
      ) {
        const index = action.value?.indexOf(
          (el) => el._id === state.pedigreeSelectedUsers.selectedUser._id
        );
        if (index < 0) {
          result.pedigreeSelectedUsers = {
            diagnoses: [],
            phenotypes: [],
            family: [],
            selectedUser: {}
          };
        }
      }
      break;
    }

    case EVENTS.SET_FORM_BUILDER_TAB: {
      result = {
        formBuilder: { ...state.formBuilder, [action.tab]: action.value }
      };
      break;
    }

    //updating form live status to true when link is generated
    case EVENTS.SET_FORM_BUILDER_SCHEMA_ONLIVE_STATUS: {
      const index = state.formBuilder.schemas.findIndex(
        (el) => el._id === action.value.id
      );
      const schemas = state.formBuilder.schemas;
      schemas[index].on_live = true;

      result = { ...state.formBuilder, schemas: schemas };

      break;
    }

    case EVENTS.SET_FORM_BUILDER_SCHEMA_STATUS: {
      const index = state.formBuilder.schemas.findIndex(
        (el) => el._id === action.value.id
      );
      const schemas = state.formBuilder.schemas;
      schemas[index].status = action.value.status;
      result = { ...state.formBuilder, schemas: schemas };

      break;
    }

    case EVENTS.ADD_FORM_BUILDER_GENERATED_LINKS: {
      const linksGenerated = action.value.concat(
        state.formBuilder.linksGenerated
      );
      result = { formBuilder: { ...state.formBuilder, linksGenerated } };
      break;
    }

    case EVENTS.REVERT_DRAFT_FORM_BUILDER_LINK: {
      const links = [...state.formBuilder.linksGenerated];
      // remove the old link and add new one
      const i = links.findIndex((el) => el._id === action.value._id);
      if (i > -1) {
        links[i] = { ...links[i], ...action.value };
        result = {
          formBuilder: { ...state.formBuilder, linksGenerated: links }
        };
      }
      break;
    }

    case EVENTS.DELETE_FORM_BUILDER_LINK: {
      const links = [...state.formBuilder.linksGenerated];
      const i = links.findIndex((el) => el._id === action.value);
      if (i > -1) {
        links.splice(i, 1);
        result = {
          formBuilder: { ...state.formBuilder, linksGenerated: links }
        };
      }
      break;
    }

    case EVENTS.DEACTIVATE_FORM_BUILDER_LINK: {
      const links = [...state.formBuilder.linksGenerated];
      const i = links.findIndex((el) => el._id === action.value);
      if (i > -1) {
        links[i] = { ...links[i], status: CONSTANTS.user_status.DEACTIVATED };
        result = {
          formBuilder: { ...state.formBuilder, linksGenerated: links }
        };
      }
      break;
    }

    // update a tabbed array values by merging with existing
    case EVENTS.MERGE_AND_SET_TAB: {
      const prev = state[action.tab];
      const values = action.value.map((item) => {
        const prevItem = prev.find((el) => el._id === item._id);
        return Object.assign({}, prevItem, item);
      });
      result = { [action.tab]: values };
      break;
    }

    case EVENTS.SET_PEDIGREE_SELECTED_USER: {
      const diagnoses = getUniqueDiagnoses([
        ...action.value.family,
        action.value.selectedUser
      ]);
      const phenotypes = getUniquePhenotypes([
        ...action.value.family,
        action.value.selectedUser
      ]);
      result = {
        pedigreeSelectedUsers: {
          ...action.value,
          diagnoses: action.value?.diagnoses ?? diagnoses,
          phenotypes: phenotypes
        }
      };
      break;
    }
    case EVENTS.SET_PEDIGREE_DIAGNOSES: {
      const uniqueDiagnoses = getUniqueDiagnoses([
        {
          diagnoses: [...state.pedigreeSelectedUsers.diagnoses, ...action.value]
        }
      ]);
      result = {
        pedigreeSelectedUsers: {
          ...state.pedigreeSelectedUsers,
          diagnoses: uniqueDiagnoses
        }
      };
      break;
    }
    case EVENTS.SET_PEDIGREE_PHENOTYPES: {
      const uniquePhenotypes = getUniquePhenotypes([
        {
          phenotypes: [
            ...state.pedigreeSelectedUsers.phenotypes,
            ...action.value
          ]
        }
      ]);

      result = {
        pedigreeSelectedUsers: {
          ...state.pedigreeSelectedUsers,
          phenotypes: uniquePhenotypes
        }
      };
      break;
    }

    case EVENTS.UPDATE_EACH_PEDIGREE_DIAGNOSES: {
      const { probandDiagnoses = {}, familyDiagnoses = [] } = action.value;

      const family = state.pedigreeSelectedUsers.family.map((elem) => {
        const matchedMember = familyDiagnoses.find(
          (fam) => fam.pid === elem.pid
        );
        return {
          ...elem,
          diagnoses: matchedMember?.diagnoses || []
        };
      });

      const allDiagnoses = [
        ...probandDiagnoses.diagnoses,
        ...familyDiagnoses.flatMap((member) => member.diagnoses)
      ];

      const uniqueDiagnoses = getUniqueDiagnoses([{ diagnoses: allDiagnoses }]);

      result = {
        pedigreeSelectedUsers: {
          ...state.pedigreeSelectedUsers,
          selectedUser: {
            ...state.pedigreeSelectedUsers.selectedUser,
            diagnoses: probandDiagnoses.diagnoses
          },
          family,
          diagnoses: uniqueDiagnoses
        }
      };
      break;
    }

    case EVENTS.UPDATE_PEDIGREE_SELECTED_USER: {
      const userValues = action.value;
      // first check if the update is required in selected user or the family memeber
      // if email exists then selected user
      if (userValues.email) {
        result = {
          pedigreeSelectedUsers: {
            ...state.pedigreeSelectedUsers,
            selectedUser: action.value
          }
        };
      } else {
        const family = [...state.pedigreeSelectedUsers.family];
        // find the family member
        const i = family.findIndex((el) => el._id === userValues._id);
        family[i] = { ...family[i], ...userValues };
        result = {
          pedigreeSelectedUsers: { ...state.pedigreeSelectedUsers, family }
        };
      }
      break;
    }

    case EVENTS.ADD_GENERATED_LINKS: {
      const links = action.value.concat(state.linksGenerated);
      result = { linksGenerated: links };
      break;
    }

    case EVENTS.REVERT_TO_DRAFT_LINK: {
      const links = [...state.linksGenerated];
      // remove the old link and add new one
      const i = links.findIndex((el) => el._id === action.value._id);
      if (i > -1) {
        links[i] = { ...links[i], ...action.value };
        result = { linksGenerated: links };
      }
      break;
    }

    case EVENTS.DEACTIVATE_LINK: {
      const links = [...state.linksGenerated];
      if (action?.value > -1) {
        links[action?.value] = { ...links[action?.value], linkStatus: false };
        result = { linksGenerated: links };
      }
      break;
    }

    case EVENTS.CONFIG_UPDATE_INSTRUCTION: {
      let instructions = action.value;
      result = {
        settings: {
          ...state.settings,
          customisation: { ...state.settings.customisation, instructions }
        }
      };
      break;
    }

    case EVENTS.CONFIG_TOGGLE_CUSTOMIZATION: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            isEnabled: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_TOGGLE_CUSTOM_DIAGNOSES: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            customDiagnoses: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_TOGGLE_PREGNANCY: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            isEnablePregnancy: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_TOGGLE_RISK_SURVEY: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            isEnableRiskSurvey: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_UPDATE_CUSTOM_CONSENT: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            customConsent: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_UPDATE_PAGE_TITLE: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            questionsTitle: action.value
          }
        }
      };
      break;
    }

    case EVENTS.CONFIG_UPDATE_QUESTIONS: {
      const questions = [...state.settings.customisation.questions];
      switch (action.method) {
        case 'add': {
          questions.push(action.value);
          break;
        }
        case 'delete': {
          if (action.value > -1) questions.splice(action.value, 1);
          break;
        }
        case 'save': {
          if (action.value?.id > -1) {
            questions[action.value.id] = {
              ...questions[action.value.id],
              value: action.value?.value,
              enabled: action.value?.enabled,
              required: action.value?.required
            };
          }
          break;
        }
      }
      result = {
        settings: {
          ...state.settings,
          customisation: { ...state.settings.customisation, questions }
        }
      };
      break;
    }

    case EVENTS.UPDATE_CUSTOM_DIAGNOSES: {
      result = {
        settings: {
          ...state.settings,
          customisation: {
            ...state.settings.customisation,
            clinicianDiagnoses: action.value
          }
        }
      };

      break;
    }

    case EVENTS.SET_SETTINGS: {
      result = { settings: { ...state.settings, [action.tab]: action.value } };
      break;
    }

    // save details corresponding to each feature
    case EVENTS.SET_SYSTEM_FEATURE_SETTINGS: {
      result = { settings: { ...state.settings, ...action.value } };
      break;
    }
    case EVENTS.SET_SYSTEM_FEATURES: {
      switch (action.method) {
        case 'set': {
          result = {
            settings: {
              ...state.settings,
              systemFeatures: {
                ...state.settings.systemFeatures,
                [action.tab]: action.value
              }
            }
          };
          break;
        }
        case 'load': {
          result = {
            settings: {
              ...state.settings,
              systemFeatures: action.value
            }
          };
          break;
        }
      }
      break;
    }

    case EVENTS.UPDATE_CLIENTS: {
      const clients = [...state.clients];
      switch (action.method) {
        case 'add': {
          clients.unshift(action.value);
          break;
        }
        case 'delete': {
          if (action.value > -1) clients.splice(action.value, 1);
          break;
        }
        case 'edit': {
          const id = action.value?.tableData?.id;
          if (id > -1) {
            clients[id] = { ...clients[id], ...action.value };
          }
          break;
        }
      }
      result = { clients };
      break;
    }

    case EVENTS.CONFIG_UPDATE_EMAIL_TEMPLATES: {
      const emailTemplates = [...state.settings.emailTemplates];
      switch (action.method) {
        case 'add': {
          emailTemplates.push(action.value);
          break;
        }
        case 'delete': {
          if (action.value > -1) emailTemplates.splice(action.value, 1);
          break;
        }
        case 'save': {
          if (action.value?.id > -1) {
            emailTemplates[action.value.id] = action.value?.value;
          }
          break;
        }
      }
      result = { settings: { ...state.settings, emailTemplates } };
      break;
    }

    case EVENTS.SET_SELECTED_GROUP: {
      result = { selectedGroup: action.value };
      break;
    }

    case EVENTS.ADD_MEDICARE_INVOICES: {
      result = { medicareInvoices: action.value };
      break;
    }
    case EVENTS.SET_MEDICARE_INVOICES: {
      const invoices = [...state.medicareInvoices];
      switch (action.method) {
        case 'add': {
          result = {
            medicareInvoices: [action.value, ...state.medicareInvoices]
          };
          break;
        }
        case 'delete': {
          const i = invoices.findIndex((el) => el._id === action.value);
          if (i > -1) {
            invoices.splice(i, 1);
            result = {
              medicareInvoices: invoices
            };
          }
          break;
        }
        case 'update': {
          const i = invoices.findIndex((el) => el._id === action.value._id);
          if (i > -1) {
            invoices[i] = action.value;
            result = {
              medicareInvoices: invoices
            };
          }
          break;
        }
        case 'archive': {
          const i = invoices.findIndex((el) => el._id === action.value);
          if (i > -1) {
            invoices[i].archived = true;
            result = {
              medicareInvoices: invoices
            };
          }
          break;
        }
      }
      break;
    }

    case EVENTS.SET_CARRIER_SCREENING_REPORTS: {
      const reports = [...state.carrierScreenReports];
      switch (action.method) {
        case 'load': {
          result = {
            carrierScreenReports: [...action.value]
          };
          break;
        }
        case 'add': {
          result = {
            carrierScreenReports: [...reports, ...action.value]
          };
          break;
        }
        case 'delete': {
          const i = reports.findIndex((el) => el._id === action.value);
          if (i > -1) {
            reports.splice(i, 1);
            result = {
              carrierScreenReports: reports
            };
          }
          break;
        }
        case 'update': {
          const i = reports.findIndex((el) => el._id === action.value._id);
          if (i > -1) {
            reports[i] = action.value;
            result = {
              carrierScreenReports: reports
            };
          }
          break;
        }
      }

      break;
    }

    default:
      result = state;
  }
  return { ...state, ...result };
};
