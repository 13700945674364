import html2pdf from 'html2pdf.js';
import htmlDocx from 'html-docx-js/dist/html-docx';

// Export to PDF
export const exportToPDF = (content) => {
  const element = document.createElement('div');
  element.innerHTML = content;

  const opt = {
    margin: 10, // Space around the content
    filename: 'document.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
  };

  html2pdf()
    .set(opt)
    .from(element)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      pdf.setLineWidth(1); // Border thickness
      pdf.rect(5, 5, pageWidth - 10, pageHeight - 10); // Draw border inside margins

      pdf.save('document.pdf');
    });
};

// Export to DOCX
export const exportToDocs = (content) => {
  const blob = new Blob([htmlDocx.asBlob(content)], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'document.docx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
