import {
  createDropdown,
  addListToDropdown,
  Plugin,
  ViewModel,
  Collection
} from 'ckeditor5';
import { mergeFieldsOptions } from 'src/components/settings/templates/constants';
const mergeFieldPreviewIcon = `
<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9.00037" cy="9.79993" r="1.5"></circle>
  <path d="M5.75024 2.75005C5.75019 2.00005 5.75006 2.00005 5.75006 2.00005L5.74877 2.00005L5.74647 2.00006L5.73927 2.00009L5.71503 2.0003C5.6947 2.00053 5.66619 2.00098 5.63111 2.00185C5.56123 2.0036 5.46388 2.00707 5.35241 2.01402C5.14095 2.02722 4.83482 2.05536 4.56712 2.12276C4.1703 2.22267 3.82938 2.40399 3.55967 2.67392C3.29221 2.94161 3.1311 3.26001 3.03544 3.5803C2.85401 4.18776 2.8854 4.89393 2.92747 5.49256C2.9373 5.6324 2.94792 5.76849 2.95828 5.90131C2.99629 6.38849 3.03087 6.83163 3.01038 7.25369C2.98475 7.78147 2.87469 8.13279 2.6777 8.3656C2.45517 8.6286 2.1841 8.79405 1.95875 8.89436C1.84756 8.94386 1.75282 8.97509 1.68956 8.99319C1.65813 9.00219 1.63513 9.00776 1.62253 9.01062L1.61304 9.01269L1.00024 9.12173V9.75005H3.4023C3.54579 9.63123 3.68814 9.49364 3.82278 9.33451C4.33087 8.73405 4.47638 7.99036 4.50861 7.32643C4.5342 6.79933 4.48942 6.23163 4.4502 5.73429C4.44071 5.61404 4.43155 5.49785 4.42378 5.3874C4.38011 4.76596 4.37986 4.32043 4.4727 4.00956C4.51418 3.87069 4.56668 3.78828 4.62078 3.73414C4.67264 3.68223 4.76124 3.6207 4.93336 3.57736C5.06269 3.5448 5.25656 3.52293 5.44585 3.51111C5.53475 3.50556 5.61296 3.50277 5.66854 3.50139C5.6962 3.5007 5.71789 3.50036 5.73209 3.5002L5.74748 3.50007L5.75054 3.50005L6.5003 3.5L6.50019 2L5.75006 2.00005L5.75024 2.75005Z"></path>
  <path d="M5.75024 16.7501C5.75019 17.5001 5.75006 17.5001 5.75006 17.5001L5.74877 17.5001L5.74647 17.5001L5.73927 17.5L5.71503 17.4998C5.6947 17.4996 5.66619 17.4991 5.63111 17.4983C5.56123 17.4965 5.46388 17.493 5.35241 17.4861C5.14095 17.4729 4.83482 17.4448 4.56712 17.3774C4.1703 17.2774 3.82938 17.0961 3.55967 16.8262C3.29221 16.5585 3.1311 16.2401 3.03544 15.9198C2.85401 15.3124 2.8854 14.6062 2.92747 14.0076C2.9373 13.8677 2.94792 13.7316 2.95828 13.5988C2.99629 13.1116 3.03087 12.6685 3.01038 12.2464C2.98475 11.7186 2.87469 11.3673 2.6777 11.1345C2.45517 10.8715 2.1841 10.7061 1.95875 10.6058C1.84756 10.5563 1.75282 10.525 1.68956 10.5069C1.65813 10.4979 1.63513 10.4924 1.62253 10.4895L1.61304 10.4874L1.00024 10.3784V9.75005H3.4023C3.54579 9.86887 3.68814 10.0065 3.82278 10.1656C4.33087 10.7661 4.47638 11.5098 4.50861 12.1737C4.5342 12.7008 4.48942 13.2685 4.4502 13.7658C4.44071 13.8861 4.43155 14.0023 4.42378 14.1127C4.38011 14.7341 4.37986 15.1797 4.4727 15.4906C4.51418 15.6294 4.56668 15.7118 4.62078 15.766C4.67264 15.8179 4.76124 15.8794 4.93336 15.9228C5.06269 15.9553 5.25656 15.9772 5.44585 15.989C5.53475 15.9945 5.61296 15.9973 5.66854 15.9987C5.6962 15.9994 5.71789 15.9998 5.73209 15.9999L5.74748 16L5.75054 16.0001L6.5003 16.0001L6.50019 17.5001L5.75006 17.5001L5.75024 16.7501Z"></path>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2514 2.00005L12.2501 2.00005L11.5 2L11.4999 3.5L12.2496 3.50005L12.2527 3.50007L12.2681 3.5002C12.2823 3.50036 12.304 3.5007 12.3316 3.50139C12.3872 3.50277 12.4654 3.50556 12.5543 3.51111C12.7436 3.52293 12.9375 3.5448 13.0668 3.57736C13.2389 3.6207 13.3275 3.68223 13.3794 3.73414C13.4335 3.78828 13.486 3.87069 13.5275 4.00956C13.6203 4.32043 13.6201 4.76596 13.5764 5.3874C13.5686 5.49785 13.5595 5.61404 13.55 5.73429C13.5108 6.23163 13.466 6.79933 13.4916 7.32643C13.5238 7.99036 13.6693 8.73405 14.1774 9.33451C14.312 9.49364 14.4544 9.63123 14.5979 9.75005C14.4544 9.86887 14.312 10.0065 14.1774 10.1656C13.7121 10.7154 13.5509 11.3854 13.5023 12.0042C13.6011 12.0012 13.7003 11.9997 13.7999 11.9997C14.208 11.9997 14.6093 12.0247 15.0018 12.0729C15.0416 11.6402 15.1479 11.3408 15.3225 11.1345C15.545 10.8715 15.8161 10.7061 16.0414 10.6058C16.1526 10.5563 16.2474 10.525 16.3106 10.5069C16.342 10.4979 16.365 10.4924 16.3776 10.4895L16.3871 10.4874L16.9999 10.3784V9.75005V9.12173L16.3871 9.01269L16.3776 9.01062C16.365 9.00776 16.342 9.00219 16.3106 8.99319C16.2474 8.97509 16.1526 8.94386 16.0414 8.89436C15.8161 8.79405 15.545 8.6286 15.3225 8.3656C15.1255 8.13279 15.0154 7.78147 14.9898 7.25369C14.9693 6.83163 15.0039 6.38849 15.0419 5.90131C15.0523 5.76849 15.0629 5.6324 15.0727 5.49256C15.1148 4.89393 15.1462 4.18776 14.9647 3.5803C14.8691 3.26001 14.708 2.94161 14.4405 2.67392C14.1708 2.40399 13.8299 2.22267 13.433 2.12276C13.1654 2.05536 12.8592 2.02722 12.6478 2.01402C12.5363 2.00707 12.4389 2.0036 12.3691 2.00185C12.334 2.00098 12.3055 2.00053 12.2851 2.0003L12.2609 2.00009L12.2537 2.00006L12.2514 2.00005Z"></path>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00335 17.2062L9.00308 17.2065C8.7234 17.5118 8.24919 17.5327 7.94372 17.2532C7.63816 16.9735 7.61716 16.4991 7.89681 16.1935L8.45008 16.6999C7.89681 16.1935 7.89697 16.1934 7.89713 16.1932L7.89751 16.1928L7.89844 16.1918L7.90098 16.189L7.90879 16.1806L7.93517 16.1526C7.95746 16.1292 7.98914 16.0963 8.02971 16.0555C8.11079 15.9738 8.22768 15.8597 8.37644 15.724C8.6732 15.4532 9.10079 15.0927 9.62744 14.7314C10.6647 14.0198 12.1659 13.2499 13.8501 13.2499C15.5343 13.2499 17.0355 14.0198 18.0727 14.7314C18.5994 15.0927 19.027 15.4532 19.3237 15.724C19.4725 15.8597 19.5894 15.9738 19.6705 16.0555C19.711 16.0963 19.7427 16.1292 19.765 16.1526L19.7914 16.1806L19.7992 16.189L19.8017 16.1918L19.8027 16.1928L19.803 16.1932C19.8032 16.1934 19.8034 16.1935 19.2501 16.6999L19.8034 16.1935C20.083 16.4991 20.062 16.9735 19.7565 17.2532C19.4511 17.5326 18.9772 17.5118 18.6975 17.207L18.6971 17.2065L18.6968 17.2062L18.6945 17.2037L18.6783 17.1865C18.6629 17.1704 18.6386 17.1452 18.6059 17.1123C18.5404 17.0463 18.4414 16.9494 18.3127 16.8321C18.0546 16.5966 17.6814 16.282 17.2242 15.9683C16.9805 15.8012 16.7185 15.6381 16.4421 15.4883C16.7016 15.9322 16.8502 16.4487 16.8502 16.9999C16.8502 18.6567 15.5071 19.9999 13.8502 19.9999C12.1934 19.9999 10.8502 18.6567 10.8502 16.9999C10.8502 16.4486 10.9989 15.932 11.2584 15.4881C10.9819 15.6379 10.7198 15.8011 10.476 15.9683C10.0188 16.282 9.64555 16.5966 9.38746 16.8321C9.25879 16.9494 9.15975 17.0463 9.09425 17.1123C9.06153 17.1452 9.03726 17.1704 9.02192 17.1865L9.00572 17.2037L9.00335 17.2062Z"></path>
  <circle cx="14.8253" cy="16.1749" r="1.125" fill="white"></circle>
</svg>  
`;
export default class MergeFieldPreviewDropdown extends Plugin {
  static get pluginName() {
    return 'MergeFieldPreviewDropdown';
  }

  init() {
    const editor = this.editor;
    const t = editor.t;
    const dropdownTooltip = t('Merge Fields Preview');
    const mergeFieldsDefaults = editor.config.get('mergeFieldsDefaults') || {};

    editor.ui.componentFactory.add('mergeFieldPreviewDropdown', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: 'Merge Fields Preview',
        tooltip: dropdownTooltip,
        icon: mergeFieldPreviewIcon,
        withText: false
      });

      dropdownView.extendTemplate({
        attributes: {
          class: ['ck-merge-field-preview-dropdown']
        }
      });

      const items = new Collection();

      const labelModeItem = new ViewModel({
        id: 'label_mode',
        label: 'Labels',
        withText: true
      });

      items.add({ type: 'button', model: labelModeItem });

      const valueModeItem = new ViewModel({
        id: 'value_mode',
        label: 'Values',
        withText: true
      });

      items.add({ type: 'button', model: valueModeItem });

      addListToDropdown(dropdownView, items);

      dropdownView.on('execute', (evt) => {
        const selectedMode = evt.source.id;

        editor.model.change((writer) => {
          let content = editor.getData();

          mergeFieldsOptions.forEach(({ id, label }) => {
            const placeholder = `{{${label}}}`;
            const defaultValue = mergeFieldsDefaults[id] || `{{${label}}}`; // Using label for placeholders in UI

            if (selectedMode === 'label_mode') {
              // Convert default values back to placeholders (labels)
              content = content.replace(
                new RegExp(defaultValue, 'g'),
                placeholder
              );
            } else if (selectedMode === 'value_mode') {
              // Replace placeholders (labels) with values
              content = content.replace(
                new RegExp(placeholder, 'g'),
                defaultValue
              );
            }
          });

          editor.setData(content);
        });
      });

      return dropdownView;
    });
  }
}
