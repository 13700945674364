import { APICONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';

class TemplateStore {
  static instance = null;
  templates = [];

  static getInstance() {
    if (!TemplateStore.instance) {
      TemplateStore.instance = new TemplateStore();
    }
    return TemplateStore.instance;
  }

  async fetchTemplates() {
    try {
      const response = await axios(APICONSTANTS.getTemplates);
      this.templates = response.data || [];
    } catch (error) {
      console.error('Error fetching templates:', error);
      this.templates = [];
    }
  }

  async getTemplates() {
    return this.templates;
  }
}

export default TemplateStore;
