import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';

import { SnackContext, AppContext, ThemeContext } from 'src/store/ContextStore';
import { getAddressFlags, getDependencies } from './formService';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import TextInput from 'src/components/UI/input/TextInput';
import AcitonDialog from 'src/components/UI/dialog/AcitonDialog';

export default function AddressForm({
  handleClose,
  familyMemberId,
  familyId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { theme } = React.useContext(ThemeContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { setSnack } = React.useContext(SnackContext);

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        addressLine1: '',
        addressLine2: '',
        town: '',
        city: '',
        county: '',
        state: '',
        postCode: '',
        correspondenceAddress: true,
        primaryAddress: true
      };
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [addressFlag, setAddressFlag] = React.useState({
    hasPrimaryAddress: false,
    hasCorrespondenceAddress: false,
    primaryAddressId: null,
    correspondenceAddressId: null
  });

  React.useEffect(async () => {
    getAddressFlags({
      familyId: familyId,
      familyMemberId: familyMemberId
    })
      .then((flag) => setAddressFlag(flag))
      .catch((err) =>
        setSnack({
          status: true,
          msg: `An error occured `,
          severity: 'error'
        })
      );
  }, []);

  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
    if (familyMemberId == 'none') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: true
      }));
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);
      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [confirmationDialog, setConfirmationDialog] = React.useState({});

  const onCancel = () => {
    setConfirmationDialog({ open: false });
  };

  const saveAddressAndClose = () => {
    return axios
      .put(APICONSTANTS.address, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.addressLine1 && !values.town && !values.city) {
      errors.addressLine1 =
        'At least one of Address Line 1, Town, or City must be provided';
      errors.town =
        'At least one of Address Line 1, Town, or City must be provided';
      errors.city =
        'At least one of Address Line 1, Town, or City must be provided';
    } else if (
      !(addressFlag.primaryAddressId === formValues._id) &&
      formValues.primaryAddress &&
      addressFlag.hasPrimaryAddress
    ) {
      errors.primaryAddress = 'Primary Address exists';
      setConfirmationDialog({
        open: true,
        title: 'Change Primary Address',
        message:
          'Please note that saving this address as Primary Address will overwrite the primary flag on other address record. Please confirm you wish to proceed',
        onConfirm: () => {
          saveAddressAndClose();
        },
        onCancel: onCancel
      });
    } else if (
      !(addressFlag.correspondenceAddressId === formValues._id) &&
      formValues.correspondenceAddress &&
      addressFlag.hasCorrespondenceAddress
    ) {
      errors.correspondenceAddress = 'Correspondence Address exists';
      setConfirmationDialog({
        open: true,
        title: 'Change Correspondence Address',
        message:
          'Please note that saving this address as Correspondence Address will overwrite the primary flag on other address record. Please confirm you wish to proceed',
        onConfirm: () => {
          saveAddressAndClose();
        },
        onCancel: onCancel
      });
    }

    setError(errors);
    return errors;
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const errors = validateForm(formValues);

    if (Object.keys(errors).length > 0) return Promise.reject();

    return saveAddressAndClose();
  };

  return (
    //Should be fine to reuse old class?

    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Address ?"
        content="Warning: This action will permanently delete this Address. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="addressLine1"
            value={formValues.addressLine1}
            label="Address Line 1"
            error={!!error.addressLine1}
            helperText={error.addressLine1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="addressLine2"
            value={formValues.addressLine2}
            label="Address Line 2"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="town"
            value={formValues.town}
            label="Town"
            error={!!error.town}
            helperText={error.town}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="city"
            value={formValues.city}
            label="City"
            error={!!error.city}
            helperText={error.city}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="county"
            value={formValues.county}
            label="County"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="state"
            value={formValues.state}
            label="State"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="postcode"
            value={formValues.postcode}
            label="Postcode"
          />
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Correspondence Address
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.correspondenceAddress ?? false}
                onChange={onToggleSwitch}
                name="correspondenceAddress"
              />
            </Grid>
            <Grid item>
              <Typography>
                {formValues.correspondenceAddress ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Primary Address
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.primaryAddress ?? false}
                onChange={onToggleSwitch}
                name="primaryAddress"
              />
            </Grid>
            <Grid item>
              <Typography>
                {formValues.primaryAddress ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
          handleClose={handleClose}
          onClick={handleSubmit}
          addLabel={isEditing ? 'Add new address' : 'Save New Address'}
        />
      </Grid>
      <ConfirmationDialog confirmationDialog={confirmationDialog} />
    </form>
  );
}

const ConfirmationDialog = ({ confirmationDialog }) => {
  const { open, message, onConfirm, onCancel, title } = confirmationDialog;

  const handleClose = () => onCancel();

  return (
    <AcitonDialog
      open={open}
      handleClose={handleClose}
      onSubmit={onConfirm}
      title={title}
    >
      <Typography>{message}</Typography>
    </AcitonDialog>
  );
};
