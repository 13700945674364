import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';
import moment from 'moment';

export const REFERRAL_REASONS = [
  { label: 'Personal history', value: 'personal_history' },
  { label: 'Family history', value: 'family_history' },
  { label: 'Suspected diagnosis', value: 'suspected_diagnosis' },
  { label: 'Carrier testing', value: 'carrier_testing' },
  { label: 'Predictive testing', value: 'predictive_testing' },
  { label: 'Advanced maternal age', value: 'advanced_maternal_age' }
];

export const REFERRAL_TRIAGE = [
  { label: 'Immediate', value: 'immediate' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Semi-urgent', value: 'semi_urgent' },
  { label: 'Standard', value: 'standard' }
];

export const CONTACT_TYPES = [
  { label: 'Contact with patient', value: 'contact_with_patient' },
  {
    label: 'Contact with patient’s doctor',
    value: 'contact_with_patient_doctor'
  },
  { label: 'Post clinic follow-up', value: 'post_clinic_follow_up' },
  { label: 'Pre-clinic request', value: 'pre_clinic_request' },
  { label: 'Case review', value: 'case_review' },
  { label: 'Risk notification', value: 'risk_notification' }
];

export const SAMPLE_TYPES = [
  { label: 'Blood', value: 'blood' },
  { label: 'Buccal swab', value: 'buccal_swab' },
  { label: 'Saliva', value: 'saliva' },
  { label: 'Hair', value: 'hair' },
  { label: 'Prepared DNA extract', value: 'prepared_dna_extract' }
];

export const CONSENT_GIVEN_BY = [
  { label: 'Self', value: 'self' },
  { label: 'Parent', value: 'parent' },
  { label: 'Child', value: 'child' },
  { label: 'Other relative', value: 'other_relative' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Power of Attorney', value: 'power_of_attorney' }
];

export const CONSENT_RESPONSE = [
  { label: 'Consent given', value: 'consent_given' },
  { label: 'Denied consent', value: 'denied_consent' },
  { label: 'Partial consent', value: 'partial_consent' },
  { label: 'Withdrawn consent', value: 'withdrawn_consent' },
  { label: 'No response', value: 'no_response' }
];

const getReferralTags = async (theme, memberId, familyId, response) => {
  if (!response) response = await axios.get(APICONSTANTS.referral);

  let referralOptions = response.data;
  referralOptions = referralOptions.filter((referral) => {
    return (
      (referral.familyMemberId == memberId || referral.showInFamily) &&
      referral.familyId == familyId
    );
  });
  let referralTags = [];
  for (const referral of referralOptions) {
    referralTags.push({
      _id: referral._id,
      label:
        referral.referralName +
        ' ' +
        moment(referral.createdDate).format(theme.dateFormat)
    });
  }
  return referralTags;
};

const getConsentTags = async (theme, memberId, familyId, response) => {
  if (!response) response = await axios.get(APICONSTANTS.consent);
  let options = response.data;
  options = options.filter((option) => {
    return option.familyMemberId == memberId && option.familyId == familyId;
  });
  let Tags = [];
  for (const option of options) {
    Tags.push({
      _id: option._id,
      label:
        option.consentType +
        ' ' +
        moment(option.createdDate).format(theme.dateFormat)
    });
  }
  return Tags;
};

const getDependencies = async (familyId, _id, dateFormat) => {
  let dependences = [];
  await axios
    .get(`/clinical-notes/all`, {
      params: { familyId }
    })
    .then((res) => {
      for (const note of res.data) {
        if (
          note.associatedReferral?._id == _id ||
          note.associatedConsent?._id == _id
        ) {
          dependences.push(
            note.noteType +
              ' created ' +
              moment(note.createdAt).format(dateFormat)
          );
        }
      }
    });
  return dependences;
};

//Keeping these here just in case I need them, for now they need fixing which I have no reason to do
//const getAppointmentTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.appointment}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.clinicName + ' ' + option.appointmmentDate.split('T')[0]);
//  }
//  return Tags;
//};

//const getContactTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.contact}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.contactType + ' ' + option.contactDate.split('T')[0]);
//  }
//  return Tags;
//};
//
//
//const getSampleTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.sample}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.sampleType + ' ' + option.dateTaken.split('T')[0]);
//  }
//  return Tags;
//};
//
//const getTestTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.test}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.testName + ' ' + option.resultDueDate.split('T')[0]);
//  }
//  return Tags;
//};
//
//const getAddressTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.address}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.postCode + ' ' + option.town);
//  }
//  return Tags;
//};
//
//const getClinicalNoteTags = async (response) => {
//  if (!response)
//    response = await axios.get(`/api/${APICONSTANTS.clinicalNote}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.clinician + ' ' + option.date.split('T')[0]);
//  }
//  return Tags;
//};

const getAddressFlags = async ({ familyId, familyMemberId }) => {
  const res = await axios.get(
    APICONSTANTS.fetchAddressFlags
      .replace(':familyId', familyId)
      .replace(':familyMemberId', familyMemberId)
  );

  return res.data || {};
};

const handleUpload = async (e) => {
  if (e.target.files.length !== 0) {
    const formData = new FormData();
    formData.append('file_attachment', e.target.files[0]);
    axios.post(APICONSTANTS.fileAttachment, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export {
  getAddressFlags,
  getConsentTags,
  getReferralTags,
  getDependencies,
  //getAddressTags,
  //getClinicalNoteTags,
  //getTestTags,
  //getSampleTags,
  //getContactTags,
  //getAppointmentTags,
  handleUpload
};
