/* eslint-disable no-undef */
const basepath = '/admin';

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const FEATURE_FORM_BUILDER =
  process.env.REACT_APP_FEATURE_FORM_BUILDER === 'true';

export const APICONSTANTS = Object.freeze({
  loginverify: '/token',
  login: '/login',
  logout: '/signout',
  ssoLogout: '/sso-signout',
  ssoLogin: '/api/sso/auth/microsoft',

  //public-routes
  recaptcha: '/system-params/recaptcha',
  defaultTheme: '/system-params/default-theme',

  filterProbandUsers: '/pedigree/filter',
  updatePedigreeDetails: '/pedigree/update/user',
  getPedigreeDetails: '/pedigree/details/:familyId',
  getProbandDetails: '/proband',
  getProbandSubmittedDetails: '/proband/submitted',
  deleteProbandFamilyMember: '/proband/family/:user_id/:member_id',
  probandFamilyDetails: '/proband/family/:user_id',
  getProbandUsers: `/users/proband`,
  getFamiliesInfo: `/users/proband/:user_id/family`,
  getXMLData: '/users/proband/:user_id/xml',
  getGA4JsonData: '/users/proband/:user_id/json',
  updateTourSkip: '/users/update-tour-skip',

  savePedigree: 'proband/family-tree/:id',
  setTags: '/pedigree/tags',
  pedigreeConfig: '/pedigree/pedigree-config',
  savePedigreeMemberDetails: '/pedigree/config/:user_id/member-details',
  updatePedigreeRelaiton: '/pedigree/config/:user_id/set-relation',

  //Pedigree
  deletePedigreeFamilyMember: '/pedigree/delete/:user_id/:member_id',
  reassignPedigreeMember: '/pedigree/:user_id/reassign-member',
  removeRelationInPedigree: '/pedigree/:user_id/remove-relation',

  createFamily: '/pedigree/createNewFamily',

  getMessagesByConversationId: '/message/conversation/:conversationId',
  updateMessagesByConversationId:
    '/message/update/conversation/:conversationId',

  createConversation: '/conversation/create',
  getConversation: '/conversation/patient/:user_id',
  getPhysicanToSendMsg: '/conversation/send/:user_id',

  getAuditLogs: '/audit-log',
  getAuditLogsByFamilyMemberId: '/audit-log/:familyMemberId',

  deleteProbandPregnancy: '/proband/pregnancy/:user_id/:pregnancy_id',
  probandPregnanciesDetails: '/proband/pregnancy/:user_id',
  getPregnanciesInfo: `/users/proband/:user_id/pregnancy`,

  deleteUser: '/users/proband/:user_id',
  markStatusAsImported: '/users/proband/imported/:user_id',
  setupPatientAccess: '/users/proband/:user_id/setup-patient',

  generateLink: '/questionnaire-links/generate',
  getGeneratedLinks: '/questionnaire-links',
  revertToDraft: '/questionnaire-links/revert/draft',
  deactivateLink: '/questionnaire-links/update/deactivate',
  sendLinkEmail: '/questionnaire-links/notify/email',

  getReferenceCode: '/reference-code',
  verifyReferenceCode: '/reference-code/verify',
  generateReferenceCode: '/reference-code/generate',

  generateLinkByPatient: '/questionnaire/generate-link',

  resetPasswordEmail: '/password/reset/email',
  resetPasswordToken: '/password/reset/token',
  resetPassword: '/password/reset',
  changePassword: '/users/password/update',

  questionCustomisations: '/settings/customisation',
  updateCustomisation: '/settings/customisation/update',
  labelsCustomisation: '/settings/customisation/labels',
  updateQuesCustomisation: '/settings/customisation/questions',
  setCustomDiagnoses: '/settings/customisation/customDiagnoses',
  setClinicianDiagnoses: '/settings/customisation/clinicianDiagnoses',
  getClinicianDiagnoses: '/settings/customisation/clinicianDiagnoses',
  editClinicianDiagnoses: '/settings/customisation/clinicianDiagnoses/:id',
  smtpDetails: '/settings/smtp',
  emailTemplates: '/settings/email-templates',
  checkEmailTemplate: '/settings/check-email-template',
  stripe: '/settings/stripe',
  stripeKeys: '/settings/stripe/keys',
  stripteProducts: '/settings/stripe/products',

  updateReminder: '/settings/update-reminder',
  getReminder: '/settings/reminder',

  // EMR feature
  adminEmrSettings: '/settings/admin/emr-settings',
  emrSettings: '/settings/emr-settings',
  emrSettingsStatus: '/emr-settings/status',
  emrIntegrationAccessToken: '/emr-settings/access-token?code=:code',
  emrIntegrationRefreshToken: '/emr-settings/refresh-token',

  inactivityLogoutDuration: '/settings/admin/inactivity-logout-duration',

  systemAnnouncement: '/settings/admin/system-announcement',
  getSystemAnnouncement: '/settings/system-announcement',

  setCustomTheme: '/settings/theme',
  getCustomTheme: '/settings/theme',
  deleteThemeLogo: '/settings/theme',

  generateLinkTokenExpireIn: `/settings/generate-link-token-expire-in`,

  getAllClients: '/admin/clients',
  createClient: '/admin/client/create',
  updateClient: '/admin/client/:_id/update',
  getUserGroups: '/admin/user-groups',
  deleteClient: '/admin/client/:_id',
  clientStatus: '/admin/client/:_id/status',

  dataAnalysis: '/data-analysis',

  generateClientApi: '/admin/client/generate-api',
  revokeClientApi: '/admin/client/generate-api/:id',
  getClientApi: '/admin/client/apiStatus',

  formBuilderSchemas: '/form-builder/schemas',
  formBuilderGenerateLink: '/form-builder/generate-links',
  formBuilderGeneratedLinks: '/form-builder/links',
  formResponsesPath: `/form-builder/responses/:_id`,
  formResponsesDeactivatePath: `/form-builder/responses/:_id/deactivate`,
  formRevertToDraft: `/form-builder/revert-draft/:_id`,
  formExportResponse: `/form-builder/export/csv/:template_id`,
  formResponsesByFamilyMember:
    '/form-builder/responses/familyMember/:familyMemberId',
  formBuilderSchemasMappings: '/form-builder/schemas/:_id/mappings',

  // Risk score
  calculateGailRiskScore: '/risk-factor/calculateGailRiskScore',
  getRiskFactor: '/risk-factor/:pid',

  //translation
  updateTranslation: '/translation',
  getTranslations: '/translation/:lang',

  // form builder feature
  formBuilderFeatureStatus: '/form-builder/status',

  // chat feature
  chatFeature: '/settings/chat/status',
  getChatFeature: '/settings/chat/status/:userGroup',

  // System Features
  getSystemFeatures: '/settings/features',
  medicareFeature: '/settings/features/medicare',
  carrierScreenFeature: '/settings/features/carrierScreen',

  // Student Account Registration Feature
  studentRegistration: '/settings/features/student-registration',

  // medicare Tyro Config
  tyroConfig: '/settings/tyro-config',

  patientEmailRegister: '/settings/patient-email-registration',

  // get all admin/user-group settings status
  getAllFeaturesSettings: '/settings/features/all',
  getAllGlobalFeaturesStatus: '/settings/global/features/status',

  //to get progress of every users for available froms
  formProgress: '/form-builder/progress-data',

  formBuilderWorkflowSchemas: '/form-builder/workflows',
  formUnlockAndReset: '/form-builder/workflows/unlock',

  // update patient selected userGroup
  setPatientSelectedUserGroup: '/users/patient/selected-usergroup',

  setDefaultLinkParameter: '/trakgene/linkparameters/default',
  getDefaultLinkParameter: '/trakgene/linkparameters/default',

  updateSystem: '/system-update/install',
  getCurrentVersion: '/system-update/current-version',
  checkSystemUpdate: '/system-update/check',
  toggleSystemUpdatePermission: '/system-update/client',

  //storage configs
  storageConfig: '/settings/storage-config',

  // Phenotypes
  phenotypes: '/phenotypes',
  getPhenotypesById: '/phenotypes/:user_id/:familyMemberId',
  updatePhenotype: '/phenotypes/:user_id/:familyMemberId', // Add this line
  // Genotypes
  genotypes: '/genotypes',
  getGenotypesById: '/genotypes/:user_id/:familyMemberId',
  updateGenotype: '/genotypes/:user_id/:familyMemberId',
  // Diagnoses
  diagnoses: '/diagnoses',
  getDiagnosesById: '/diagnoses/:user_id/:familyMemberId',

  medicareSave: '/medicare/file-data',
  deleleMedicareBill: '/medicare/file-data/:id',
  archiveMedicareBill: '/medicare/file-data/archive/:id',
  getMedicareInvoices: '/medicare/invoices',
  deleteMedicarePatientInvoice: '/medicare/invoices/:id',
  claimMedicarePatientInvoice: '/medicare/invoices/:id',
  updateMedicarePatientInvoice: '/medicare/invoices/update',
  medicareInvoiceMarker: '/medicare/invoice/manual-marker',

  medicareOnlineTransactionHook:
    '/api/medicare-transactions/online-transaction',
  medicareClaimTransactionHook: '/api/medicare-transactions/transactions',

  saveCarrierScreenReports: '/carrier-screen/reports',
  deleteCarrierScreenReport: '/carrier-screen/reports/:id',
  getCarrierScreenReports: '/carrier-screen/reports',

  // clinical-notes
  referral: '/clinical-notes/referral',
  fileAttachment: '/clinical-notes/file',
  appointment: '/clinical-notes/appointment',
  consent: '/clinical-notes/consent',
  contact: '/clinical-notes/contact',
  sample: '/clinical-notes/sample',
  test: '/clinical-notes/test',
  address: '/clinical-notes/address',
  clinicalNote: '/clinical-notes/clinical-note',

  fetchAddressFlags: '/clinical-notes/address/:familyId/:familyMemberId',

  // account data deletion
  getDataOverview: `${basepath}/account/overview`,
  deleteAccountData: `${basepath}/account/delete`,

  //templates
  createTemplate: `/letter-template/create`,
  getTemplates: '/letter-template',
  getTemplateById: '/letter-template/:id',
  updateTemplate: '/letter-template/:id',

  //letters
  createLetter: `/letter/create`,
  getLetters: '/letter/familyMemberId/:familyMemberId',
  getLetterById: '/letter/:id',
  updateLetter: '/letter/:id'
});

export const CONSTANTS = Object.freeze({
  relations: {
    son: 'Son',
    daughter: 'Daughter',
    fullSister: 'Full Sister',
    halfSister: 'Half Sister',
    fullBrother: 'Full Brother',
    halfBrother: 'Half Brother',
    mother: 'Mother',
    father: 'Father',
    mothersMother: "Mother's Mother",
    mothersFather: "Mother's Father",
    fathersMother: "Father's Mother",
    fathersFather: "Father's Father",
    mothersBrother: "Mother's Brother",
    mothersSister: "Mother's Sister",
    fathersBrother: "Father's Brother",
    fathersSister: "Father's Sister",
    otherAffected: 'Other relative with disease/disorder'
  },
  gender: {
    son: 'male',
    daughter: 'female',
    fullSister: 'female',
    halfSister: 'female',
    fullBrother: 'male',
    halfBrother: 'male',
    mother: 'female',
    father: 'male',
    mothersMother: 'female',
    mothersFather: 'male',
    fathersMother: 'female',
    fathersFather: 'male',
    mothersBrother: 'male',
    mothersSister: 'female',
    fathersBrother: 'male',
    fathersSister: 'female',
    otherAffected: 'unknown'
  },

  genderIdentity: {
    male: 'Male',
    female: 'Female',
    Indeterminate: 'Indeterminate',
    'Male - AFAB': 'Male - AFAB', //Female AFAB Assigned female at birth, identifying as male Square Mother
    'Male - UAAB': 'Male - UAAB', //Indeterminate UAAB VSC identifying as male Square Question
    'Female - AMAB': 'Female - AMAB', //Male AMAB Assigned male at birth, identifying as female Circle Father
    'Female - UAAB': 'Female - UAAB', //Indeterminate UAAB VSC identifying as female Circle
    'Non-binary/gender diverse - AFAB': 'Non-binary/gender diverse - AFAB', //Female AFAB Assigned female at birth, identifying as non-binary Diamond Mother
    'Non-binary/gender diverse - AMAB': 'Non-binary/gender diverse - AMAB', //Male AMAB Assigned male at birth, identifying as non-binary Diamond Father
    'Non-binary/gender diverse - UAAB': 'Non-binary/gender diverse - UAAB', //Indeterminate UAAB VSC, sex unassigned at birth - identifying as non-binary Diamond Question Prompt
    'Unknown/Not Stated Unknown': 'Unknown/Not Stated Unknown' //- Sex unknown or not stated or representing multiple persons of all sexes Diamond Question
  },

  genderIdentityOptions: [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Male - Assigned Female at Birth', value: 'Male - AFAB' },
    {
      label: 'Male -  Unspecified/Unknown Assigned at Birth',
      value: 'Male - UAAB'
    },
    { label: 'Female - Assigned Male at Birth', value: 'Female - AMAB' },
    {
      label: 'Female - Unspecified or Unknown Assigned at Birth',
      value: 'Female - UAAB'
    },
    {
      label: 'Non-binary/gender diverse -  Assigned Female at Birth',
      value: 'Non-binary/gender diverse - AFAB'
    },
    {
      label: 'Non-binary/gender diverse - Assigned Male at Birth',
      value: 'Non-binary/gender diverse - AMAB'
    },
    {
      label:
        'Non-binary/gender diverse - Unspecified or Unknown Assigned at Birth',
      value: 'Non-binary/gender diverse - UAAB'
    },
    { label: 'Unknown/Not Stated ', value: 'Unknown/Not Stated ' }
  ],

  adoptedMap: [
    { label: 'None', value: null },
    { label: 'Adopted In', value: 'adopted_in' },
    { label: 'Adopted Out', value: 'adopted_out' }
  ],

  fertilityMap: [
    { label: 'None', value: null },
    { label: 'Infertile', value: 'infertile' },
    { label: 'No Issue', value: 'fertile' }
  ],

  genderMappings: {
    Male: 'male',
    Female: 'female',
    Indeterminate: 'unknown',
    'Male - AFAB': 'female',
    'Male - UAAB': 'unknown',
    'Female - AMAB': 'male',
    'Female - UAAB': 'unknown',
    'Non-binary/gender diverse - AFAB': 'female',
    'Non-binary/gender diverse - AMAB': 'male',
    'Non-binary/gender diverse - UAAB': 'unknown',
    'Unknown/Not Stated Unknown': 'unknown'
  },

  tags: [
    { label: 'research project', tagColor: 'rgb(210, 221, 250)' },
    { label: 'clinical study', tagColor: 'rgb(211, 250, 210)' },
    { label: 'clinic', tagColor: 'rgb(250, 224, 210)' }
  ],

  linkStatusMap: {
    false: 'Expired',
    true: 'Active'
  },

  pedigreeState: {
    unchanged: 'UN_CHANGED',
    modified: 'MODIFIED'
  },

  //languages
  languages: [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'es', label: 'Espagnol' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fi', label: 'Suomi' },
    { value: 'hi', label: 'हिंदी' },
    { value: 'ms', label: 'melayu' },
    { value: 'it', label: 'italiano' },
    { value: 'si', label: 'සිංහල' }
  ],

  flags: {
    en: 'GB',
    fr: 'FR',
    es: 'ES',
    de: 'DE',
    fi: 'FI',
    hi: 'IN',
    ms: 'MY',
    it: 'IT',
    si: 'LK'
  },

  //Custom theme loaded for default users
  customTheme: {
    dateFormat: 'DD/MM/YYYY',
    appearance: {
      value: 'light',
      color: '#FFFFFF',
      shade: '#DDDDDD',
      text: '#000'
    },
    navbar: '#FFFFFF',
    navbarText: '#000',
    sidebar: '#FFFFFF',
    sidebarText: '#000',
    accent: '#2ba9bf',
    header: '#2ba9bf',
    buttonStyle: '#f50057',
    buttonText: '#FFFFFF',
    highlighter: '#53cd53'
  },

  signinPath: `/identity/login`,
  studentRegister: `/student/register`,

  userformPath: '/form',
  adminPath: basepath,
  adminDashboardPath: `${basepath}/dashboard`,
  resetPasswordPath: `${basepath}/password/reset`,
  resetPasswordLinkPath: `/password/reset/token`,
  generateLinkPath: `${basepath}/generateLink`,
  pedigreeToolPath: `${basepath}/pedigree`,
  pedigreeChartPath: `${basepath}/pedigree/:_id/`,
  settingsPath: `${basepath}/settings`,
  errorPagePath: '/auth/error',

  medicarePath: `${basepath}/medicare`,
  medicareBillingPath: `${basepath}/medicare/:_id/`,

  carrierScreenPath: `${basepath}/carrier-screen`,
  carrierScreenReportPath: `${basepath}/carrier-screen/:_id`,

  patientPath: '/patients',
  patientDashboardPath: `/patients/dashboard`,
  patientProband: `/patients/proband`,
  patientPedigreeToolPath: `/patients/pedigree`,
  patientPedigreeChartPath: `/patients/pedigree/:_id/`,
  patientSettingsPath: `/patients/settings`,
  patientChatPath: `/patients/chat`,

  formCreationPath: `${basepath}/form-builder`,
  formSchemaViewPath: `${basepath}/form-builder/view/:_id`,
  newFormCreationPath: `${basepath}/form-builder/create`,
  formResponsesPath: `${basepath}/form-builder/responses/:_id`,
  formBuilderGenerateLinksPath: `${basepath}/form-builder/generate-links`,
  formProgressTracker: `${basepath}/form-builder/progress-tracker`,
  //WORKFLOWS PATH
  workflowCreationPath: `${basepath}/form-builder/workflows`,
  newWorkflowCreationPath: `${basepath}/form-builder/workflows/create`,
  workflowSchemaViewPath: `${basepath}/form-builder/workflows/view/:_id`,

  manageUserPath: `${basepath}/manageUsers`,
  dataAnalysis: `${basepath}/dataAnalysis`,

  probandInfoPath: `${basepath}/users`,
  calendarPath: `${basepath}/calendar`,
  probandEditPath: `${basepath}/users/edit`,
  probandViewPath: `${basepath}/users/view`,
  familyMemberInfoPath: `${basepath}/users/:user_id/family/:member_id`,
  pregnancyInfoPath: `${basepath}/users/:user_id/pregnancy/:pregnancy_id`,

  // Letters Template
  letterTemplates: `${basepath}/settings/letter-templates`,
  letterTemplateCreationPath: `${basepath}/settings/letter-templates/create`,
  letterTemplateEditPath: `${basepath}/settings/letter-templates/edit/:template_id`,
  letterTemplateViewPath: `${basepath}/settings/letter-templates/view/:template_id`,

  customDiagnoses: {
    'breast-cancer': { value: 'Breast Cancer', status: true },
    'pancreatic-cancer': { value: 'Pancreatic Cancer', status: true },
    'colon-cancer': { value: 'Colon Cancer', status: true },
    'endometrial-cancer': {
      value: 'Endometrial (Uterus) cancer',
      status: true
    },
    'ovarian-cancer': { value: 'Ovarian Cancer', status: true },
    'prostate-cancer': { value: 'Prostate Cancer', status: true },
    polyps: { value: 'Polyps', status: true },
    'heart-problems': { value: 'Heart problems', status: true },
    'eye-problems': { value: 'Eye problems', status: true },
    'kidney-problems': { value: 'Kidney problems', status: true },
    'neurological-conditions': { value: 'Neurological', status: true },
    other: { value: 'Other', status: true },
    'blood-disorders': { value: 'Blood Disorders', status: true },
    stillbirth: { value: 'Stillbirth', status: true },
    'recurrent-misscarriage': {
      value: 'Recurrent Miscarriage',
      status: true
    },
    'congenital-abnormalities': {
      value: 'Congenital Abnormalities',
      status: true
    },
    hemophilia_a: {
      value: 'Hemophilia A',
      status: true
    },
    hemophilia_b: {
      value: 'Hemophilia B',
      status: true
    },
    fibrinogen_disorder: {
      value: 'Fibrinogen Disorder',
      status: true
    },
    von_willebrand_disease: {
      value: 'Von Willebrand Disease',
      status: true
    },
    glanzmann_thrombasthenia: {
      value: 'Glanzmann Thrombasthenia',
      status: true
    },
    bernard_soulier_syndrome: {
      value: 'Bernard Soulier Syndrome',
      status: true
    },
    hereditary_hemorrhagic_telangiectasia: {
      value: 'Hereditary Hemorrhagic Telangiectasia (HHT)',
      status: true
    },
    platelet_function_disorder: {
      value: 'Platelet Function Disorder',
      status: true
    },
    neutropenia: {
      value: 'Neutropenia',
      status: true
    },
    protein_c_deficiency: {
      value: 'Protein C Deficiency',
      status: true
    },
    protein_s_deficiency: {
      value: 'Protein S Deficiency',
      status: true
    },
    antithrombin_iii_deficiency: {
      value: 'Antithrombin III Deficiency',
      status: true
    },
    factor_v_leiden: {
      value: 'Factor V Leiden',
      status: true
    },
    prothrombin_gene_variant: {
      value: 'Prothrombin Gene Variant',
      status: true
    },
    'low_platelet_count_(thrombocytopenia)': {
      value: 'Low Platelet Count (Thrombocytopenia)',
      status: true
    },
    'blood_clot_(thrombosis)': {
      value: 'Blood Clot (Thrombosis)',
      status: true
    }
  },

  customDiagnosesTypes: [
    {
      type: 'Cancer',
      list: [
        'breast-cancer',
        'pancreatic-cancer',
        'colon-cancer',
        'endometrial-cancer',
        'ovarian-cancer',
        'prostate-cancer',
        'polyps'
      ]
    },
    {
      type: 'Reproductive Health',
      list: ['stillbirth', 'recurrent-misscarriage', 'congenital-abnormalities']
    },
    {
      type: 'Blood Disorders',
      list: [
        'blood-disorders',
        'hemophilia_a',
        'hemophilia_b',
        'fibrinogen_disorder',
        'von_willebrand_disease',
        'glanzmann_thrombasthenia',
        'bernard_soulier_syndrome',
        'hereditary_hemorrhagic_telangiectasia',
        'platelet_function_disorder',
        'neutropenia',
        'protein_c_deficiency',
        'protein_s_deficiency',
        'antithrombin_iii_deficiency',
        'factor_v_leiden',
        'prothrombin_gene_variant',
        'low_platelet_count_(thrombocytopenia)',
        'blood_clot_(thrombosis)'
      ]
    },
    {
      type: 'General',
      list: [
        'heart-problems',
        'eye-problems',
        'kidney-problems',
        'neurological-conditions',
        'other'
      ]
    }
  ],
  patientInitialContent: {
    formBuilder: {
      schemas: [],
      workflows: [],
      linksGenerated: []
    },
    pedigreeSelectedUsers: {
      family: [],
      selectedUser: {},
      diagnoses: [],
      phenotypes: []
    },
    settings: {
      chatFeature: true
    },
    probandUsers: [],
    selectedGroup: 'default'
  },

  consent: {
    default: `I am happy for you to disclose where you are calling from should someone other than myself answer the phone, and I consent to a message being left`
  },

  initialContent: {
    // used to collapse the previously clicked pannel
    previousSelectedId: -1,
    probandUsers: [],
    pedigreeUsers: [],
    pedigreeSelectedUsers: {
      family: [],
      selectedUser: {},
      diagnoses: []
    },
    medicareInvoices: [],
    carrierScreenReports: [],
    linksGenerated: [],
    settings: {
      systemFeatures: {
        FEATURE_FORM_BUILDER: false,
        medicareFeature: false,
        carrierScreenFeature: false
      },
      customisation: {
        isEnabled: false,
        customPersonalDetailsInstructions: '<p></p>',
        customFamilyMembersInstructions: '<p></p>',
        customPregnanciesInstructions: '<p></p>',
        customRiskSurveyInstructions: '<p></p>',
        customAdditionalQuestionsInstructions: '<p></p>',
        customConfirmDetailsInstructions: '<p></p>',
        questions: [],
        questionsTitle: '',
        saveSurnameInitials: false,
        saveDateAsYears: false,
        customConsent:
          '<strong>I am happy for you to disclose where you are calling from should someone other than myself answer the phone, and I consent to a message being left.</strong>',
        customDiagnoses: null
      },
      smtp: {
        hostname: '',
        secure: false,
        port: '',
        username: '',
        password: ''
      },
      chatFeature: true,
      emailTemplates: [],
      stripeConfig: {},
      auditLogs: [],
      // epic emr sys admin settings
      epicEmrIntegration: {
        enabled: false
      },
      // epic emr genetics settings
      epicEmrApi: {
        enabled: false,
        endpoint: ''
      }
    },
    clients: [],
    formBuilder: {
      schemas: [],
      workflows: [],
      linksGenerated: []
    }
  },

  user_status: {
    DEACTIVATED: 'DEACTIVATED',
    CREATED: 'CREATED',
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    SUBMITTED: 'SUBMITTED',
    IMPORTED: 'IMPORTED'
  },
  client_status: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
  },
  twinTypeOptions: [
    { label: 'Identical', value: 'identical' },
    { label: 'Non-Identical', value: 'non-identical' }
  ],

  userTypes: {
    ADMIN: 'admin',
    GENETICS: 'genetics',
    GENETICS_SERVICE_ADMIN: 'geneticsServiceAdmin',
    GENETICS_USER: 'geneticsUser',
    PATIENT: 'patient',
    STUDENT: 'student'
  },
  userTypeOptions: [
    { label: 'System Admin', value: 'admin' },
    { label: 'Genetics Service', value: 'genetics' },
    { label: 'Genetics Service Admin', value: 'geneticsServiceAdmin' },
    { label: 'Genetics User', value: 'geneticsUser' }
  ],

  userTypeOptionsMap: {
    admin: 'System Admin',
    genetics: 'Genetics Service',
    geneticsServiceAdmin: 'Genetics Service Admin',
    geneticsUser: 'Genetics User',
    student: 'Student'
  },

  userRegionOptions: [
    { label: 'United States', value: 'US-NY' },
    { label: 'United Kingdom', value: 'GB' }
    // { label: 'Australia', value: null }
  ],

  raceOptions: [
    { label: 'White', value: 'white' },
    { label: 'African-American', value: 'black' },
    { label: 'Hispanic-American', value: 'hispanic' },
    { label: 'Chinese-American', value: 'chinese' },
    { label: 'Japanese-American', value: 'japanese' },
    { label: 'Filipino-American', value: 'filipino' },
    { label: 'Hawaiian-American', value: 'hawaiian' },
    { label: 'Other Pacific Islander', value: 'pacific' },
    { label: 'Other Asian', value: 'asian' }
  ],

  biopsiesOptions: [
    { label: 'No biopsies', value: 0 },
    { label: '1 biopsies', value: 1 },
    { label: '2 or more biopsies', value: 2 }
  ],

  menstruationOptions: [
    { label: 'Less than 12 year old', value: 11 },
    { label: '12 through 13 year old', value: 12 },
    { label: 'Greater than 13 year old', value: 14 }
  ],

  flbOptions: [
    { label: 'No births', value: 0 },
    { label: 'Less than 20 year old', value: 19 },
    { label: '20 through 24 year old', value: 20 },
    { label: '25 through 29 year old', value: 25 },
    { label: '30 years old & greater', value: 30 }
  ],

  relativeOptions: [
    { label: '0 relatives', value: 0 },
    { label: '1 relatives', value: 1 },
    { label: '2 or more relatives', value: 2 }
  ],

  emailTemplatesActionMap: {
    new_research_link_generated: 'New Custom Form link generated',
    new_link_generated: 'New Family History Questionnaire link generated',
    form_submitted: 'Questionnaire submitted (Send email to admin)',
    form_submitted_user: 'Questionnaire submitted (Send email to user)',
    questionnaire_submission_reminder: 'Automated Form reminder',
    new_client_created: 'New admin/genetics service user created',
    new_account_created: 'New patient/student/genetic user created'
  },
  emailTemplatesAdminActionMap: {
    new_stripe_client_created: 'New stripe genetics service user created'
  },

  socketEvents: {
    physicianAssigned: 'physicianAssigned',
    message: 'message',
    incUnreadMsgCount: 'incUnreadMsgCount',
    joinRoom: 'joinRoom',
    sendGeneralMessage: 'sendGeneralMessage',
    sendMessage: 'sendMessage'
  },

  linkParameters: {
    cancer: 'cancer',
    cardiac: 'cardiac',
    blood: 'blood'
  },

  auditLogType: {
    pedigreeChart: 'PEDIGREE_CHART',
    clinicalRecord: 'CLINICAL_RECORD',
    personDetails: 'PERSON_DETAILS',
    riskFactors: 'RISK_FACTORS',
    phenotypes: 'PHENOTYPES',
    genotypes: 'GENOTYPES',
    diagnoses: 'DIAGNOSES'
  },
  formTypeOptions: [
    { label: 'Family History Questionaire(FHQ)', value: 'FHQ' },
    { label: 'Custom Form', value: 'CUSTOM_FORM' }
  ]
});

export const NAV_ITEMS = {
  general: [
    {
      path: CONSTANTS.adminDashboardPath,
      label: 'Home',
      icon: 'home'
    },
    {
      path: CONSTANTS.generateLinkPath,
      label: 'Collect Family History',
      icon: 'generate-link',
      id: 'tour-fhq'
    },
    {
      path: CONSTANTS.probandInfoPath,
      label: 'Search Family History',
      icon: 'families',
      id: 'tour-proband'
    },
    {
      path: CONSTANTS.pedigreeToolPath,
      label: 'Pedigrees',
      icon: 'pedigree-tool',
      id: 'tour-pedigree'
    }
  ],
  formBuilderAddon: [
    {
      path: CONSTANTS.formCreationPath,
      label: 'Create Forms',
      icon: 'assignment'
    }
  ],
  medicareAddon: [
    {
      path: CONSTANTS.medicarePath,
      label: 'Medicare Billing',
      icon: 'medicare',
      id: 'tour-pedigree'
    }
  ],
  carrierScreenAddon: [
    {
      path: CONSTANTS.carrierScreenPath,
      label: 'Carrier Screening Reports',
      icon: 'carrier-screen',
      id: 'tour-pedigree'
    }
  ],

  admin: [
    { path: CONSTANTS.settingsPath, label: 'Settings', icon: 'settings' },
    { path: CONSTANTS.manageUserPath, label: 'Manage Users', icon: 'add-users' }
  ],
  patient: [
    { path: CONSTANTS.patientDashboardPath, label: 'Home', icon: 'home' },
    {
      path: CONSTANTS.patientProband,
      label: 'Family History',
      icon: 'families',
      id: 'tour-proband'
    },
    {
      path: CONSTANTS.patientChatPath,
      label: 'Chat',
      icon: 'chat',
      id: 'tour-chat'
    },
    {
      path: CONSTANTS.patientSettingsPath,
      label: 'Settings',
      icon: 'settings'
    }
  ],
  student: [
    { path: CONSTANTS.adminDashboardPath, label: 'Home', icon: 'home' },
    {
      path: CONSTANTS.pedigreeToolPath,
      label: 'Pedigree Tool',
      icon: 'pedigree-tool',
      id: 'tour-pedigree'
    }
  ]
};

export const STORAGE_TYPES = {
  GOOGLE: 'google',
  AWS: 'aws',
  AZURE: 'azure'
};

export const PEDIGREE = {
  defaultModalValues: { open: false, title: '' },

  saveStatus: {
    SAVED: 'Saved',
    IN_PROGRESS: 'Autosaving'
  },

  defaultFormValues: {
    sex: 'unknown',
    type: 'standard',
    connection: 'partnership'
  },
  sexOptions: [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Unknown', value: 'unknown' }
  ],
  typeOptions: [
    { label: 'Standard', value: 'standard' },
    { label: 'Proband', value: 'proband' },
    { label: 'Deceased', value: 'deceased' },
    { label: 'Multiple', value: 'multiple' },
    { label: 'Pregnant', value: 'pregnant' }
  ],
  connectionOptions: [
    { label: 'Partnership', value: 'partnership' },
    { label: 'Separation', value: 'separation' },
    { label: 'Consanguineous', value: 'consanguineous' },
    { label: 'Siblings', value: 'siblings' }
  ],

  defaultLabelFilters: {
    firstName: true,
    lastName: true,
    age: true,
    upn: true,
    personNote: true,
    phenotypesName: true,
    phenotypesID: true
  },

  legendLabelFilters: {
    diseases: true
  }
};

// For phenotypes
export const PHENOTYPES_CONSTANTS = {
  initialPage: 0,
  maxLimit: 9,
  hpoBaseURL: 'https://ontology.jax.org/api',
  searchHPOTerm: '/hp/search'
};

// For genotypes & diagnoses
export const HPO_CONSTANTS = {
  initialPage: 0,
  maxLimit: 9,
  hpoBaseURL: 'https://ontology.jax.org/api/network',
  searchHPOTerm: '/search/:category',
  category: {
    gene: 'gene',
    disease: 'disease'
  }
};

export const GLOBAL_CONSTANTS = Object.freeze({
  tokenKey: 'fhq_token',
  noExpiry: 'no_expiry'
});

export const modelRelations = {
  Family: ['User', 'FamilyChanged'],
  User: [
    'Family',
    'FamilyChanged',
    'Genetics',
    'Pregnancy',
    'PregnancyChanged',
    'UserChanged'
  ],
  FamilyChanged: ['User', 'Family']
};

export const TABLE_CONSTANTS = {
  generatedLinks: 'generated_links_table',
  familyMembers: 'family_members_table',
  pedigree: 'pedigree_table',
  forms: 'forms_table',
  workflows: 'workflow_table',
  formBuilderGeneratedLinks: 'form_builder_generated_links_table',
  progressTracker: 'progress_tracker_table',
  medicareBilling: 'medicare_billing_table',
  carrierScreenReport: 'carrier_screen_report_table',
  users: 'users_table',
  disease: 'disease_table',
  emailTemplates: 'email_templates_table',
  activityLogs: 'activity_logs_table',
  dataAnalysis: 'data_analysis_table',
  patientLinks: 'patient_links_table',
  viewOnlyfamilyDetails: 'view_only_family_details_table',
  viewOnlyPregnanciesDetails: 'view_only_pregnancies_details_table',
  details: 'details_table',
  alcoholConsumption: 'alcohol_consumption_table',
  pregDetails: 'preg_details_table',
  apiSetting: 'api_setting_table',
  customQuestions: 'custom_questions'
};
