import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import AssociatedNote from '../../../UI/input/AssociatedNote';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';

import { Box, TextField } from '@mui/material';
import DateInput from '../../../UI/input/DateInput';

import { formatDate } from 'src/util/util';
import { SnackContext, AppContext } from 'src/store/ContextStore';
import { getConsentTags, SAMPLE_TYPES } from './formService';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import { getDependencies } from './formService';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';

import TextInput from 'src/components/UI/input/TextInput';

export default function SampleForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);
  const { user: loggedInUser } = React.useContext(UserContext);

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        sampleType: '',
        dateTaken: new Date(),
        trackingId: '',
        sampleStatus: '',
        associatedConsent: { label: 'none', _id: '' },
        obtainedBy: staffMemberOptions.find(
          (mem) => mem.value === loggedInUser._id
        ).label
      };

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    if (e.target.type === 'date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();

      if (selectedDate >= today) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
      } else setError({});
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);
  let [consentOptions, setConsentOptions] = React.useState(['loading...']);
  React.useEffect(() => {
    const fetchData = async () => {
      const consentTags = await getConsentTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setConsentOptions(consentTags);
    };
    if (consentOptions != [] && consentOptions[0] == 'loading...') fetchData();
  }, []);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);
      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateForm = (values) => {
    let errors = {};

    if (values.sampleType === '') {
      errors.sampleType = 'Sample Type is required.';
    }
    if (values.dateTaken === '') {
      errors.dateTaken = 'Date Taken is required.';
    }

    if (!values.sampleHeldBy) {
      errors.sampleHeldBy = 'Sample Held By is required.';
    }

    if (values.dateTaken) {
      const selectedDate = new Date(values.dateTaken);
      const today = new Date();

      if (selectedDate > today) {
        errors.dateTaken = 'Date Taken cannot be in future';
      }
    }

    setError(errors);
    return errors;
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const errors = validateForm(formValues);

    if (Object.keys(errors).length > 0) return Promise.reject();
    // let obtainedBy = null;
    // if (formValues.obtainedBy) {
    //   obtainedBy = staffMembers.find(
    //     (member) => member.userId === formValues.obtainedBy
    //   );
    // }

    const parsePayload = {
      ...formValues
      // obtainedBy: obtainedBy ? [obtainedBy] : []
    };

    return axios
      .put(APICONSTANTS.sample, parsePayload)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Sample ?"
        content="Warning: This action will permanently delete this Sample. Once deleted, this
      information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        {/* Sample Type  */}
        <Grid item xs={12}>
          <TextInput
            label="Sample type"
            variant="outlined"
            type={'autocomplete'}
            fullWidth
            required
            onChange={(e) => {
              const label = e ? e.label : '';
              setFormValues({
                ...formValues,
                sampleType: label
              });
            }}
            name="sampleType"
            options={SAMPLE_TYPES}
            value={formValues.sampleType}
            validated={!!error.sampleType}
            helperText={error.sampleType}
          />
        </Grid>
        {/* Date Taken (Mandatory) & Associated Consent */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel required>Date Taken </FormLabel>
          </Grid>
          <DateInput
            name="dateTaken"
            value={formValues.dateTaken ? new Date(formValues.dateTaken) : null}
            disableFuture
            onChange={(val) =>
              handleChange({
                target: { name: 'dateTaken', value: val, type: 'date' }
              })
            }
            variant="outline"
            error={!!error.dateTaken}
            helperText={error.dateTaken}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Consent</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedConsent"
            handleChange={handleChange}
            value={formValues.associatedConsent}
            noteOptions={consentOptions}
          />
        </Grid>
        {/* Sample Held By */}
        <Grid item xs={12}>
          <TextInput
            label={'Sample Held By'}
            variant="outlined"
            name="sampleHeldBy"
            required
            value={formValues.sampleHeldBy || ''}
            onChange={handleChange}
            error={!!error.sampleHeldBy}
            helperText={error.sampleHeldBy}
          />
        </Grid>
        {/* Tracking ID */}
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Tracking ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="trackingId"
              value={formValues.trackingId}
            />
          </Box>
        </Grid>
        {/* Sample Status & Status Date */}
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Sample Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="sampleStatus"
              value={formValues.sampleStatus}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Status Date</FormLabel>
          </Grid>
          <DateInput
            name="statusDate"
            value={
              formValues.statusDate ? new Date(formValues.statusDate) : null
            }
            onChange={(val) =>
              handleChange({
                target: { name: 'statusDate', value: val, type: 'date' }
              })
            }
            variant="outline"
          />
        </Grid>

        {/* Obtained By */}
        <Grid item xs={12}>
          <TextInput
            label="Obtained By"
            type="autocomplete"
            name="obtainedBy"
            value={formValues.obtainedBy}
            onChange={(e) => {
              const label = e ? e.label : '';
              setFormValues({
                ...formValues,
                obtainedBy: label
              });
            }}
            options={staffMemberOptions || []}
            validated={!!error.obtainedBy}
            helperText={error.obtainedBy}
          />
        </Grid>
        {/* Modal Buttons */}
        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
          addLabel="Save new sample "
        />
      </Grid>
    </form>
  );
}
