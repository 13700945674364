import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box paddingY={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export const tabStyles = (theme) => {
  return makeStyles(() => {
    return {
      root: {
        flexGrow: 1,
        backgroundColor: theme ? theme.appearance.color : '#ffff',
        transition: 'background-color 1s'
      },
      colorPrimary: {
        backgroundColor: theme ? theme.appearance.color : '#ffff',
        color: theme ? theme.appearance.text : '#000',
        boxShadow: theme && `3px 3px 10px ${theme.appearance.shade}`,
        transition: 'all 1s'
      },
      indicator: {
        height: 4,
        backgroundColor: theme && theme.accent
      }
    };
  })();
};
