import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { APICONSTANTS, CONSTANTS } from '../Constants';
import { Grid } from '@mui/material';
import PedigreeBox from './PedigreeBox';
import { AppContext, LoadingContext } from 'src/store/ContextStore';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'src/axios';
import { EVENTS } from 'src/components/admin/appReducer';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden !important'
  }
});

function PedigreeChartView({ patient }) {
  const classes = useStyles();
  const { content } = React.useContext(AppContext);
  const {
    pedigreeSelectedUsers: { selectedUser }
  } = content;

  const history = useHistory();
  const { _id: familyId } = useParams();
  const { dispatchUpdate } = React.useContext(AppContext);
  const { setLoading } = React.useContext(LoadingContext);

  const getPedigreeDetails = () => {
    if (!familyId) return;
    setLoading(true);
    axios
      .get(APICONSTANTS.getPedigreeDetails.replace(':familyId', familyId))
      .then((resp) => {
        const { proband, families } = resp.data;

        const value = {
          family: families,
          selectedUser: proband
        };

        dispatchUpdate({ type: EVENTS.SET_PEDIGREE_SELECTED_USER, value });
      })
      .catch((err) => {
        history.push(CONSTANTS.pedigreeToolPath);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (!selectedUser?._id || selectedUser.familyId !== familyId) {
      getPedigreeDetails();
    }
  }, [selectedUser, familyId]);

  const pedigree = React.useMemo(() => {
    return <>{selectedUser?._id && <PedigreeBox patient={patient} />}</>;
  }, [patient, selectedUser]);

  return (
    <Grid container className={classes.container}>
      {pedigree}
    </Grid>
  );
}

export default PedigreeChartView;
