import React, { useState, useEffect, useContext, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Box,
  Typography,
  Switch
} from '@mui/material';
import {
  APICONSTANTS,
  CONSTANTS,
  PHENOTYPES_CONSTANTS
} from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import PhenotypesDetailsCard from './PhenotypesDetailsCard';
import { getPhenotypeSearchURL } from 'src/util/util';
import { AppContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { EVENTS } from 'src/components/admin/appReducer';
import HistoryIcon from '@mui/icons-material/History';

// ===== 引入与代码A相同的组件，但注意去掉手动选颜色 =====
import TextInput from 'src/components/UI/input/TextInput';
import { t } from 'i18next';

const PhenotypeModal = ({ open, onClose, phenotype, onSave, user }) => {
  const [formValues, setFormValues] = useState({
    clinicallyConfirmed: false,
    patientReported: false,
    isPrivate: false,
    displayOnPedigree: false,
    dateReported: '',
    recordedBy: ''
  });

  useEffect(() => {
    if (phenotype) {
      setFormValues({
        clinicallyConfirmed: phenotype.clinicallyConfirmed || false,
        patientReported: phenotype.patientReported || false,
        isPrivate: phenotype.isPrivate || false,
        displayOnPedigree: phenotype.displayOnPedigree || false,
        dateReported:
          phenotype.dateRecorded || new Date().toISOString().split('T')[0],
        recordedBy: phenotype.recordedBy || `${user.firstName} ${user.lastName}`
      });
    }
  }, [phenotype, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSwitchChange = (field) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleSave = () => {
    onSave({
      ...phenotype,
      clinicallyConfirmed: formValues.clinicallyConfirmed,
      patientReported: formValues.patientReported,
      isPrivate: formValues.isPrivate,
      displayOnPedigree: formValues.displayOnPedigree,
      dateRecorded: formValues.dateReported,
      recordedBy: formValues.recordedBy
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
          // 移除多余的 p: 4，改为手动分割顶栏与内容区
        }}
      >
        {/* 顶栏：使用系统主题的 secondary.main 来跟随主题色 */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            backgroundColor: 'secondary.main', // 跟随主题 secondary
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }}
        >
          {/* 左侧的小方块，演示略微深一点或相同的主题色 */}
          <Box />
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}
          >
            {t('Edit Phenotype')}
          </Typography>
        </Box>

        {/* 实际内容区：空出一点上边距，与顶栏分隔 */}
        <Box sx={{ p: 2, pt: 3 }}>
          {/* Phenotype 基本信息展示 */}
          <Box
            sx={{
              padding: 2,
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              boxShadow: 1,
              mb: 2
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {t('Name')}:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {phenotype?.name}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {t('ID')}:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {phenotype?.id}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {t('Definition')}:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {phenotype?.definition}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {t('Synonyms')}:
            </Typography>
            <Typography variant="body1" colour="text.secondary">
              {phenotype?.synonyms || t('None')}
            </Typography>
          </Box>

          {/* 日期与记录者 */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextInput
                type="date"
                label={t('Date Reported')}
                name="dateReported"
                value={formValues.dateReported}
                onChange={handleChange}
                disableFuture
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextInput
                label={t('Recorded By')}
                name="recordedBy"
                value={formValues.recordedBy}
                onChange={handleChange}
              />
            </Box>
          </Box>

          {/* 开关选项 */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 2
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{t('Clinically Confirmed')}</Typography>
              <Switch
                checked={formValues.clinicallyConfirmed}
                onChange={() => handleSwitchChange('clinicallyConfirmed')}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{t('Patient Reported')}</Typography>
              <Switch
                checked={formValues.patientReported}
                onChange={() => handleSwitchChange('patientReported')}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{t('Private')}</Typography>
              <Switch
                checked={formValues.isPrivate}
                onChange={() => handleSwitchChange('isPrivate')}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{t('Display on Pedigree')}</Typography>
              <Switch
                checked={formValues.displayOnPedigree}
                onChange={() => handleSwitchChange('displayOnPedigree')}
              />
            </Box>
          </Box>

          {/* 底部按钮 */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" colour="secondary" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button variant="contained" colour="secondary" onClick={handleSave}>
              {t('Save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

// =================== PhenotypesMenu 主组件 ===================
const PhenotypesMenu = ({
  data,
  setDataset,
  modificationHistoryHandle,
  deepCopy,
  setDeepCopy
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(PHENOTYPES_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fetchedPhenotypes, setFetchedPhenotypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPhenotype, setSelectedPhenotype] = useState(null);

  const { setSnack } = useContext(SnackContext);
  const { content, dispatchUpdate } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const t = useAutoTranslation();

  let user_id = useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  let familyMemberId = useMemo(() => {
    return data?.data?.familyMemberId;
  }, [data]);

  let proBandId = useMemo(() => {
    return data?.data?.proBandId;
  }, [data]);

  const fetchDataByHPOTerm = async (currentPage) => {
    if (searchTerm.length >= 3) {
      let url = getPhenotypeSearchURL(searchTerm, currentPage);
      axios
        .get(url)
        .then(({ data }) => {
          if (isError) setIsError(false);

          const { terms = [], totalCount } = data || {};

          const newOptions =
            terms.map((d) => ({
              id: d.id,
              name: d.name,
              definition: d.definition,
              comment: d.comment,
              synonyms: d.synonyms
            })) || [];

          if (!currentPage) {
            setOptions([...newOptions]);
          } else {
            setOptions((preOption) => [...preOption, ...newOptions]);
          }

          setTotalCount(totalCount);
          setPage(currentPage);
        })
        .catch((err) => {
          setIsError(true);
          console.log('ERRRR-', err);
        });
    } else {
      setOptions([]);
    }
  };

  const getPhenotypes = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    setFetchedPhenotypes([]); // Reset phenotypes
    axios
      .get(
        APICONSTANTS.getPhenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      )
      .then(({ data }) => {
        const { phenotypes = [] } = data || {};
        setFetchedPhenotypes(phenotypes);
      })
      .catch((err) => {
        console.log('ERRRR-', err);
      });
  };

  useEffect(() => {
    fetchDataByHPOTerm(PHENOTYPES_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    getPhenotypes();
    // eslint-disable-next-line
  }, [familyMemberId, proBandId]);

  const handleSelect = (event, value) => {
    if (value) {
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setSelectedItems([...uniqueData]);
      setSearchTerm('');
    }
  };

  const handleTileClick = (phenotype) => {
    setSelectedPhenotype(phenotype);
    setModalOpen(true);
  };

  const handleModalSave = (updatedFields) => {
    const updatedPhenotype = { ...selectedPhenotype, ...updatedFields };
    const id = proBandId ?? familyMemberId;

    // 更新前端本地状态
    setFetchedPhenotypes((prev) =>
      prev.map((item) =>
        item.id === updatedPhenotype.id ? updatedPhenotype : item
      )
    );

    // 发送更新请求给后端
    axios
      .put(
        APICONSTANTS.updatePhenotype
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          phenotype: updatedPhenotype,
          isProband: Boolean(proBandId)
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSnack({
            status: true,
            msg: 'Phenotype updated successfully!',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: response.data.message || 'Error updating phenotype',
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        console.error('Error updating phenotype:', err);
        setSnack({
          status: true,
          msg: 'Error updating phenotype',
          severity: 'error'
        });
      })
      .finally(() => {
        setModalOpen(false);
      });
  };

  const onSave = () => {
    const id = proBandId ?? familyMemberId;

    if (!id || !user_id) {
      return setSnack({
        status: true,
        msg: 'Please save the pedigree first',
        severity: 'error'
      });
    }

    if (selectedItems.length === 0) {
      return setSnack({
        status: true,
        msg: 'No phenotypes selected. Please add at least one phenotype to proceed.',
        severity: 'error'
      });
    }

    setLoading(true);

    const selectedPhenotypes = selectedItems.map((item) => ({
      id: item.id,
      name: item.name
    }));

    const payload = {
      familyId: user.familyId,
      familyMemberId: id,
      user_id: user_id,
      isProband: proBandId ? true : false,
      phenotypes: selectedPhenotypes
    };

    axios
      .post(APICONSTANTS.phenotypes, payload)
      .then((res) => {
        if (res.data?.success) {
          const updatedDeepCopy = deepCopy.map((elem) => {
            if (elem.pid === data.data.pid) {
              return {
                ...elem,
                phenotypes: res.data.phenotypes
              };
            }
            return elem;
          });

          setDeepCopy((deepCopy) => {
            return JSON.parse(JSON.stringify(updatedDeepCopy));
          });
          setDataset(updatedDeepCopy);

          setFetchedPhenotypes(res.data.phenotypes);
          dispatchUpdate({
            type: EVENTS.SET_PEDIGREE_PHENOTYPES,
            value: selectedPhenotypes
          });
          setSnack({
            status: true,
            msg: 'Phenotypes saved successfully',
            severity: 'success'
          });
          setSearchTerm('');
          setSelectedItems([]);
        } else {
          setSnack({
            status: true,
            msg: res.data.message || 'Error saving phenotypes',
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: err.response?.data?.error || 'An error occurred!',
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      options.length < totalCount
    ) {
      let newCurrentPage = page + 1;
      fetchDataByHPOTerm(newCurrentPage);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {isError && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                'The HPO service is unreachable and may be offline. Please also check your Internet connection. You will not be able to search HPO until the connection is restored.'
              )}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={selectedItems}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{ onScroll: handleScroll }}
            filterOptions={(options, state) => options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Phenotypes"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            endIcon={loading ? <CircularProgress size={15} /> : <></>}
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>

          <IconButton
            sx={{ marginLeft: '10px' }}
            onClick={() =>
              modificationHistoryHandle(CONSTANTS.auditLogType['phenotypes'])
            }
          >
            <HistoryIcon />
          </IconButton>
        </Grid>

        {fetchedPhenotypes.map((item) => (
          <Grid key={item.id} item xs={12}>
            <div
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
              onClick={() => handleTileClick(item)}
            >
              <Typography variant="subtitle1">{item.name}</Typography>
              <Typography variant="caption">{item.id}</Typography>
            </div>
          </Grid>
        ))}

        {selectedPhenotype && (
          <PhenotypeModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            phenotype={selectedPhenotype}
            onSave={handleModalSave}
            user={user}
          />
        )}
      </Grid>
    </>
  );
};

export default PhenotypesMenu;
