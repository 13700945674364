import React from 'react';

import { APICONSTANTS, CONSTANTS } from '../Constants';
import { CONSTANTS as c } from 'src/components/questionnaire/Constants';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import axios from 'axios';
import { AppContext, ThemeContext, SnackContext } from 'src/store/ContextStore';
import * as d3 from 'd3';
import { replacer } from './pedigree-sidebar/util';
import { EVENTS } from 'src/components/admin/appReducer';

export default function PhenotypesModal({
  deepCopy,
  setDeepCopy,
  setDataset,
  phenotypes,
  setPedigreeState
}) {
  const { setSnack } = React.useContext(SnackContext);

  const selectRadius = 250;
  const { theme } = React.useContext(ThemeContext);

  const { content, dispatchUpdate } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  let draggableData = [];

  let hold = React.useRef(deepCopy);
  let chosen = React.useRef(-1);

  const deselectAllOutlines = () => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return '.11em';
      })
      .style('stroke', 'black');
  };

  const selectOutline = (index) => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return i === index ? '.25em' : '.11em';
      })
      .style('stroke', function (d, i) {
        return i === index ? theme.accent : 'black';
      });
  };

  const updateData = (dataset) => {
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset, replacer));
    });
    setDataset(dataset);
  };

  let user_id = React.useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  const getNodeFromIndex = (index) => {
    let res = {};
    d3.selectAll('.te').datum((d, i) => {
      if (i == index) {
        res = d?.data;
        if (!res) res = d;
      }
    });
    return res;
  };

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (index, phenotype, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const node = getNodeFromIndex(index);
    const id = node.id || node.currentId;
    const proBandId = node.proBandId;
    const familyMemberId = proBandId ? proBandId : node.familyMemberId;

    if (!id || !user_id)
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });

    const selectedItems = [phenotype];

    if (selectedItems?.length === 0) {
      return setSnack({
        status: true,
        msg: 'No phenotypes selected. Please add at least one phenotype to proceed.',
        severity: 'error'
      });
    }

    setLoading(true);

    const updatedPhenotypes = selectedItems.map((item) => ({
      id: item.id,
      name: item.name
    }));

    const payload = {
      familyId: user.familyId,
      familyMemberId,
      user_id,
      isProband: proBandId ? true : false,
      phenotypes: updatedPhenotypes
    };

    axios
      .post(`/api/${APICONSTANTS.phenotypes}`, payload)
      .then((res) => {
        if (res.data?.success) {
          dispatchUpdate({
            type: EVENTS.SET_PEDIGREE_PHENOTYPES,
            value: res.data.phenotypes
          });

          const deepCopyFromRef = hold.current;
          const new_dataset = deepCopyFromRef.map((elem) => {
            if (elem.pid === node.pid) {
              return {
                ...elem,
                phenotypes: res.data.phenotypes
              };
            }
            return elem;
          });

          updateData(new_dataset);

          setSnack({
            status: true,
            msg: 'Phenotypes saved successfully',
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response?.data?.error ?? 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));

    deselectAllOutlines();
  };

  const distance = (point1, point2) => {
    return Math.sqrt(
      Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
    );
  };

  function dragstarted(d) {
    d3.select(this).style('opacity', 1);
  }

  function dragged(d) {
    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    deselectAllOutlines();
    if (choice.dist < selectRadius) {
      selectOutline(choice.index);
    }

    d3.select(this)
      .datum(function (a) {
        return {
          id: d.subject.id,
          phenotype: d.subject.phenotype,
          x: d.x - 10,
          y: d.y + 5
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`);
  }

  function dragended(d) {
    var dragged = d3.select(this);

    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    dragged
      .datum(function (a) {
        return {
          id: d.subject.id,
          phenotype: d.subject.phenotype,
          x: 0,
          y: draggableData[d.subject.id].y
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .style('opacity', 0);
    if (choice.dist < selectRadius) {
      if (
        d.subject.phenotype !== 'other' &&
        !c.pregnanciesDiagnoses.includes(d.subject.phenotype)
      ) {
        chosen.current = choice.index;
        handleSubmit(choice.index, d.subject.phenotype);
      } else {
        deselectAllOutlines();
        handleSubmit(choice.index, d.subject.phenotype);
      }
    }
  }
  const t = useAutoTranslation();

  const getPhenotypes = (data) => {
    const { name, id } = data?.phenotype || {};

    return t(name) + ' ' + id;
  };

  const lineWidth = 30;
  React.useEffect(() => {
    var phenotypeModal = d3.select('.phenotypeModal');
    let svg = phenotypeModal.select('svg');
    svg.selectAll('.dragon').remove();

    let tally = 0;
    phenotypes.map((el, i) => {
      const height = Math.ceil(phenotypes[i].name.length / lineWidth);
      draggableData.push({
        id: i,
        x: -10,
        y: 15 * i + 15 * tally + (height - 1) * 5,
        phenotype: phenotypes[i],
        width: 300
      });
      tally += Math.ceil(phenotypes[i].name.length / 28);
    });

    let text = svg
      .selectAll('.dragon')
      .data(draggableData)
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('opacity', 0)
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

    text
      .append('rect')
      .attr('z-index', 999999)
      .attr('height', 30)
      .attr('width', 300)
      .attr('rx', 15)
      .style('border', 'grey 1px solid')
      .attr('fill', (d) => theme.appearance.text)
      .raise();

    text
      .append('text')
      .text((d) => getPhenotypes(d))
      .attr('y', 20)
      .attr('x', 25)
      .attr('font-family', `"Roboto", "Helvetica", "Arial", sans-serif`)
      .style('font-size', '1rem')
      .style('fill', (d) => theme.appearance.color);

    text.each(function (e, i) {
      var bbox = d3.select(this).select('text').node().getBBox();
      var width = bbox.width;
      d3.select(this)
        .select('rect')
        .attr('width', width + 50);
    });
  }, [phenotypes]);

  React.useEffect(() => {
    if (deepCopy) hold.current = deepCopy;
  });

  return (
    <div className="phenotypeModal" style={{ position: 'absolute' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
        overflow="visible"
      />
    </div>
  );
}
