import React from 'react';
import Box from '@mui/material/Box';
import MaterialTable from 'material-table';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeContext } from 'src/store/ContextStore';

function getBoxRows(rowData, boxKeys, detailKey) {
  if (boxKeys) {
    let data = {};
    for (const key in rowData) {
      // if the key is not included in removals and value exists for it
      if (boxKeys.includes(key)) {
        data[key] = rowData[key];
      }
    }
    return [data];
  } else return rowData.boxRows || rowData[detailKey];
}

const useStyles = makeStyles({
  boxPanel: {
    backgroundColor: (props) => props.bgColor || 'red'
  }
});

export default function DetailPanel({
  columns,
  boxKeys,
  getRowColor,
  onRowClick,
  rowData,
  detailKey
}) {
  const { theme } = React.useContext(ThemeContext);
  // const classes = useStyles({ bgColor });
  const classes = useStyles({ bgColor: theme.appearance.color });

  React.useEffect(() => {
    // if there are boxKeys - no need for on row click
    if (!boxKeys) onRowClick(rowData);
  }, []);

  const rows = getBoxRows(rowData, boxKeys, detailKey);

  return (
    <Box className={classes.boxPanel} padding={1} textAlign="center">
      {rows && rows.length > 0 ? (
        <MaterialTable
          columns={columns}
          data={rows}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />
          }}
          options={{
            padding: 'dense',
            search: false,
            toolbar: false,
            paging: false,
            rowStyle: (rowData) => ({
              backgroundColor: getRowColor(rowData)
            }),
            headerStyle: {
              fontSize: '0.9rem',
              backgroundColor: theme.accent + '55',
              color: '#000',
              fontWeight: 'bold'
            }
          }}
        />
      ) : (
        'No records to display'
      )}
    </Box>
  );
}
