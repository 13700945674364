//this file is Dashboard.jsx

import React, { Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminLogin from 'src/components/dashboard/Adminlogin';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import { CONSTANTS as Q_CONSTANTS } from 'src/components/questionnaire/Constants';
import {
  basePath as customFormBasePath,
  CONSTANTS as CUSTOMFORM_CONSTANTS
} from 'src/components/custom-form/Constants';
import BackdropLoader from 'src/components/UI/loader/BackdropLoader';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import CustomForm from 'src/components/custom-form/CustomForm';
import PatientPanel from '../patient/PatientPanel';
import StudentRegister from './StudentRegister';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material';
import axios from 'src/axios';
import { TourProvider } from 'src/components/tour/TourContext';
import { green } from '@mui/material/colors';

// lazy loading for app and admin modules
const AdminPanel = React.lazy(() => import('src/components/admin/AdminPanel'));
const Questionnaire = React.lazy(() =>
  import('src/components/questionnaire/Questionnaire')
);
const GenerateLink = React.lazy(() =>
  import('src/components/questionnaire/generate-link/GenerateLink')
);
const CustomFormGenerateLink = React.lazy(() =>
  import('src/components/custom-form/generate-link/GenerateLink')
);
const ResetPassword = React.lazy(() =>
  import('src/components/dashboard/ResetPassword')
);
const ErrorPage = React.lazy(() =>
  import('src/components/dashboard/ErrorPage')
);

const Dashboard = () => {
  const [user, setUser] = React.useState(null);

  // const clearSession = async () => {
  //   //await axios.get(APICONSTANTS.logout);
  //   history.push(CONSTANTS.signinPath);
  //   setUser(null);
  //   localStorage.setItem('2FAloggedin', 'false');
  // };

  // useEffect(() => {
  //   const isUser2FAloggedin = localStorage.getItem('2FAloggedin');

  //   if (isUser2FAloggedin !== 'true') {
  //     clearSession();
  //   }
  // }, []);

  const userProviderValue = React.useMemo(
    () => ({ user, setUser }),
    [user, setUser]
  );

  const [theme, setTheme] = useState(CONSTANTS.customTheme);

  React.useMemo(() => {
    //TODO: optimize the codc
    user &&
      axios
        .get(APICONSTANTS.getCustomTheme)
        .then((res) => {
          if (res.data) setTheme(res.data);
        })
        .catch((/*err*/) => {
          // setTheme(CONSTANTS.customTheme);
        });
  }, [user]);

  if (window.location.search) {
    localStorage.setItem('code', window.location.search);
  }

  const muiCustomTheme = React.useMemo(
    () =>
      createTheme({
        dateFormat: 'MM/DD/YYYY',
        palette: {
          mode: theme.appearance.value === 'light' ? 'light' : 'dark',
          secondary: {
            main: theme.buttonStyle ?? '#f50057',
            contrastText: theme.buttonText ?? '#FFFFFF'
          },
          primary: {
            main: theme.buttonStyle ?? '#42a5f5',
            contrastText: theme.buttonText ?? '#FFFFFF'
          }
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: theme.highlighter ?? green[500]
                  }
              }
            }
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.highlighter ?? green[500]
                }
              }
            }
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                backgroundColor: theme.appearance?.color ?? '#ffffff'
              }
            }
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                backgroundColor: `${theme.buttonStyle ?? '#42a5f5'} !important`,
                color: `${theme.buttonText ?? '#000'} !important`
              }
            }
          },
          MuiTab: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: `${
                    theme.accent + '1a' ?? '#ffffff'
                  } !important`
                }
              }
            }
          }
        }
      }),
    [theme]
  );

  React.useMemo(() => {
    document.body.id = theme.appearance.value;
  }, [theme]);

  return (
    <UserContext.Provider value={userProviderValue}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiCustomTheme}>
            <TourProvider>
              <Switch>
                {/* Custom form generate link */}
                <Route path={CUSTOMFORM_CONSTANTS.generateLinkPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <CustomFormGenerateLink />
                  </Suspense>
                </Route>

                <Route path={Q_CONSTANTS.generateLinkPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <GenerateLink />
                  </Suspense>
                </Route>
                <Route path={Q_CONSTANTS.formPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <Questionnaire />
                  </Suspense>
                </Route>
                <Route path={customFormBasePath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <CustomForm />
                  </Suspense>
                </Route>

                <Route path={CONSTANTS.signinPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <AdminLogin />
                  </Suspense>
                </Route>
                <Route path={CONSTANTS.resetPasswordPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <AdminLogin />
                  </Suspense>
                </Route>

                <Route path={CONSTANTS.studentRegister}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <StudentRegister />
                  </Suspense>
                </Route>

                <Route path={CONSTANTS.resetPasswordLinkPath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <ResetPassword />
                  </Suspense>
                </Route>

                <Route path={CONSTANTS.errorPagePath}>
                  <Suspense fallback={<BackdropLoader loading={true} />}>
                    <ErrorPage />
                  </Suspense>
                </Route>

                {user?.userType === CONSTANTS.userTypes.PATIENT ? (
                  <Route path={CONSTANTS.patientPath}>
                    <Suspense fallback={<BackdropLoader loading={true} />}>
                      <PatientPanel />
                    </Suspense>
                  </Route>
                ) : (
                  <Route path={CONSTANTS.adminPath}>
                    <Suspense fallback={<BackdropLoader loading={true} />}>
                      <AdminPanel />
                    </Suspense>
                  </Route>
                )}

                <Redirect
                  to={
                    user?.userType === CONSTANTS.userTypes.PATIENT
                      ? CONSTANTS.patientPath
                      : CONSTANTS.adminPath
                  }
                />
              </Switch>
            </TourProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
    </UserContext.Provider>
  );
};

export default Dashboard;
