import { Alert, Box, Button } from '@mui/material';
import React from 'react';
import { CONSTANTS } from 'src/components/Constants';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import TextInput from 'src/components/UI/input/TextInput';

function TextModal({
  node,
  deepCopy,
  setDeepCopy,
  setDataset,
  handleDetailsChange,
  handleClose,
  menu,
  editText
}) {
  const [comment, setComment] = React.useState(
    (editText ? node?.data?.editText : node?.data?.personNote) ?? ''
  );

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();

      inputRef.current.setSelectionRange(length, length);
    }
  }, [node]);

  const onSave = () => {
    const { data } = node;

    const idx = deepCopy.findIndex((person) => data.pid === person.pid);

    editText
      ? (deepCopy[idx] = {
          ...deepCopy[idx],
          editText: String(comment).slice(0, 4).toUpperCase()
        })
      : (deepCopy[idx] = {
          ...deepCopy[idx],
          personNote: comment
        });

    handleDetailsChange([deepCopy[idx]]);

    setDeepCopy((deepCopy) => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);

    handleClose();
  };

  const onEditChange = (e) => {
    setComment(e.target.value);
  };

  const handleClear = () => {
    setComment('');
  };

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {editText && (
          <Alert severity="info">
            Only the first 4 characters will be displayed inside the icon.
          </Alert>
        )}
        <div className="user-form">
          <FormLabel component="label" className="form-label">
            {editText ? 'Edit Text' : 'Person Note'}
          </FormLabel>
        </div>
        <TextInput
          type={editText ? 'text' : 'textarea'}
          rows={8}
          value={comment}
          onChange={(e) =>
            editText ? onEditChange(e) : setComment(e.target.value)
          }
          placeholder={''}
          ref={inputRef}
          autoFocus
        />
        <Box display={'flex'} gap={2} justifyContent={'end'}>
          {menu && (
            <Button size="small" variant="outlined" onClick={handleClear}>
              clear
            </Button>
          )}

          {menu && (
            <Button size="small" variant="outlined" onClick={handleClose}>
              cancel
            </Button>
          )}
          <Button size="small" variant="contained" onClick={onSave}>
            save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default TextModal;
