import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  CardActionArea,
  Collapse,
  Box
} from '@mui/material';
import axios from 'axios';
import { APICONSTANTS } from 'src/components/Constants';
import PushPinIcon from '@mui/icons-material/PushPin';

import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';

import { ThemeContext } from 'src/store/ContextStore';
import { useContext } from 'react';

const TileCard = ({
  model,
  type,
  setIsEditing,
  setModalOpen,
  setNoteType,
  setCurrentModel,
  deepCopy,
  staffMemberOptions,
  showInFamily,
  highlightedPerson,
  setIsDisabled
}) => {
  const [body] = React.useState({
    ...model,
    _id: '',
    createdAt: '',
    pinned: '',
    caution: '',
    noteType: ''
  });

  const [fileLinks, setFileLinks] = React.useState({});

  const [expanded, setExpanded] = React.useState(false);

  const colours = {
    Referral: '#4d9fb3',
    Appointment: '#53b382',
    Test: '#354051',
    Sample: '#de2054',
    Address: '#9b9b9b',
    'Clinical Note': '#f7b661',
    Contact: '#f6d956',
    Consent: '#14859a'
  };

  const { theme } = useContext(ThemeContext);

  const handleExpandClick = () => {
    setIsEditing(true);
    setCurrentModel(model);
    setNoteType(type);
    setModalOpen(true);

    if (
      highlightedPerson?.familyMemberId !== model.familyMemberId &&
      !!setIsDisabled
    )
      setIsDisabled(true);
  };

  const handleClick = (e, fileLink) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(fileLink, '_blank');
  };

  React.useEffect(() => {
    if (body.fileAttachments) {
      const fetchData = async () => {
        let links = {};
        for (let x in body.fileAttachments) {
          const response = await axios.get(
            `/api/${APICONSTANTS.fileAttachment}`,
            {
              params: {
                fileName: body.fileAttachments[x]
              }
            }
          );
          links[body.fileAttachments[x]] = response.data.fileURL;
        }
        setFileLinks(links);
      };
      fetchData();
    }
  }, []);

  const isDate = (date) => {
    return !isNaN(Date.parse(date)) && Date.parse(date) > 0;
  };

  //Not showing the tilecard contents anymore, but the code is here just in case
  const renderField = (key) => {
    //let type = typeof body[key];
    //
    //if (isDate(body[key])) {
    //  if (key === 'appointmentTime')
    //    return <>{moment(body[key]).format('hh:mm')}</>;
    //  return <>{moment(body[key]).format(theme.dateFormat)}</>;
    //}
    //switch (type) {
    //  case 'object':
    //    return (
    //      <>
    //        {body[key] &&
    //          body[key].map((e, i) => (
    //            <Link
    //              key={i}
    //              href={fileLinks[e]}
    //              value={fileLinks[e]}
    //              onClick={(event) => {
    //                handleClick(event, fileLinks[e]);
    //              }}
    //            >
    //              <br />
    //              {e}
    //            </Link>
    //          ))}
    //      </>
    //    );
    //  case 'boolean':
    //    return <>{body[key] ? 'True' : 'False'}</>;
    //  case 'string':
    //    return (
    //      <>
    //        {body[key]}
    //        <Grid container>
    //          {key == 'trackingId' && (
    //            <Grid item xs={3} paddingTop="1.1rem">
    //              <QRCode value={body.trackingId} size={60} />
    //            </Grid>
    //          )}
    //          {key == 'trackingId' && (
    //            <Grid item xs={9} paddingTop="0.5rem">
    //              <Barcode
    //                value={body.trackingId}
    //                fontSize="0"
    //                height="60"
    //                width="2"
    //              />
    //            </Grid>
    //          )}
    //        </Grid>
    //      </>
    //    );
    //  default:
    //    return <>{body[key]}</>;
    //}
    return <>What</>;
  };

  // const SelectField = () => {
  //   switch (model.noteType) {
  //     case 'Referral':
  //       return model.referralDate;
  //     case 'Appointment':
  //       return model.appointmentDate;
  //     case 'Test':
  //       return model.dateRequested;
  //     case 'Sample':
  //       return model.dateTaken;
  //     case 'Address':
  //       return model.primaryAddress;
  //     case 'Clinical Note':
  //       return model.clinicalNoteDate;
  //     case 'Contact':
  //       return model.contactDate;
  //     case 'Consent':
  //       return model.consentDate || model.consentRequestDate;
  //     default:
  //       return model.createdAt;
  //   }
  // };

  // const getLabel = () => {
  //   const field = SelectField();
  //   if (type == 'Address') return field;
  //   return moment(field).format(theme.dateFormat);
  // };

  const getLabel = () => {
    switch (model.noteType) {
      case 'Clinical Note': {
        return (
          <>
            <div>
              Date: {moment(model.clinicalNoteDate).format(theme.dateFormat)}
            </div>
            {model.clinician && (
              <div>
                Staff Member:
                {model.clinician}
              </div>
            )}
          </>
        );
      }

      case 'Address': {
        const primaryFlag = model.primaryAddress ? ' P' : '';
        const correspondenceFlag = model.correspondenceAddress ? ' C' : '';
        return (
          <>
            {model.addressLine1 && (
              <div>
                Address Line 1: {model.addressLine1}
                {primaryFlag} {correspondenceFlag}
              </div>
            )}
            <div>
              {(model.town || model.city) && (
                <span>Town/City: {model.town || model.city}</span>
              )}
              {model.postcode && (
                <span>
                  {model.town || model.city ? ', ' : ''}Postcode:{' '}
                  {model.postcode}
                </span>
              )}
            </div>
          </>
        );
      }

      case 'Appointment': {
        return (
          <>
            <div>
              {model.appointmentDate && (
                <span>
                  Date: {moment(model.appointmentDate).format(theme.dateFormat)}
                </span>
              )}
              {model.clinicName && (
                <span>
                  {model.appointmentDate ? ', ' : ''}Clinic Name:{' '}
                  {model.clinicName}
                </span>
              )}
            </div>
            <div>
              {model.staffMember && (
                <span>
                  Staff Member:{' '}
                  {
                    staffMemberOptions.find(
                      (staff) => staff.value === model.staffMember[0]
                    )?.label
                  }
                </span>
              )}
              {model.appointmentStatus && (
                <span>
                  {model.staffMember ? ', ' : ''}Appt Status:{' '}
                  {model.appointmentStatus}
                </span>
              )}
            </div>
          </>
        );
      }

      case 'Consent': {
        const dateValue = model.consentDate || model.consentRequestDate;
        return (
          <>
            <div>
              {dateValue && (
                <span>Date: {moment(dateValue).format(theme.dateFormat)}</span>
              )}
              {model.response && (
                <span>
                  {dateValue ? '; ' : ''}Consent Response: {model.response}
                </span>
              )}
            </div>
            {model.consentType && <div>Consent Type: {model.consentType}</div>}
          </>
        );
      }

      case 'Contact': {
        return (
          <>
            <div>
              {model.contactDate && (
                <span>
                  Date: {moment(model.contactDate).format(theme.dateFormat)}
                </span>
              )}
              {model.contactType && (
                <span>
                  {model.contactDate ? ', ' : ''}Contact Type:{' '}
                  {model.contactType}
                </span>
              )}
            </div>
            {model.staffMember && (
              <div>
                Staff Member:
                {
                  staffMemberOptions.find(
                    (staff) => staff.value === model.staffMember[0]
                  )?.label
                }
              </div>
            )}
          </>
        );
      }

      case 'Referral': {
        return (
          <>
            <div>
              {model.referralDate && (
                <span>
                  Date: {moment(model.referralDate).format(theme.dateFormat)}
                </span>
              )}
              {model.referralType && (
                <span>
                  {model.referralDate ? ', ' : ''}Referral Type:{' '}
                  {model.referralType}
                </span>
              )}
            </div>
            {model.referredBy && <div>Referral By: {model.referredBy}</div>}
          </>
        );
      }

      case 'Sample': {
        const statusText =
          !model.sampleStatus && !model.statusDate
            ? 'Sample Status is pending'
            : '';
        return (
          <>
            <div>
              {model.dateTaken && (
                <span>
                  Date: {moment(model.dateTaken).format(theme.dateFormat)}
                </span>
              )}
              {model.laboratory && (
                <span>
                  {model.dateTaken ? ', ' : ''}Laboratory: {model.laboratory}
                </span>
              )}
            </div>
            <div>
              {model.sampleStatus && (
                <span>Sample Status: {model.sampleStatus}</span>
              )}
              {model.statusDate && (
                <span>
                  {model.sampleStatus ? ', ' : ''}Status Date:{' '}
                  {moment(model.statusDate).format(theme.dateFormat)}
                </span>
              )}
            </div>
            {statusText && (
              <div style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                {statusText}
              </div>
            )}
          </>
        );
      }

      case 'Test': {
        const resultText =
          !model.testResult && !model.dateResultReceived
            ? 'Test Result is pending'
            : '';
        return (
          <>
            <div>
              {model.dateRequested && (
                <span>
                  Date Requested:{' '}
                  {moment(model.dateRequested).format(theme.dateFormat)}
                </span>
              )}
              {model.testName && (
                <span>
                  {model.dateRequested ? ', ' : ''}Test Name: {model.testName}
                </span>
              )}
            </div>
            <div>
              {model.testResult && <span>Test Result: {model.testResult}</span>}
              {model.dateResultReceived && (
                <span>
                  {model.testResult ? ', ' : ''}
                  Date Result Received:{' '}
                  {moment(model.dateResultReceived).format(theme.dateFormat)}
                </span>
              )}
            </div>
            {resultText && (
              <div style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                {resultText}
              </div>
            )}
          </>
        );
      }

      default: {
        return model.createdAt
          ? moment(model.createdAt).format(theme.dateFormat)
          : '';
      }
    }
  };

  const personSummary = () => {
    const person = deepCopy.find(
      (data) =>
        data.familyMemberId === model.familyMemberId ||
        data.proBandId === model.familyMemberId
    );

    if (!person) return '';

    const { firstName, lastName, upn } = person;
    const fullName = [firstName, lastName].filter(Boolean).join(' ');

    return `${fullName}${fullName ? ', ' : ''}UPN ${
      person.proBandId ? '1' : upn
    }`;
  };

  return (
    <Card>
      <CardActionArea onClick={handleExpandClick}>
        <CardContent>
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="caption" component="div">
                {getLabel()}
              </Typography>
              {showInFamily && (
                <Typography
                  variant="caption"
                  component="div"
                  fontStyle={
                    highlightedPerson?.familyMemberId === model?.familyMemberId
                      ? 'italic'
                      : 'normal'
                  }
                >
                  {highlightedPerson?.familyMemberId === model?.familyMemberId
                    ? 'Current person'
                    : personSummary()}
                </Typography>
              )}
            </Box>
            <Box>
              <Chip
                label={
                  <>
                    {type}
                    {model.caution && <StarIcon fontSize="sm" />}
                    {model.pinned && <PushPinIcon fontSize="sm" />}
                  </>
                }
                variant="fill"
                size="small"
                style={{
                  backgroundColor: colours[model.noteType],
                  color: 'white'
                }}
              />
            </Box>
          </Box>

          <Collapse in={expanded}>
            {Object.keys(body).map((key, index) => (
              <>
                {body[key] != '' && body[key] != null && (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{ borderBottom: '1px solid #ccc', py: 1 }}
                  >
                    <span style={{ fontWeight: 'bold' }}>{key} </span>
                    {renderField(key)}
                  </Typography>
                )}
              </>
            ))}
          </Collapse>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TileCard;
