import React, { useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from 'ckeditor5';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';

import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  SnackContext,
  ThemeContext,
  AppContext,
  UserContext
} from 'src/store/ContextStore';
import DateInput from '../../../UI/input/DateInput';

import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import TextInput from 'src/components/UI/input/TextInput';
import AssociatedNote from 'src/components/UI/input/AssociatedNote';
import {
  getReferralTags,
  handleUpload,
  getDependencies,
  CONTACT_TYPES
} from './formService';

import UploadFileList from 'src/components/UI/input/UploadFileList';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import SelectInput from 'src/components/UI/input/SelectInput';
import {
  ExportToPdf,
  ExportToDocs,
  MergeFieldDropdown,
  TemplateDropdown,
  MergeFieldsPreviewDropdown
} from 'src/plugins/ckeditor/CKEditorSetup.js';
import {
  baseConfig,
  basicPlugins,
  toolbar
} from 'src/components/settings/templates/constants.js';
import {
  exportToPDF,
  exportToDocs
} from 'src/components/settings/templates/util.js';
import 'ckeditor5/ckeditor5.css';
import { mergeFieldsOptions } from 'src/components/settings/templates/constants';

const getTitle = (gender) => {
  if (gender?.toLowerCase() === 'male') return 'Mr.';
  if (gender?.toLowerCase() === 'female') return 'Mrs.';
  return 'Unknown';
};

const getMergeFieldsDefaults = (mergeFieldsOptions, data, user, members) => {
  return mergeFieldsOptions.reduce((acc, field) => {
    if (field.id === 'title') {
      acc[field.id] = getTitle(data?.data?.gender) || `{{${field.label}}}`;
    } else if (field.id === 'familyId') {
      acc[field.id] = user[field.id] || `{{${field.label}}}`;
    } else if (field.id === 'staffMember') {
      acc[field.id] =
        members?.length > 0 ? members.join(', ') : `{{${field.label}}}`;
    } else {
      acc[field.id] = data?.data[field.id] || `{{${field.label}}}`;
    }
    return acc;
  }, {});
};

export default function ContactForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions,
  data,
  isDisabled
}) {
  const editorRef = useRef(null);
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { user: loggedInUser } = React.useContext(UserContext);

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        contactMode: '',
        contactType: '', // (Family History, Follow Up, etc. + Allow custom values)
        contactDate: '',
        contactStatus: 'Scheduled', // Scheduled, Sent / Completed, Did Not Attend, Cancelled
        durationMinutes: 0, // Numeric field for duration in minutes
        staffMember: [loggedInUser._id], // Pre-populated with logged-in user
        fileAttachments: [], // Array to store file objects (e.g., { name, size, type, url })
        contactNotes: '',
        showInFamily: false,
        assignedDate: new Date(), // Renamed from "Date Requested for Checking"
        dateApproved: null // Changed to `null` to indicate it's pending approval
      };
  const { theme } = useContext(ThemeContext);
  let [referralOptions, setReferralOptions] = React.useState(['loading...']);
  const [formValues, setFormValues] = React.useState(defaultValues);

  const { setSnack } = React.useContext(SnackContext);

  const allStaffMembers = React.useMemo(() => {
    return staffMemberOptions.map((options) => options.value);
  }, [staffMemberOptions]);

  const staffMembersMap = React.useMemo(() => {
    return staffMemberOptions.reduce((acc, item) => {
      acc[item.value] = item.label;
      return acc;
    }, {});
  }, [staffMemberOptions]);

  const members = React.useMemo(() => {
    return formValues.staffMember?.map((member) => staffMembersMap[member]);
  }, [formValues.staffMember, staffMembersMap]);

  const mergeFieldsDefaults = getMergeFieldsDefaults(
    mergeFieldsOptions,
    data,
    user,
    members
  );

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);

      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Make this a controller?
  React.useEffect(() => {
    const fetchData = async () => {
      const referralTags = await getReferralTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setReferralOptions(referralTags);
    };
    if (referralOptions != [] && referralOptions[0] == 'loading...')
      fetchData();
  });

  const [contactTypeOptions] = React.useState([
    'Follow Up',
    'Pre-Clinic',
    'Consultation'
  ]);

  const [contactModeOptions] = React.useState([
    'Letter',
    'Email',
    'Telephone',
    'Video Call'
  ]);
  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormValues((prev) => ({ ...prev, contactNotes: data }));
  };

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };
  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
  };

  const [error, setError] = React.useState({});

  const validateForm = (values) => {
    let errors = {};

    if (values.contactType === '') {
      errors.contactType = 'Contact Type is required.';
    }

    if (!values.contactDate) {
      errors.contactDate = 'Contact Date is required.';
    }
    setError(errors);
    return errors;
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const errors = validateForm(formValues);

    if (Object.keys(errors).length > 0) return Promise.reject();

    let requestChecking = null;
    let staffMember = null;

    if (formValues.requestChecking) {
      requestChecking = staffMembers.find(
        (member) => member.userId === formValues.requestChecking
      );
    }

    if (formValues.staffMember && formValues.staffMember.length > 0) {
      staffMember = formValues.staffMember.map((staff) => {
        let existStaffMember = staffMembers.find(
          (member) => member.userId === staff
        );

        return {
          ...existStaffMember
        };
      });
    }

    const parsePayload = {
      ...formValues,
      staffMember: staffMember ? [...staffMember] : [],
      requestChecking: requestChecking ? [requestChecking] : [],
      assignedDate:
        requestChecking && formValues.assignedDate
          ? formValues.assignedDate
          : null,
      dateApproved:
        formValues.approved && formValues.dateApproved
          ? formValues.dateApproved
          : null
    };

    handleClose();
    return axios
      .put(APICONSTANTS.contact, parsePayload)
      .then((res) => {
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  const reqCheckingOptions = React.useMemo(() => {
    return staffMemberOptions.filter(
      (member) => !formValues.staffMember?.includes(member.value)
    );
  }, [staffMemberOptions, formValues]);

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Contact ?"
        content="Warning: This action will permanently delete this Contact. Once deleted, this
      information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextInput
              label={'Contact Type'}
              variant="outlined"
              fullWidth
              type={'autocomplete'}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  contactType: label
                });
              }}
              options={CONTACT_TYPES}
              name="contactType"
              value={formValues.contactType}
              multiline={true}
              required
              validated={!!error.contactType}
              helperText={error.contactType}
              readOnly={isDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Contact Mode"
              type="autocomplete"
              name="contactMode"
              value={formValues.contactMode || ''}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  contactMode: label
                });
              }}
              options={contactModeOptions || []}
              helperText="Please select or enter a user group"
              readOnly={isDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedReferral"
            handleChange={handleChange}
            value={formValues.associatedReferral}
            noteOptions={referralOptions}
            readOnly={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel required>Contact Date</FormLabel>
          </Grid>
          <DateInput
            name="contactDate"
            value={
              formValues.contactDate ? new Date(formValues.contactDate) : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                contactDate: e
              }))
            }
            variant="outline"
            minDate={new Date(1600, 0, 0)}
            liveText={true}
            error={!!error?.contactDate}
            helperText={error?.contactDate}
            readOnly={isDisabled}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Duration (minutes) "
            name="contactTime"
            value={formValues.contactTime || ''}
            onChange={handleChange}
            InputProps={{ readOnly: isDisabled }}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="contactStatus"
                value={formValues.contactStatus}
                onChange={handleChange}
                readOnly={isDisabled}
              >
                {[
                  'Scheduled',
                  'Sent / Completed',
                  'Did Not Attend',
                  'Cancelled'
                ].map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* 
      // REMOVE as per ticket [TGC-767]
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
          <TextInput
            label="Contact Type"
            type="autocomplete"
            name="contactType"
            value={formValues.contactType || ''}
            onChange={(value) => {
              setFormValues({
                ...formValues,
                contactType: value
              });
            }}
            options={contactTypeOptions || []}
            helperText="Please select or enter a contact type"
          />
        </Box>
      </Grid> 
      <Grid item xs={12}>
        <Grid sx={{ paddingBlock: 1 }}>
          <FormLabel>Link</FormLabel>
        </Grid>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <TextField
            variant="outlined"
            fullWidth
            onChange={handleChange}
            name="link"
            value={formValues.link}
          />
        </Box>
      </Grid> */}
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <CKEditor
              editor={ClassicEditor}
              disabled={isDisabled}
              config={{
                exportToPDF,
                exportToDocs,
                plugins: [
                  ...basicPlugins,
                  ExportToPdf,
                  ExportToDocs,
                  MergeFieldDropdown,
                  TemplateDropdown,
                  MergeFieldsPreviewDropdown
                ],
                toolbar: toolbar,
                ...baseConfig,
                mergeFieldsDefaults
              }}
              onReady={(editor) => {
                editorRef.current = editor;
                editor.setData(formValues.contactNotes);
              }}
              onChange={handleEditorChange}
            />
          </Box>
        </Grid>
        <UploadFileList
          value={formValues.fileAttachments}
          familyId={familyMemberId}
          onChange={(value) =>
            setFormValues({
              ...formValues,
              fileAttachments: value
            })
          }
          readOnly={isDisabled}
        />

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                disabled={isDisabled}
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Staff Member */}
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  label={'Staff Member'}
                  name="staffMember"
                  type="multiple-select"
                  value={formValues?.staffMember || []}
                  onChange={handleChange}
                  options={allStaffMembers || []}
                  mapper={staffMembersMap || {}}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        {/* Request Checking*/}
        {formValues.staffMember && formValues.staffMember.length > 0 && (
          <Grid item xs={12} id="top">
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Assign to Staff for checking asd</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                fullWidth={true}
                variant="outlined"
                name="requestChecking"
                options={reqCheckingOptions}
                value={formValues.requestChecking || ''}
                onChange={handleChange}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        )}

        {/*Date Requested Checking*/}
        {formValues.requestChecking && (
          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Assigned Date</FormLabel>
            </Grid>
            <DateInput
              readOnly={isDisabled}
              name="assignedDate"
              value={
                formValues.assignedDate
                  ? new Date(formatDate(formValues.assignedDate))
                  : null
              }
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  assignedDate: e
                }))
              }
              variant="outline"
            />
          </Grid>
        )}

        {/*Approved*/}
        {formValues.requestChecking && formValues.assignedDate && (
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={6} sm={7}>
              <FormLabel component="label" className="form-label">
                Approved
              </FormLabel>
            </Grid>
            <Grid item container xs={6} sm={3} alignItems="center">
              <Grid item>
                <Switch
                  color="secondary"
                  checked={formValues.approved ?? false}
                  onChange={onToggleSwitch}
                  name="approved"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item>
                <Typography>{formValues.approved ? 'Yes' : 'No'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*Date Approved*/}
        {formValues.approved && (
          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Date Approved</FormLabel>
            </Grid>
            <DateInput
              name="dateApproved"
              value={
                formValues.dateApproved
                  ? new Date(formatDate(formValues.dateApproved))
                  : null
              }
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  dateApproved: e
                }))
              }
              variant="outline"
              readOnly={isDisabled}
            />
          </Grid>
        )}

        <ModalButtons
          hideSaveButton={isDisabled}
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing && !isDisabled ? handleOpenModal : null}
          handleClose={handleClose}
          addLabel={`Save New Contact`}
        />
      </Grid>
    </form>
  );
}
