import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Box,
  Typography,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Checkbox
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios'; // Your customised axios instance
import HistoryIcon from '@mui/icons-material/History';
import {
  APICONSTANTS,
  CONSTANTS,
  HPO_CONSTANTS
} from 'src/components/Constants';
import { SnackContext, AppContext } from 'src/store/ContextStore';
import { getHPOSearchURL } from 'src/util/util';
import { useAutoTranslation } from 'src/hooks/useTranslate';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * 独立组件：显示 ClinVar Variants 列表。
 * 每行左边多了一个 checkbox，用于选择/取消选择该 variant。
 * 同时按 variant.description 进行分组，并用 Accordion 实现可折叠。
 */
const ClinVarVariantsList = ({
  variants = [],
  selectedVariantIds = [],
  onToggleVariant = () => {}
}) => {
  // 1. 将 useState 放在顶部，避免“条件调用 Hook”的问题
  const [expanded, setExpanded] = useState(null);

  // 2. 接下来再判断是否有数据
  if (!variants.length) {
    return (
      <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
        No ClinVar Variants found
      </Typography>
    );
  }

  // 我们只关心这三类 description
  const groups = [
    { label: 'Pathogenic', color: 'red' },
    { label: 'Likely benign', color: 'green' },
    { label: 'Uncertain significance', color: 'inherit' }
  ];

  // 将 variants 按 description 分好类
  const groupedVariants = {
    Pathogenic: [],
    'Likely benign': [],
    'Uncertain significance': []
  };

  variants.forEach((variant) => {
    const { description } = variant;
    if (description === 'Pathogenic') {
      groupedVariants.Pathogenic.push(variant);
    } else if (description === 'Likely benign') {
      groupedVariants['Likely benign'].push(variant);
    } else if (description === 'Uncertain significance') {
      groupedVariants['Uncertain significance'].push(variant);
    }
  });

  // 手风琴展开/折叠控制
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        ClinVar Variants:
      </Typography>

      {groups.map(({ label, color }) => {
        const list = groupedVariants[label];
        // 没有对应分组数据就不渲染
        if (!list.length) return null;

        return (
          <Accordion
            key={label}
            expanded={expanded === label}
            onChange={handleChange(label)}
            sx={{ mt: 1 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold', color: color }}>
                {label} ({list.length})
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {list.map((variant) => {
                const checked = selectedVariantIds.includes(variant.id);
                return (
                  <Box
                    key={variant.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {/* 左侧复选框 */}
                    <Checkbox
                      checked={checked}
                      onChange={() => onToggleVariant(variant.id)}
                      sx={{ mr: 1 }}
                    />
                    {/* 右侧文字信息 */}
                    <Box>
                      <Typography variant="body2">{variant.title}</Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            variant.description === 'Pathogenic'
                              ? 'red'
                              : variant.description === 'Likely benign'
                              ? 'green'
                              : 'inherit'
                        }}
                      >
                        {/* {variant.description} */}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

// ------------------ Genotype Modal ------------------ //
const GenotypeModal = ({ open, onClose, genotype, onSave, user }) => {
  const [clinicallyConfirmed, setClinicallyConfirmed] = useState(false);
  const [patientReported, setPatientReported] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [displayOnPedigree, setDisplayOnPedigree] = useState(false);
  const [dateRecorded, setDateRecorded] = useState('');
  const [recordedBy, setRecordedBy] = useState('');
  const [carrierStatus, setCarrierStatus] = useState('');

  // ClinVar Variants 相关
  const [clinVarVariants, setClinVarVariants] = useState([]); // 存储从 ClinVar 获取的对象数组
  // 新增：存储用户选中（勾选）的 Variant ID 列表
  const [selectedVariantIds, setSelectedVariantIds] = useState([]);

  const { setSnack } = useContext(SnackContext);

  // 当 genotype 或 Modal 打开时，初始化相关字段
  useEffect(() => {
    if (genotype) {
      setClinicallyConfirmed(genotype.clinicallyConfirmed || false);
      setPatientReported(genotype.patientReported || false);
      setIsPrivate(genotype.isPrivate || false);
      setDisplayOnPedigree(genotype.displayOnPedigree || false);
      setDateRecorded(
        genotype.dateRecorded || new Date().toISOString().split('T')[0]
      );
      setRecordedBy(
        genotype.recordedBy || `${user.firstName} ${user.lastName}`
      );
      setCarrierStatus(genotype.carrierStatus || '');

      // 如果后端/本地存了已选的 Variant IDs，则恢复
      if (
        genotype.selectedClinVarIds &&
        Array.isArray(genotype.selectedClinVarIds)
      ) {
        setSelectedVariantIds(genotype.selectedClinVarIds);
      } else {
        setSelectedVariantIds([]);
      }
    }
  }, [genotype, user]);

  /**
   * 从 ClinVar 获取并解析数据：
   * 1. 先用基因ID在 ClinVar 里搜索到对应 variant 的 idList
   * 2. 再去获取每个 variant 的详细摘要信息，并解析成一个对象数组
   */
  const fetchClinVarData = (fullId) => {
    // 安全地截取 “NCBIGene:####” 部分
    const textBeforeSpace = fullId.split(' ')[0];
    const geneId = textBeforeSpace.replace('NCBIGene:', '');

    // Step 1: search gene ID in ClinVar
    axios
      .get('https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi', {
        params: {
          db: 'clinvar',
          term: `${geneId}[geneid]`,
          retmode: 'json'
        }
      })
      .then(({ data }) => {
        const idList = data?.esearchresult?.idlist || [];
        if (idList.length > 0) {
          // Step 2: Fetch summary for IDs
          axios
            .get(
              'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi',
              {
                params: {
                  db: 'clinvar',
                  id: idList.join(','), // 用逗号分隔
                  retmode: 'json'
                }
              }
            )
            .then(({ data: summaryData }) => {
              const results = summaryData?.result || {};
              const variants = idList.map((variantId) => {
                const variantTitle =
                  results[variantId]?.title || 'No title found';
                const germlineClassification =
                  results[variantId]?.germline_classification || {};
                const description =
                  germlineClassification.description || 'No description found';

                return {
                  id: variantId,
                  title: variantTitle,
                  description: description
                };
              });

              // 更新 state
              setClinVarVariants(variants);
              setSnack({
                status: true,
                msg: 'ClinVar API call successful',
                severity: 'success'
              });
            })
            .catch(() => {
              setSnack({
                status: true,
                msg: 'Failed to fetch ClinVar summary data',
                severity: 'error'
              });
            });
        }
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Failed to query ClinVar database',
          severity: 'error'
        });
      });
  };

  // 如果 genotype.id 包含 'NCBIGene:'，则触发请求
  useEffect(() => {
    if (open && genotype?.id?.includes('NCBIGene:')) {
      fetchClinVarData(genotype.id);
    } else {
      // 清空之前的结果
      setClinVarVariants([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, genotype]);

  /**
   * 用户点击复选框的回调：
   * 若原本已在 selectedVariantIds 中则移除，否则添加进去
   */
  const handleToggleVariant = (variantId) => {
    setSelectedVariantIds((prev) => {
      if (prev.includes(variantId)) {
        return prev.filter((id) => id !== variantId);
      } else {
        return [...prev, variantId];
      }
    });
  };

  // 点击 Save 时将选中的 Variant IDs 也保存进 genotype
  const handleSaveClick = () => {
    onSave({
      ...genotype,
      clinicallyConfirmed,
      patientReported,
      isPrivate,
      displayOnPedigree,
      dateRecorded,
      recordedBy,
      carrierStatus,
      selectedClinVarIds: selectedVariantIds // 在此将勾选的 ID 一并保存
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          height: 700,
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
        }}
      >
        {/* 顶部标题 */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            backgroundColor: 'secondary.main',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }}
        >
          <Box />
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}
          >
            Edit Genotype
          </Typography>
        </Box>

        {/* 主内容区域 */}
        <Box sx={{ p: 2, pt: 3 }}>
          {/* 基本信息 */}
          <Box
            sx={{
              padding: 2,
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              boxShadow: 1,
              mb: 2
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 2
              }}
            >
              {/* 左侧 Symbol 和 Name + ID */}
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Symbol:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {genotype?.name || 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {genotype?.id || 'N/A'}
                </Typography>
              </Box>

              {/* 右侧 Gene Location */}
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', textAlign: 'right' }}
                >
                  Gene Location:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {genotype?.location || 'N/A'}
                </Typography>
              </Box>
            </Box>

            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Definition:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {genotype?.definition || 'N/A'}
            </Typography>

            {/* ClinVar Variants: 使用分组 + Accordion + checkbox */}
            <ClinVarVariantsList
              variants={clinVarVariants}
              selectedVariantIds={selectedVariantIds}
              onToggleVariant={handleToggleVariant}
            />
          </Box>

          {/* Date / Recorded By */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextField
                type="date"
                label="Date Recorded"
                value={dateRecorded}
                onChange={(e) => setDateRecorded(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Recorded By"
                value={recordedBy}
                onChange={(e) => setRecordedBy(e.target.value)}
                fullWidth
              />
            </Box>
          </Box>

          {/* Switches */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Clinically Confirmed</Typography>
              <Switch
                checked={clinicallyConfirmed}
                onChange={() => setClinicallyConfirmed((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Patient Reported</Typography>
              <Switch
                checked={patientReported}
                onChange={() => setPatientReported((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Private</Typography>
              <Switch
                checked={isPrivate}
                onChange={() => setIsPrivate((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Display on Pedigree</Typography>
              <Switch
                checked={displayOnPedigree}
                onChange={() => setDisplayOnPedigree((prev) => !prev)}
              />
            </Box>
          </Box>

          {/* Carrier Status */}
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="carrier-status-label">Carrier Status</InputLabel>
              <Select
                labelId="carrier-status-label"
                value={carrierStatus}
                label="Carrier Status"
                onChange={(e) => setCarrierStatus(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Carrier">Carrier</MenuItem>
                <MenuItem value="Affected">Affected</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Bottom action buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

// ------------------ Main GenotypesMenu Component ------------------ //
const GenotypesMenu = ({ data, modificationHistoryHandle }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(HPO_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGenotype, setSelectedGenotype] = useState(null);

  const { setSnack } = useContext(SnackContext);
  const { content } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const t = useAutoTranslation();

  const user_id = useMemo(() => user?.user_id || user?._id, [user]);
  const familyMemberId = useMemo(() => data?.data?.familyMemberId, [data]);
  const proBandId = useMemo(() => data?.data?.proBandId, [data]);

  // 根据搜索词从 HPO 服务获取基因信息
  const fetchDataByGenes = async (currentPage) => {
    if (searchTerm.length >= 3) {
      const url = getHPOSearchURL(
        searchTerm,
        currentPage,
        HPO_CONSTANTS.category['gene']
      );
      try {
        const response = await axios.get(url);
        if (isError) setIsError(false);

        const { results = [], totalCount: count } = response.data || {};
        const newOptions = results.map((d) => ({
          id: d.id,
          name: d.name
        }));

        if (currentPage === HPO_CONSTANTS['initialPage']) {
          setOptions(newOptions);
        } else {
          setOptions((preOption) => [...preOption, ...newOptions]);
        }
        setTotalCount(count);
        setPage(currentPage);
      } catch (err) {
        setIsError(true);
        console.error('Error fetching genes:', err);
      }
    } else {
      setOptions([]);
    }
  };

  // 获取已保存的 genotype 信息
  const getGenotypes = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    setSelectedItems([]); // Reset genotypes
    axios
      .get(
        APICONSTANTS.getGenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: Boolean(proBandId) }
        }
      )
      .then(({ data }) => {
        const { genotypes = [] } = data || {};
        setSelectedItems(genotypes);
      })
      .catch((err) => {
        console.error('Error fetching genotypes:', err);
      });
  };

  useEffect(() => {
    getGenotypes();
    // eslint-disable-next-line
  }, [familyMemberId, proBandId]);

  useEffect(() => {
    fetchDataByGenes(HPO_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  // 选中基因后添加到 selectedItems
  const handleSelect = (event, value) => {
    if (value) {
      // 去重
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);

      const merged = [...selectedItems];
      uniqueData.forEach((newItem) => {
        if (!merged.some((m) => m.id === newItem.id)) {
          merged.push({
            ...newItem,
            clinicallyConfirmed: false,
            patientReported: false,
            isPrivate: false,
            displayOnPedigree: false,
            dateRecorded: new Date().toISOString().split('T')[0],
            recordedBy: `${user.firstName} ${user.lastName}`,
            carrierStatus: '',
            selectedClinVarIds: [] // 初始时尚未勾选 ClinVar Variants
          });
        }
      });
      setSelectedItems(merged);
      setSearchTerm('');
    }
  };

  // 可选地从 MyGene.info 获取更多信息
  const fetchGeneInfo = async (geneName) => {
    try {
      const response = await axios.get('https://mygene.info/v3/query', {
        params: {
          q: geneName,
          fields: 'name,symbol,summary,map_location'
        }
      });
      const hits = response?.data?.hits || [];
      if (hits.length > 0) {
        const { symbol, name, summary, map_location } = hits[0];
        return {
          symbol,
          name,
          definition: summary,
          location: map_location
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching gene info from MyGene API:', error);
      return null;
    }
  };

  // 点击已选 genotype “卡片”时，获取更多信息并打开 Modal
  const handleTileClick = async (genotype) => {
    const myGeneInfo = await fetchGeneInfo(genotype.name);
    if (myGeneInfo) {
      genotype.symbol = myGeneInfo.symbol || genotype.symbol;
      genotype.definition = myGeneInfo.definition || genotype.definition;
      genotype.location = myGeneInfo.location || genotype.location;
    }
    setSelectedGenotype(genotype);
    setModalOpen(true);
  };

  // Modal 中点击「保存」后的回调
  const handleModalSave = (updatedFields) => {
    const updatedGenotype = { ...selectedGenotype, ...updatedFields };
    // 本地更新
    setSelectedItems((prev) =>
      prev.map((item) =>
        item.id === updatedGenotype.id ? updatedGenotype : item
      )
    );

    // 同步到后端
    const id = proBandId ?? familyMemberId;
    axios
      .put(
        APICONSTANTS.updateGenotype
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          genotype: updatedGenotype,
          isProband: Boolean(proBandId)
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSnack({
            status: true,
            msg: 'Genotype updated successfully!',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: response.data.message || 'Error updating genotype',
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        console.error('Error updating genotype:', err);
        setSnack({
          status: true,
          msg: 'Error updating genotype',
          severity: 'error'
        });
      })
      .finally(() => {
        setModalOpen(false);
      });
  };

  // 将选中基因保存到后端
  const onSave = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) {
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });
    }

    setLoading(true);
    const payload = {
      familyId: user.familyId,
      familyMemberId: id,
      user_id: user_id,
      isProband: Boolean(proBandId),
      genotypes: selectedItems
    };

    axios
      .post(APICONSTANTS.genotypes, payload)
      .then((res) => {
        if (res.data?.success) {
          setSnack({
            status: true,
            msg: 'Genotypes saved successfully',
            severity: 'success'
          });
          // 成功后刷新
          getGenotypes();
        } else {
          setSnack({
            status: true,
            msg: res.data.message || 'Error saving genotypes',
            severity: 'error'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response?.data?.error || 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));
  };

  // Autocomplete 下拉列表滚动事件，用于无限加载
  const handleScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      options.length < totalCount
    ) {
      const newCurrentPage = page + 1;
      fetchDataByGenes(newCurrentPage);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {isError && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                'The HPO service is unreachable and may be offline. Please also check your Internet connection. You will not be able to search HPO until the connection is restored.'
              )}
            </Alert>
          </Grid>
        )}

        {/* 基因搜索下拉 */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={[]}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{ onScroll: handleScroll }}
            filterOptions={(options) => options} // 不使用默认过滤
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Genes"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>

        {/* 保存 & 历史 */}
        <Grid item xs={12}>
          <Button
            endIcon={loading ? <CircularProgress size={15} /> : null}
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>
          <IconButton
            sx={{ marginLeft: '10px' }}
            onClick={() =>
              modificationHistoryHandle(CONSTANTS.auditLogType['genotypes'])
            }
          >
            <HistoryIcon />
          </IconButton>
        </Grid>

        {/* 已选中（保存过）的基因 “卡片” */}
        {selectedItems.map((item) => (
          <Grid key={item.id} item xs={12}>
            <Box
              sx={{
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleTileClick(item)}
            >
              <Typography variant="subtitle1">{item.name || 'N/A'}</Typography>
              <Typography variant="caption">{item.id}</Typography>
              {item.clinicallyConfirmed && (
                <Typography variant="caption" style={{ color: 'red' }}>
                  <br />
                  Clinically Confirmed
                </Typography>
              )}
            </Box>
          </Grid>
        ))}

        {/* Modal：编辑 Genotype 详情（包括分组展示的 ClinVar Variants 勾选） */}
        {selectedGenotype && (
          <GenotypeModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            genotype={selectedGenotype}
            onSave={handleModalSave}
            user={user}
          />
        )}
      </Grid>
    </>
  );
};

export default GenotypesMenu;
