import React from 'react';
import { Dialog, Button, Box, Typography, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import { amber } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { ThemeContext } from 'src/store/ContextStore';

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    }
  };
};

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, appearance, ...other } = props;
  const { theme } = React.useContext(ThemeContext);
  const headerColor = hexToRGB(theme.accent, 0.15);
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ backgroundColor: headerColor }}
    >
      <div className={classes.title} style={{ opacity: 1 }}>
        {appearance === 'warning' && (
          <WarningIcon fontSize="small" style={{ color: amber[500] }} />
        )}
        <Typography variant="h6" style={{ opacity: 1 }}>
          {children}
        </Typography>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
          style={{ opacity: 1 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: 20
  }
}))(MuiDialogContent);

const AcitonDialog = ({
  open,
  title,
  handleClose,
  onSubmit,
  saveText = 'Continue',
  children,
  maxWidth = 'sm',
  minHeight = 180
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="custom-dialog-title"
    >
      <DialogTitle id="custom-dialog-title">{title}</DialogTitle>

      <DialogContent sx={{ minHeight: minHeight }}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex={1}>{children}</Box>

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: 2,
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: 'background.paper',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              {saveText}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AcitonDialog;
