import {
  createDropdown,
  addListToDropdown,
  Plugin,
  ViewModel,
  Collection
} from 'ckeditor5';
import { mergeFieldsOptions } from 'src/components/settings/templates/constants';
const mergeFieldIcon = `
<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9.00037" cy="9.79993" r="1.5"></circle>
  <path d="M13.2496 2.75005C13.2497 2.00005 13.2498 2.00005 13.2498 2.00005L13.2511 2.00005L13.2534 2.00006L13.2606 2.00009L13.2848 2.0003C13.3052 2.00053 13.3337 2.00098 13.3688 2.00185C13.4386 2.0036 13.536 2.00707 13.6475 2.01402C13.8589 2.02722 14.1651 2.05536 14.4328 2.12276C14.8296 2.22267 15.1705 2.40399 15.4402 2.67392C15.7077 2.94161 15.8688 3.26001 15.9644 3.5803C16.1459 4.18776 16.1145 4.89393 16.0724 5.49256C16.0626 5.6324 16.052 5.76849 16.0416 5.90131C16.0036 6.38849 15.969 6.83163 15.9895 7.25369C16.0151 7.78147 16.1252 8.13279 16.3222 8.3656C16.5447 8.6286 16.8158 8.79405 17.0411 8.89436C17.1523 8.94386 17.2471 8.97509 17.3103 8.99319C17.3417 9.00219 17.3647 9.00776 17.3773 9.01062L17.3868 9.01269L17.9996 9.12173V9.75005H15.5976C15.4541 9.63123 15.3117 9.49364 15.1771 9.33451C14.669 8.73405 14.5235 7.99036 14.4913 7.32643C14.4657 6.79933 14.5105 6.23163 14.5497 5.73429C14.5592 5.61404 14.5683 5.49785 14.5761 5.3874C14.6198 4.76596 14.62 4.32043 14.5272 4.00956C14.4857 3.87069 14.4332 3.78828 14.3791 3.73414C14.3272 3.68223 14.2386 3.6207 14.0665 3.57736C13.9372 3.5448 13.7433 3.52293 13.554 3.51111C13.4651 3.50556 13.3869 3.50277 13.3313 3.50139C13.3037 3.5007 13.282 3.50036 13.2678 3.5002L13.2524 3.50007L13.2493 3.50005L12.4996 3.5L12.4997 2L13.2498 2.00005L13.2496 2.75005Z"></path>
  <path d="M13.2496 16.7501C13.2497 17.5001 13.2498 17.5001 13.2498 17.5001L13.2511 17.5001L13.2534 17.5001L13.2606 17.5L13.2848 17.4998C13.3052 17.4996 13.3337 17.4991 13.3688 17.4983C13.4386 17.4965 13.536 17.493 13.6475 17.4861C13.8589 17.4729 14.1651 17.4448 14.4328 17.3774C14.8296 17.2774 15.1705 17.0961 15.4402 16.8262C15.7077 16.5585 15.8688 16.2401 15.9644 15.9198C16.1459 15.3124 16.1145 14.6062 16.0724 14.0076C16.0626 13.8677 16.052 13.7316 16.0416 13.5988C16.0036 13.1116 15.969 12.6685 15.9895 12.2464C16.0151 11.7186 16.1252 11.3673 16.3222 11.1345C16.5447 10.8715 16.8158 10.7061 17.0411 10.6058C17.1523 10.5563 17.2471 10.525 17.3103 10.5069C17.3417 10.4979 17.3647 10.4924 17.3773 10.4895L17.3868 10.4874L17.9996 10.3784V9.75005H15.5976C15.4541 9.86887 15.3117 10.0065 15.1771 10.1656C14.669 10.7661 14.5235 11.5098 14.4913 12.1737C14.4657 12.7008 14.5105 13.2685 14.5497 13.7658C14.5592 13.8861 14.5683 14.0023 14.5761 14.1127C14.6198 14.7341 14.62 15.1797 14.5272 15.4906C14.4857 15.6294 14.4332 15.7118 14.3791 15.766C14.3272 15.8179 14.2386 15.8794 14.0665 15.9228C13.9372 15.9553 13.7433 15.9772 13.554 15.989C13.4651 15.9945 13.3869 15.9973 13.3313 15.9987C13.3037 15.9994 13.282 15.9998 13.2678 15.9999L13.2524 16L13.2493 16.0001L12.4996 16.0001L12.4997 17.5001L13.2498 17.5001L13.2496 16.7501Z"></path>
  <path d="M5.75024 2.75005C5.75019 2.00005 5.75006 2.00005 5.75006 2.00005L5.74877 2.00005L5.74647 2.00006L5.73927 2.00009L5.71503 2.0003C5.6947 2.00053 5.66619 2.00098 5.63111 2.00185C5.56123 2.0036 5.46388 2.00707 5.35241 2.01402C5.14095 2.02722 4.83482 2.05536 4.56712 2.12276C4.1703 2.22267 3.82938 2.40399 3.55967 2.67392C3.29221 2.94161 3.1311 3.26001 3.03544 3.5803C2.85401 4.18776 2.8854 4.89393 2.92747 5.49256C2.9373 5.6324 2.94792 5.76849 2.95828 5.90131C2.99629 6.38849 3.03087 6.83163 3.01038 7.25369C2.98475 7.78147 2.87469 8.13279 2.6777 8.3656C2.45517 8.6286 2.1841 8.79405 1.95875 8.89436C1.84756 8.94386 1.75282 8.97509 1.68956 8.99319C1.65813 9.00219 1.63513 9.00776 1.62253 9.01062L1.61304 9.01269L1.00024 9.12173V9.75005H3.4023C3.54579 9.63123 3.68814 9.49364 3.82278 9.33451C4.33087 8.73405 4.47638 7.99036 4.50861 7.32643C4.5342 6.79933 4.48942 6.23163 4.4502 5.73429C4.44071 5.61404 4.43155 5.49785 4.42378 5.3874C4.38011 4.76596 4.37986 4.32043 4.4727 4.00956C4.51418 3.87069 4.56668 3.78828 4.62078 3.73414C4.67264 3.68223 4.76124 3.6207 4.93336 3.57736C5.06269 3.5448 5.25656 3.52293 5.44585 3.51111C5.53475 3.50556 5.61296 3.50277 5.66854 3.50139C5.6962 3.5007 5.71789 3.50036 5.73209 3.5002L5.74748 3.50007L5.75054 3.50005L6.5003 3.5L6.50019 2L5.75006 2.00005L5.75024 2.75005Z"></path>
  <path d="M5.75024 16.7501C5.75019 17.5001 5.75006 17.5001 5.75006 17.5001L5.74877 17.5001L5.74647 17.5001L5.73927 17.5L5.71503 17.4998C5.6947 17.4996 5.66619 17.4991 5.63111 17.4983C5.56123 17.4965 5.46388 17.493 5.35241 17.4861C5.14095 17.4729 4.83482 17.4448 4.56712 17.3774C4.1703 17.2774 3.82938 17.0961 3.55967 16.8262C3.29221 16.5585 3.1311 16.2401 3.03544 15.9198C2.85401 15.3124 2.8854 14.6062 2.92747 14.0076C2.9373 13.8677 2.94792 13.7316 2.95828 13.5988C2.99629 13.1116 3.03087 12.6685 3.01038 12.2464C2.98475 11.7186 2.87469 11.3673 2.6777 11.1345C2.45517 10.8715 2.1841 10.7061 1.95875 10.6058C1.84756 10.5563 1.75282 10.525 1.68956 10.5069C1.65813 10.4979 1.63513 10.4924 1.62253 10.4895L1.61304 10.4874L1.00024 10.3784V9.75005H3.4023C3.54579 9.86887 3.68814 10.0065 3.82278 10.1656C4.33087 10.7661 4.47638 11.5098 4.50861 12.1737C4.5342 12.7008 4.48942 13.2685 4.4502 13.7658C4.44071 13.8861 4.43155 14.0023 4.42378 14.1127C4.38011 14.7341 4.37986 15.1797 4.4727 15.4906C4.51418 15.6294 4.56668 15.7118 4.62078 15.766C4.67264 15.8179 4.76124 15.8794 4.93336 15.9228C5.06269 15.9553 5.25656 15.9772 5.44585 15.989C5.53475 15.9945 5.61296 15.9973 5.66854 15.9987C5.6962 15.9994 5.71789 15.9998 5.73209 15.9999L5.74748 16L5.75054 16.0001L6.5003 16.0001L6.50019 17.5001L5.75006 17.5001L5.75024 16.7501Z"></path>
</svg>
`;

export default class MergeFieldDropdown extends Plugin {
  static get pluginName() {
    return 'MergeFieldDropdown';
  }

  init() {
    const editor = this.editor;
    const t = editor.t;
    const dropdownTooltip = t('Insert Merge Field');

    editor.ui.componentFactory.add('mergeFieldDropdown', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: 'Insert Merge Field',
        tooltip: dropdownTooltip,
        icon: mergeFieldIcon,
        withText: false
      });

      dropdownView.extendTemplate({
        attributes: {
          class: ['ck-merge-field-dropdown']
        }
      });

      // Create a collection for dropdown items
      const items = new Collection();

      // Add merge fields dynamically
      mergeFieldsOptions.forEach((field) => {
        const fieldModel = new ViewModel({
          id: field.id,
          label: field.label,
          withText: true
        });

        items.add({ type: 'button', model: fieldModel });
      });

      // Attach items to the dropdown
      addListToDropdown(dropdownView, items);

      dropdownView.on('execute', (evt) => {
        let label = evt.source.label;

        editor.model.change((writer) => {
          writer.insertText(
            `{{${label}}}`,
            editor.model.document.selection.getFirstPosition()
          );
        });
      });

      return dropdownView;
    });
  }
}
