import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';

import axios from 'src/axios';

import { Box, TextField } from '@mui/material';
import { getDependencies } from './formService';

import { formatDate } from 'src/util/util';
import {
  SnackContext,
  AppContext,
  ThemeContext,
  UserContext
} from 'src/store/ContextStore';
import TextInput from 'src/components/UI/input/TextInput';
import { getConsentTags } from './formService';
import DateInput from '../../../UI/input/DateInput';
import AssociatedNote from '../../../UI/input/AssociatedNote';

import UploadFileList from 'src/components/UI/input/UploadFileList';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import SelectInput from 'src/components/UI/input/SelectInput';
import CheckboxInput from 'src/components/UI/input/CheckboxInput';
import InfoTooltip from 'src/components/UI/addons/InfoTooltip';

export default function TestForm({
  data,
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user: loggedInUser } = React.useContext(UserContext);

  const isBillableValid = React.useMemo(
    () => !!data.data.firstName && !!data.data.lastName,
    [data]
  );

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        testName: '',
        testType: '',
        lab: '',
        billable: false,
        dateRequested: new Date(),
        dateResultDue: null,
        dateResultReceived: null,
        testResult: '',
        testNotes: '',
        fileAttachments: [],
        associatedConsent: { label: 'none', _id: '' },
        requestedBy: loggedInUser._id
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([]);

  let [consentOptions, setConsentOptions] = React.useState(['loading...']);
  React.useEffect(() => {
    const fetchData = async () => {
      const consentTags = await getConsentTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setConsentOptions(consentTags);
    };
    if (consentOptions != [] && consentOptions[0] == 'loading...') fetchData();
  }, []);

  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    if (e.target.type === 'date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();

      if (selectedDate >= today) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
      } else setError({});
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);

      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then(() => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (Object.keys(error).length > 0) return Promise.resolve();
    let requestedBy = null;
    if (formValues.requestedBy) {
      requestedBy = staffMembers.find(
        (member) => member.userId === formValues.requestedBy
      );
    }

    const parsePayload = {
      ...formValues,
      requestedBy: requestedBy ? [requestedBy] : []
    };

    return axios
      .put(APICONSTANTS.test, parsePayload)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const [dateDueText, setDateDueText] = React.useState('.');
  const [dateDueError, setDateDueError] = React.useState(false);

  const [dateResultReceivedText, setDateResultReceivedText] =
    React.useState('.');
  const [dateResultReceivedError, setDateResultReceivedError] =
    React.useState(false);

  const isDateDueValid = () => {
    return (
      !formValues.dateRequested ||
      !formValues.dateResultDue ||
      moment(formValues.dateRequested) <=
        moment(formValues.dateResultDue).add('1', 'day')
    );
  };

  const isDateResultReceivedValid = () => {
    return (
      !formValues.dateRequested ||
      !formValues.dateResultReceived ||
      moment(formValues.dateRequested) <=
        moment(formValues.dateResultReceived).add('1', 'day')
    );
  };

  React.useEffect(() => {
    let err = !isDateDueValid();
    setDateDueError(err);

    err = !isDateResultReceivedValid();
    setDateResultReceivedError(err);
  });

  const validDates = () => {
    return isDateDueValid() && isDateResultReceivedValid();
  };

  return (
    //Should be fine to reuse old class?
    <ClickAwayListener onClickAway={handleClose}>
      <form className="user-form">
        <ClinicalNoteDeleteModal
          loading={loading}
          open={open}
          handleCloseModal={handleCloseModal}
          onConfirm={deleteNote}
          title="Are you sure you want to delete this Test ?"
          content="Warning: This action will permanently delete this Test. Once deleted, this
        information will be permanently removed and cannot be restored."
        />

        <Grid container spacing={2}>
          <Grid item xs={12} id="modaltop">
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Test Name</FormLabel>
            </Grid>
            <Box sx={{ display: 'flex', position: 'relative' }}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="testName"
                value={formValues.testName}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}
            >
              <TextInput
                label="Test Type"
                type="autocomplete"
                name="testType"
                value={formValues.testType || ''}
                onChange={(e) => {
                  const label = e ? e.label : '';
                  setFormValues({
                    ...formValues,
                    testType: label
                  });
                }}
                options={typeOptions || []}
                helperText="Please select or enter a test type"
                helperTextColour="red"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Date Requested</FormLabel>
            </Grid>
            <DateInput
              name="dateRequested"
              value={formValues.dateRequested}
              disableFuture
              onChange={(val) =>
                handleChange({
                  target: { name: 'dateRequested', value: val, type: 'date' }
                })
              }
              variant="outline"
              error={error?.dateRequested}
              helperText={'Date cannot be in the future'}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Associated Consent</FormLabel>
            </Grid>
            <AssociatedNote
              name="associatedConsent"
              handleChange={handleChange}
              value={formValues.associatedConsent}
              noteOptions={consentOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Result Due Date</FormLabel>
            </Grid>
            <DateInput
              value={
                formValues.dateResultDue
                  ? new Date(formatDate(formValues.dateResultDue))
                  : null
              }
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  dateResultDue: e
                }))
              }
              variant="outline"
              minDate={formValues.dateRequested}
              error={dateDueError}
              helperText={dateDueText}
              liveText={true}
              helperTextColour="red"
            />
          </Grid>

          <Grid item xs={6}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Date Result Received</FormLabel>
            </Grid>
            <DateInput
              value={formValues.dateResultReceived}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  dateResultReceived: e
                }))
              }
              variant="outline"
              minDate={formValues.dateRequested}
              error={dateResultReceivedError}
              helperText={dateResultReceivedText}
              liveText={true}
              helperTextColour="red"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Test Billable</FormLabel>
            </Grid>
            <CheckboxInput
              disabled={!isBillableValid}
              value={formValues.billable}
              label={formValues.billable ? 'Yes' : 'No'}
              name={'billable'}
              onChange={handleChange}
            />
            {!isBillableValid && (
              <InfoTooltip label={'Firstname & Lastname required'} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Lab</FormLabel>
            </Grid>
            <Box sx={{ display: 'flex', position: 'relative' }}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="lab"
                value={formValues.lab}
                multiline={true}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Test Result</FormLabel>
            </Grid>
            <Box sx={{ display: 'flex', position: 'relative' }}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="testResult"
                value={formValues.testResult}
                multiline={true}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Test Notes</FormLabel>
            </Grid>
            <Box sx={{ display: 'flex', position: 'relative' }}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="testNotes"
                value={formValues.testNotes}
                multiline={true}
              />
            </Box>
          </Grid>

          <UploadFileList
            value={formValues.fileAttachments}
            familyId={familyMemberId}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                fileAttachments: value
              })
            }
          />

          {/* RequestedBy */}
          <Grid item xs={12} id="top">
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Requested By </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                fullWidth={true}
                variant="outlined"
                name="requestedBy"
                options={staffMemberOptions}
                value={formValues.requestedBy || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <ModalButtons
            isEditing={isEditing}
            hideContinueAdding={true}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!validDates()) {
                e.preventDefault();
                e.stopPropagation();
                const element = document.getElementById('modaltop');
                element.scrollIntoView();
                if (!isDateDueValid()) {
                  setDateDueText('Test cannot be due before it was requested');
                  setDateDueError(true);
                }
                if (!isDateResultReceivedValid()) {
                  setDateResultReceivedText(
                    "Test can't be received before being requested"
                  );
                  setDateResultReceivedError(true);
                }
                setSnack({
                  status: true,
                  msg: 'Dates were not valid',
                  severity: 'warning'
                });
                return;
              }
              handleSubmit(e);
            }}
            intermediateBtnLabel="Delete"
            intermediateAction={isEditing ? handleOpenModal : null}
            handleClose={handleClose}
            addLabel={`Add new test details`}
          />
        </Grid>
      </form>
    </ClickAwayListener>
  );
}
