//this file is AppointmentForm.jsx

import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import TextInput from 'src/components/UI/input/TextInput';

import axios from 'src/axios';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DateInput from 'src/components/UI/input/DateInput';
import AssociatedNote from 'src/components/UI/input/AssociatedNote';
import { Box, MenuItem, Select, TextField, Button } from '@mui/material';

import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import {
  SnackContext,
  ThemeContext,
  AppContext,
  UserContext
} from 'src/store/ContextStore';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getReferralTags, handleUpload, getDependencies } from './formService';

import UploadFileList from 'src/components/UI/input/UploadFileList';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';

export default function AppointmentForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions,
  isDisabled
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { user: loggedInUser } = React.useContext(UserContext);
  const { setSnack } = React.useContext(SnackContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // 处理表单初始值
  const defaultValues = isEditing
    ? {
        ...model,
        fileAttachments: model.fileAttachments || [],
        // 确保这三个值存在
        joinUrl: model.joinUrl || '',
        rescheduleUrl: model.rescheduleUrl || '',
        cancelUrl: model.cancelUrl || ''
      }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        clinicName: '',
        appointmentId: '',
        appointmentDate: new Date(),
        appointmentTime: dayjs(new Date()), // TimePicker 使用 dayjs
        clinicianName: '',
        appointmentStatus: 'Closed',
        appointmentType: '',
        location: 'Telephone',
        associatedReferral: { label: 'none', _id: '' },
        fileAttachments: [],
        appointmentNotes: '',
        showInFamily: false,
        staffMember: [loggedInUser._id],
        joinUrl: '',
        rescheduleUrl: '',
        cancelUrl: '',
        inviteeName: ''
      };

  const { theme } = useContext(ThemeContext);
  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);

  const [locationOptions] = React.useState([
    'Telephone',
    'Video',
    'Face to Face',
    'Private Provider'
  ]);

  let [referralOptions, setReferralOptions] = React.useState(['loading...']);

  React.useEffect(() => {
    const fetchData = async () => {
      const referralTags = await getReferralTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setReferralOptions(referralTags);
    };
    if (referralOptions.length === 1 && referralOptions[0] === 'loading...') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralOptions]);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);
      return;
    }

    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then(() => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 显示在家庭切换
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
  };

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };

  // 保存逻辑
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let staffMember = null;
    if (formValues.staffMember && formValues.staffMember.length > 0) {
      staffMember = formValues.staffMember.map((staff) => {
        let existStaffMember = staffMembers.find(
          (member) => member.userId === staff
        );

        return {
          ...existStaffMember
        };
      });
    }

    const parsePayload = {
      ...formValues,
      staffMember: staffMember ? [...staffMember] : []
    };

    return axios
      .put(APICONSTANTS.appointment, parsePayload)
      .then((res) => {
        handleClose();
        setSnack({
          status: true,
          msg: 'Successfully saved',
          severity: 'success'
        });
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const allStaffMembers = React.useMemo(() => {
    return staffMemberOptions.map((options) => options.value);
  }, [staffMemberOptions]);

  const staffMembersMap = React.useMemo(() => {
    return staffMemberOptions.reduce((acc, item) => {
      acc[item.value] = item.label;
      return acc;
    }, {});
  }, [staffMemberOptions]);

  return (
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Appointment ?"
        content="Warning: This action will permanently delete this Appointment. Once deleted, this
      information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Clinic Name</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="clinicName"
              value={formValues.clinicName}
              inputProps={{ readOnly: isDisabled }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="appointmentId"
              value={formValues.appointmentId}
              inputProps={{ readOnly: isDisabled }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="appointmentStatus"
                value={formValues.appointmentStatus}
                onChange={handleChange}
                readOnly={isDisabled}
              >
                {['Open', 'Closed'].map((option, i) => (
                  <MenuItem value={option} key={i} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Date</FormLabel>
          </Grid>
          <DateInput
            value={
              formValues.appointmentDate
                ? new Date(formValues.appointmentDate)
                : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                appointmentDate: e
              }))
            }
            variant="outline"
            minDate={new Date(1600, 0, 0)}
            readOnly={isDisabled}
            // minDate={isEditing ? new Date(1600, 0, 1) : new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Time</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formValues.appointmentTime}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    appointmentTime: e
                  }));
                }}
                readOnly={isDisabled}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Appointment Type"
              type="autocomplete"
              name="appointmentType"
              value={formValues.appointmentType || ''}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  appointmentType: label
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter an appointment type"
              readOnly={isDisabled}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Location"
              type="autocomplete"
              name="location"
              value={formValues.location || null}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  location: label
                });
              }}
              options={locationOptions || []}
              helperText="Please select or enter a location"
              readOnly={isDisabled}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedReferral"
            handleChange={handleChange}
            value={formValues.associatedReferral}
            noteOptions={referralOptions}
            readOnly={isDisabled}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="appointmentNotes"
              value={formValues.appointmentNotes}
              multiline={true}
              inputProps={{ readOnly: isDisabled }}
            />
          </Box>
        </Grid>

        <UploadFileList
          value={formValues.fileAttachments}
          familyId={familyMemberId}
          onChange={(value) =>
            setFormValues({
              ...formValues,
              fileAttachments: value
            })
          }
          readOnly={isDisabled}
        />

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Staff Member */}
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  label={'Staff Member'}
                  name="staffMember"
                  type="multiple-select"
                  value={formValues?.staffMember || []}
                  onChange={handleChange}
                  options={allStaffMembers || []}
                  mapper={staffMembersMap || {}}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        {/* 新增：三按钮区域 */}
        {!isDisabled && (
          <Grid item xs={12}>
            <Box
              sx={{ display: 'flex', gap: 1, mt: 1, justifyContent: 'center' }}
            >
              {/* Join 按钮 */}
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(formValues.joinUrl, '_blank');
                }}
                disabled={!formValues.joinUrl}
              >
                Join
              </Button>

              {/* Reschedule 按钮 */}
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(formValues.rescheduleUrl, '_blank');
                }}
                disabled={!formValues.rescheduleUrl}
              >
                Reschedule
              </Button>

              {/* Cancel 按钮 */}
              <Button
                variant="contained"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(formValues.cancelUrl, '_blank');
                }}
                disabled={!formValues.cancelUrl}
              >
                Cancel Appointment
              </Button>
            </Box>
          </Grid>
        )}

        {/* 提交、删除、取消 等操作按钮 */}
        <ModalButtons
          hideSaveButton={isDisabled}
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing && !isDisabled ? handleOpenModal : null}
          addLabel={`Add new appointment`}
        />
      </Grid>
    </form>
  );
}
