import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Render from './Render';
import { ThemeContext } from 'src/store/ContextStore';
import { Box } from '@mui/material';

function Pedigreechart({
  options,
  dataset,
  handleClick,
  handleRelationClick,
  handleDoubleClick,
  undo,
  redo,
  onNodeClick,
  onCloseMenu,
  setRootConfig
}) {
  const { theme } = React.useContext(ThemeContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const familyMemberId = queryParams.get('familyMemberId');

  const colors = {
    shade:
      theme.appearance.value === 'light' ? 'white' : theme.appearance.shade,
    text: theme.appearance.text
  };

  const refresthDataset = (dataset) => {
    dataset.forEach((mem) => {
      delete mem.id;
      delete mem.parentNode;
    });
  };

  const render = React.useMemo(() => {
    refresthDataset(dataset);

    return (
      <Render
        undo={undo}
        redo={redo}
        options={options}
        dataset={dataset}
        handleClick={handleClick}
        handleRelationClick={handleRelationClick}
        handleDoubleClick={handleDoubleClick}
        colors={colors}
        onNodeClick={onNodeClick}
        onCloseMenu={onCloseMenu}
        setRootConfig={setRootConfig}
        familyMemberId={familyMemberId}
      />
    );
  }, [dataset, options, undo, redo]);

  return <Box sx={{ height: '100%', width: '100%' }}>{render}</Box>;
}

export default Pedigreechart;
